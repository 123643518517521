import {
  useCategoriesQuery,
  useConfigQuery,
  usePublicThreadsQuery,
} from '../api';
import ThreadCard from '../components/ThreadCard';
import { FlatList, Pressable, RefreshControl } from 'react-native';
import Loading from '../components/Loading';
import { useNavigate } from '../components/Router';
import { useEffect, useState } from 'react';
import { HStack, Text, VStack } from '../ui';
import { api } from '../apiTypes';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ChatPromptCard from '../components/ChatPromptCard';
import { usei18n } from '../utils/i18n';
import { Divider } from 'native-base';
import TopNavbar from '../components/TopNavbar';

const SortOption = ({
  selected,
  label,
  onSelect,
}: {
  selected: boolean;
  label: string;
  onSelect: () => void;
}) => (
  <Pressable
    onPress={
      onSelect
      //   if (selectedPromptIds.includes(prompt.id)) return;
      //   setSelectedPromptIds((ids) => [...ids, prompt.id]);
    }
  >
    <HStack
      rounded='full'
      bg={selected ? 'primary' : 'secondary'}
      justifyContent={'space-between'}
      // borderColor=
      // borderWidth='1'
      px={2}
      py={2}
      space={'1'}
    >
      <Text color='bg'>{label}</Text>
      {/* <Text>{selectedPromptIds.includes(prompt.id) ? '✓' : '+'}</Text> */}
    </HStack>
  </Pressable>
);

const sortOptions: {
  label: string;
  value: api['publicThreads']['sort'];
}[] = [
  {
    label: 'Most Upvoted',
    value: 'most-upvoted',
  },
  {
    label: 'Trending',
    value: 'trending',
  },
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'Featured',
    value: 'featured',
  },
];

type Props = {
  hideSortOptions?: boolean;
  pageId?: string;
  pageGroupId?: string;
};

const Explore = ({ hideSortOptions = false, pageId, pageGroupId }: Props) => {
  const [selectedSortOption, setSelectedSortOption] =
    useState<api['publicThreads']['sort']>('most-upvoted');

  const [refreshing, setRefreshing] = useState(false);
  const [activePageId, setActivePageId] = useState<string>();

  const [offset, setOffset] = useState(0);

  const { i18n } = usei18n();

  const [threads, setThreads] = useState<
    api['publicThreads']['response']['threads']
  >([]);

  const limit = 4;
  const {
    data: publicThreadsData,
    refetch: refetchThreads,
    loading: threadsLoading,
    optimisticUpdate: optimisticPublicThreads,
  } = usePublicThreadsQuery(
    {
      offset,
      limit,
      sort: selectedSortOption,
      pageId: activePageId || pageId,
      pageGroupId,
    },
    { cache: false }
  );

  const { data: configData } = useConfigQuery();

  const page = (configData?.pages || []).find((p) => p.id === activePageId);

  useEffect(() => {
    sortOptions.forEach((option) => {
      if (option.value === selectedSortOption) return;

      refetchThreads({
        cacheOnly: true,
        newParams: {
          offset: 0,
          limit,
          sort: option.value,
        },
      });
    });
  }, []);

  useEffect(() => {
    setTimeout(
      () =>
        AsyncStorage.getItem('activePageId').then((pageId) => {
          if (pageId == null) return;
          AsyncStorage.removeItem('activePageId');
          setThreads([]);
          setOffset(0);
          setActivePageId(pageId);
          // refetchThreads();
        }),
      50
    );
  }, []);

  // useEffect(() => {
  //   setThreads([]);
  //   setOffset(0);
  //   refetchThreads();
  // }, [selectedSortOption]);

  useEffect(() => {
    if (threads.length === (offset + 1) * limit) {
      return;
    }
    setThreads((t) => [...(t ?? []), ...(publicThreadsData?.threads ?? [])]);
  }, [publicThreadsData]);

  const [started] = useState(new Date());
  const { data: categoriesData } = useCategoriesQuery({});

  const prompts = (categoriesData ?? []).flatMap((c) => c.prompts);

  const navigate = useNavigate();

  const onRefresh = () => {
    setRefreshing(true);
    // setThreads([]);
    // setOffset(0);

    refetchThreads({
      newParams: {
        offset: 0,
        limit,
        sort: selectedSortOption,
        pageId: activePageId,
      },
    }).then(() => {
      setRefreshing(false);

      setOffset(0);
      setThreads([]);
    });

    setTimeout(() => {
      setRefreshing(false);
    }, 8000);
  };

  if ((!refreshing && publicThreadsData == null) || categoriesData == null) {
    return <Loading />;
  }

  return (
    <VStack h='100%' space={2}>
      {!hideSortOptions && <TopNavbar />}
      {!hideSortOptions && (
        // <Navtabs
        //   tabs={sortOptions.map((o) => o.label)}
        //   onChange={(newTab) => {
        //     setThreads([]);
        //     setOffset(0);
        //     setSelectedSortOption(
        //       sortOptions.find((o) => o.label === newTab).value
        //     );
        //   }}
        // />

        <HStack px={2} space={2}>
          {sortOptions.map((option) => (
            <SortOption
              key={option.value}
              label={option.label}
              selected={selectedSortOption === option.value}
              onSelect={() => {
                if (selectedSortOption === option.value) return;
                setThreads([]);
                setOffset(0);
                setSelectedSortOption(option.value);
              }}
            />
          ))}
        </HStack>
      )}
      {activePageId && (
        <HStack>
          <ChatPromptCard
            prompt={{
              id: page?.id || '',
              label: i18n(page).name || '',
              i18n: {},
            }}
            onPress={() => {
              setThreads([]);
              setOffset(0);
              setActivePageId(undefined);
            }}
            removable
            selected
          />
        </HStack>
      )}
      <FlatList
        initialNumToRender={5}
        onEndReached={() => {
          setOffset((o) => o + limit);
        }}
        keyExtractor={(item, index) => String(index)}
        onEndReachedThreshold={0.5}
        refreshControl={
          <RefreshControl
            tintColor='text'
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
        ItemSeparatorComponent={Divider}
        data={threads}
        renderItem={({ item: thread, index }) => (
          <Pressable
            key={thread.id}
            onPress={() => navigate('/thread/' + thread.id)}
          >
            <ThreadCard
              key={thread.id}
              thread={thread}
              prompt={prompts.find((prompt) => thread.prompt === prompt.id)}
              onChange={refetchThreads}
              onOptimisticUpdate={(thread) => {
                setThreads((thrds) =>
                  thrds.map((t) => (t.id === thread.id ? thread : t))
                );
              }}
            />
          </Pressable>
        )}
      />
    </VStack>
  );
};

export default Explore;
