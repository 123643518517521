import { Pressable, View } from 'react-native';
import { useNavigate } from './Router';
import { HStack, Text, VStack } from '../ui';
import { FontAwesome } from '@expo/vector-icons';
import { colors } from '../utils/theme';
import ThreadOptions from './Options';
import { api } from '../apiTypes';
import UserBadge from './UserBadge';

const ThreadFavoriteCard = ({
  thread,
  user,
  onRefetch,
}: {
  thread: api['db']['Thread'] & {
    favorites: api['db']['ThreadFavorite'][];
  };
  user: api['db']['PublicUser'];
  onRefetch: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <Pressable onPress={() => navigate('/thread/' + thread.id)}>
      <HStack
        rounded='xl'
        p={3}
        px={4}
        alignItems='center'
        key={thread.id}
        // borderWidth={1}
        // borderColor={'secondary'}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <HStack alignItems='center' space={2}>
            <FontAwesome name={'bookmark'} size={20} color={colors.primary} />
            <VStack>
              <Text translate={false} numberOfLines={1}>
                {thread.title}
              </Text>
              <Text fontSize='xs' color='secondary'>
                {new Date(thread.createdAt).toLocaleDateString()}
              </Text>
            </VStack>
          </HStack>
          <HStack space={2}>
            <UserBadge user={user} />
            <ThreadOptions
              thread={thread}
              onChange={onRefetch}
              isCreator={thread.createdBy === user.id}
            />
          </HStack>
        </View>
      </HStack>
    </Pressable>
  );
};

export default ThreadFavoriteCard;
