export default ({ size = 24 }: { size?: number }) => (
  <svg
    width={`${size}`}
    height={`${size}`}
    viewBox={`0 0 24 24`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.7496 4.12531C15.7496 3.98635 15.7918 3.85065 15.8704 3.7361C15.9491 3.62155 16.0606 3.53351 16.1903 3.48359L18.2453 2.69328C18.2902 2.67615 18.331 2.64971 18.365 2.6157C18.399 2.58169 18.4255 2.54088 18.4426 2.49593L19.2329 0.440933C19.2829 0.311267 19.3709 0.199769 19.4855 0.121129C19.6 0.0424895 19.7357 0.000396729 19.8746 0.000396729C20.0136 0.000396729 20.1493 0.0424895 20.2638 0.121129C20.3784 0.199769 20.4664 0.311267 20.5164 0.440933L21.3067 2.49593C21.3239 2.54083 21.3503 2.58161 21.3843 2.61561C21.4183 2.64961 21.4591 2.67607 21.504 2.69328L23.5407 3.47656C23.6606 3.52073 23.7662 3.59675 23.8462 3.69643C23.9261 3.79612 23.9774 3.9157 23.9945 4.04234C24.013 4.19438 23.9802 4.34823 23.9013 4.47951C23.8224 4.61079 23.7019 4.71198 23.559 4.76703L21.504 5.55734C21.4591 5.57455 21.4183 5.60101 21.3843 5.63501C21.3503 5.66901 21.3239 5.70978 21.3067 5.75468L20.5164 7.80968C20.4664 7.93935 20.3784 8.05085 20.2638 8.12949C20.1493 8.20813 20.0136 8.25022 19.8746 8.25022C19.7357 8.25022 19.6 8.20813 19.4855 8.12949C19.3709 8.05085 19.2829 7.93935 19.2329 7.80968L18.4426 5.75468C18.4255 5.70974 18.399 5.66892 18.365 5.63492C18.331 5.60091 18.2902 5.57447 18.2453 5.55734L16.1903 4.76703C16.0606 4.71711 15.9491 4.62907 15.8704 4.51452C15.7918 4.39996 15.7496 4.26427 15.7496 4.12531Z'
      fill='url(#paint0_linear_502_10371)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M-0.000358677 9.75031C-0.00156184 9.51328 0.0696016 9.28154 0.203621 9.08604C0.33764 8.89054 0.528131 8.7406 0.749641 8.65625L5.60589 6.78875C5.70375 6.75092 5.79262 6.69306 5.86681 6.61888C5.94099 6.54469 5.99885 6.45582 6.03667 6.35796L7.90558 1.50031C7.99051 1.27903 8.14053 1.08871 8.33586 0.954456C8.53119 0.820204 8.76263 0.748336 8.99964 0.748336C9.23666 0.748336 9.4681 0.820204 9.66343 0.954456C9.85875 1.08871 10.0088 1.27903 10.0937 1.50031L11.9612 6.35656C11.999 6.45442 12.0569 6.54329 12.1311 6.61747C12.2053 6.69166 12.2941 6.74952 12.392 6.78734L17.2496 8.65625C17.4709 8.74117 17.6612 8.8912 17.7955 9.08652C17.9297 9.28185 18.0016 9.51329 18.0016 9.75031C18.0016 9.98732 17.9297 10.2188 17.7955 10.4141C17.6612 10.6094 17.4709 10.7594 17.2496 10.8444L12.3934 12.7119C12.2955 12.7497 12.2067 12.8076 12.1325 12.8817C12.0583 12.9559 12.0004 13.0448 11.9626 13.1427L10.105 17.9708C10.0196 18.2011 9.86535 18.3994 9.66321 18.5389C9.46107 18.6784 9.22085 18.7522 8.97527 18.7503C8.74238 18.7467 8.51593 18.6733 8.32522 18.5396C8.13451 18.4059 7.98832 18.218 7.90558 18.0003L6.03808 13.1441C6.00026 13.0462 5.9424 12.9573 5.86821 12.8831C5.79403 12.809 5.70516 12.7511 5.6073 12.7133L0.749641 10.8444C0.528131 10.76 0.33764 10.6101 0.203621 10.4146C0.0696016 10.2191 -0.00156184 9.98733 -0.000358677 9.75031Z'
      fill='url(#paint1_linear_502_10371)'
    />
    <path
      d='M11.9996 18.7503C11.9997 18.5987 12.0457 18.4507 12.1315 18.3258C12.2174 18.2008 12.3391 18.1048 12.4806 18.0505L15.2635 16.9798C15.3126 16.961 15.3571 16.9321 15.3943 16.8949C15.4314 16.8578 15.4603 16.8133 15.4792 16.7642L16.5498 13.9812C16.6043 13.8399 16.7003 13.7183 16.8252 13.6325C16.9502 13.5468 17.0981 13.5009 17.2496 13.5009C17.4012 13.5009 17.5491 13.5468 17.6741 13.6325C17.799 13.7183 17.895 13.8399 17.9495 13.9812L19.0201 16.7642C19.0389 16.8133 19.0679 16.8578 19.105 16.8949C19.1422 16.9321 19.1867 16.961 19.2357 16.9798L21.999 18.0425C22.1297 18.091 22.2448 18.174 22.332 18.2827C22.4193 18.3914 22.4754 18.5218 22.4945 18.6598C22.5146 18.8257 22.4787 18.9936 22.3925 19.1368C22.3063 19.28 22.1747 19.3903 22.0187 19.4502L19.2357 20.5208C19.1867 20.5396 19.1422 20.5685 19.105 20.6057C19.0679 20.6428 19.0389 20.6874 19.0201 20.7364L17.9495 23.5194C17.895 23.6608 17.799 23.7823 17.6741 23.8681C17.5491 23.9538 17.4012 23.9997 17.2496 23.9997C17.0981 23.9997 16.9502 23.9538 16.8252 23.8681C16.7003 23.7823 16.6043 23.6608 16.5498 23.5194L15.4792 20.7364C15.4603 20.6874 15.4314 20.6428 15.3943 20.6057C15.3571 20.5685 15.3126 20.5396 15.2635 20.5208L12.4806 19.4502C12.3391 19.3958 12.2174 19.2998 12.1315 19.1748C12.0457 19.0499 11.9997 18.9019 11.9996 18.7503Z'
      fill='url(#paint2_linear_502_10371)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_502_10371'
        x1='24.5998'
        y1='22.1998'
        x2='1.1998'
        y2='1.19981'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C25FFF' />
        <stop offset='1' stopColor='#933FA1' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_502_10371'
        x1='24.5998'
        y1='22.1998'
        x2='1.1998'
        y2='1.19981'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5CA9FF' />
        <stop offset='1' stopColor='#383BFF' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_502_10371'
        x1='24.5998'
        y1='22.1998'
        x2='1.1998'
        y2='1.19981'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#A94ECD' />
        <stop offset='1' stopColor='#360DF9' />
      </linearGradient>
    </defs>
  </svg>
);
