import {
  BrowserRouter,
  Route as WebRoute,
  Link as WebLink,
  Routes as WebRoutes,
  Outlet as WebOutlet,
  Navigate as NavigateWeb,
  useNavigate as useNavigateWeb,
  useLocation as useLocationWeb,
  useParams as useParamsWeb,
} from 'react-router-dom';

export const Router = BrowserRouter;
export const Route = WebRoute;
export const Routes = WebRoutes;
export const Outlet = WebOutlet;
export const Link = WebLink;
export const Navigate = NavigateWeb;
export const useNavigate = useNavigateWeb;
export const useLocation = useLocationWeb;
export const useParams = useParamsWeb;

