import { useAssets } from 'expo-asset';
import Modal from '../components/BasicModal';
import { ImageBackground, View } from 'react-native';
import Loading from '../components/Loading';
import { Button, Text } from '../ui';
import { useEffect, useState } from 'react';
import { useNavigate } from '../components/Router';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { usei18n } from '../utils/i18n';
import { usePreloadData, useUpdateUserMutation, useUserQuery } from '../api';
import CachedImage, { cacheImage } from '../components/CachedImage';
import { useUser } from '../hooks';

const Tutorial = () => {
  const { language, loading: i18nLoading } = usei18n();

  const navigate = useNavigate();

  const [imageIdx, setImageIdx] = useState(1);

  const [user] = useUser();
  const {
    data: userData,
    refetch,
    optimisticUpdate,
  } = useUserQuery({}, { cache: false, skip: user == null });
  const [updateUser] = useUpdateUserMutation({});

  // Should probably be a better place to do this
  useEffect(() => {
    if (userData == null || i18nLoading) return;

    if (userData.language !== language) {
      updateUser({
        language,
      });
    }
  }, [userData, i18nLoading]);

  usePreloadData({ skip: userData == null });

  useEffect(() => {
    for (let i = 1; i <= 6; i++) {
      cacheImage(
        `gs://chat-api-metazooie/tutorial/${language}/TutorialScreen_${i}.png`
      );
    }
  }, [language]);

  return (
    <View style={{ display: 'flex', width: '100%', height: '100%' }} >
      <CachedImage

        isBackground
        style={{
          height: '100%',
          width: '100%',
          // marginTop: 32,
          // top: 40,
          // flex: 1,
          // position: 'relative',
        }}
        source={{
          uri: `gs://chat-api-metazooie/tutorial/${
            language || 'en'
          }/TutorialScreen_${imageIdx}.png`,
        }}
      />
      <View
        style={{
          position: 'absolute',
          width: '100%',
          bottom: 30,
        }}
      >
        <Button
          bg='secondary'
          // borderWidth={1}

          // borderColor="text"
          alignSelf='center'
          w='50%'
          h={8}
          onPress={() => {
            if (imageIdx === 6) {
              AsyncStorage.setItem('tutorial', 'complete');
              navigate('/chat');
              return;
            }

            setImageIdx((idx) => idx + 1);
          }}
        >
          <Text fontSize='md' color='bg'>
            Continue
          </Text>
        </Button>
      </View>
    </View>
  );
};

export default Tutorial;
