import { Pressable } from 'react-native';
import { Box, Stack, Text, VStack } from '../ui';
import CachedImage from './CachedImage';
import { api } from '../apiTypes';
import { usei18n } from '../utils/i18n';
import { useNavigate } from './Router';
import { DefaultImage } from './UserBadge';
import { isDesktop } from '../utils';

type Props = {
  page: api['pageGroup']['response']['pages'][0];
  pageGroup?: api['config']['response']['pageGroupCategories'][0]['pageGroups'][0];
  horizontal?: boolean;
  subtitle?: boolean;
  groupLabel?: string;
  onPress?: () => void;
  size?: 'sm' | 'md';
};

const PageCard = ({
  size = 'md',
  page,
  pageGroup,
  onPress,
  subtitle = false,
  horizontal = false,
}: Props) => {
  const { i18n } = usei18n();
  const navigate = useNavigate();

  const width = horizontal ? undefined : '140px';

  const sizes = {
    sm: {
      nameFontSize: isDesktop() ? 12 : 10,
      descriptionFontSize: 10,
      imageHeight: {
        horizontal: 60,
        vertical: 100,
      },
    },
    md: {
      nameFontSize: isDesktop() ? 14 : 12,
      descriptionFontSize: 12,
      imageHeight: {
        horizontal: 75,
        vertical: 120,
      },
    },
  }[size];

  return (
    <Pressable
      onPress={() => {
        onPress != null
          ? onPress()
          : navigate(page.id === 'laila' ? '/chat' : `/page/${page.id}`);
      }}
    >
      <Stack
        w={width}
        alignItems='center'
        space={horizontal ? 3 : 1}
        direction={horizontal ? 'row' : 'column'}
      >
        {page.id === 'laila' ? (
          <DefaultImage size={horizontal ? 75 : 120} />
        ) : (
          <CachedImage
            h={
              horizontal
                ? sizes.imageHeight.horizontal
                : sizes.imageHeight.vertical
            }
            w={
              horizontal
                ? sizes.imageHeight.horizontal
                : sizes.imageHeight.vertical
            }
            rounded='full'
            source={{
              uri: `/static/images/pages/${page.id}.png`,
            }}
            fallbackComponent={
              <Box
                rounded='full'
                opacity={0.7}
                bg='secondary'
                h={
                  horizontal
                    ? sizes.imageHeight.horizontal
                    : sizes.imageHeight.vertical
                }
                w={
                  horizontal
                    ? sizes.imageHeight.horizontal
                    : sizes.imageHeight.vertical
                }
              />
            }
          />
        )}
        <VStack alignItems={horizontal ? undefined : 'center'}>
          <Text
            fontWeight={700}
            fontSize={sizes.nameFontSize}
            color='secondary'
            maxW={width}
            numberOfLines={1}
          >
            {i18n(page).name}
          </Text>

          {horizontal && (
            <Text
              fontWeight={500}
              color='secondary'
              maxW={width}
              numberOfLines={2}
              fontSize={sizes.descriptionFontSize}
            >
              {i18n(page).description}
            </Text>
          )}

          {pageGroup && (
            <Text
              fontWeight={600}
              color='primary'
              maxW={width}
              numberOfLines={1}
              fontSize={10}
            >
              {i18n(pageGroup).label}
            </Text>
          )}
        </VStack>
      </Stack>
    </Pressable>
  );
};

export default PageCard;
