import 'react-native-get-random-values';
import { customAlphabet } from 'nanoid';
import { isDevice } from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Alert, Dimensions, Platform, Share, StatusBar } from 'react-native';
import { API_BASE_URL } from '../api';
import * as ImagePicker from 'expo-image-picker';
import * as Device from 'expo-device';
import * as DocumentPicker from 'expo-document-picker';
import { FileType } from '../../api/functions/src/db/types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from '../components/Router';
import { api } from '../apiTypes';
import { I18N } from './i18n';
import { randomUUID } from 'expo-crypto';
import { auth } from '../firebase';

const UNMISTAKABLE_CHARS =
  '23456789ABCDEFGHJKLMNPQRSTWXYZabcdefghijkmnopqrstuvwxyz';

export const randomId = (num = 12) => customAlphabet(UNMISTAKABLE_CHARS, num)();

export const uuid = randomUUID;
export const isWeb = Platform.OS === 'web';
export const isDesktop = () => Dimensions.get('window').width > 1100;

export const LOG = false;
export const log = LOG ? console.log : () => { };

export const registerForPushNotificationsAsync = async (): Promise<
  string | null
> => {
  if (isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      return null;
    }
    const token = (
      await Notifications.getExpoPushTokenAsync({
        projectId: '24df8d76-5932-420c-9ada-ef9aaae37a8d',
      })
    ).data;

    return token;
  } else {
    alert('Must use physical device for Push Notifications');
    return null;
  }

  // if (Platform.OS === 'android') {
  //   Notifications.setNotificationChannelAsync('default', {
  //     name: 'default',
  //     importance: Notifications.AndroidImportance.MAX,
  //     vibrationPattern: [0, 250, 250, 250],
  //     lightColor: '#FF231F7C',
  //   });
  // }
};

if (Platform.OS === 'ios' || Platform.OS === 'android') {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: true,
    }),
  });
}

const MILLISECONDS_IN_SECONDS = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const DAYS_IN_WEEK = 7;

export const howLongAgo = (timestamp: number | string | Date) => {
  const now = new Date();
  const dt = new Date(timestamp);

  const diffMs = +now - +dt;

  const diffSecs = diffMs / MILLISECONDS_IN_SECONDS;

  if (diffSecs < 60) {
    const secs = diffSecs === 1 ? 'sec' : 'secs';
    return `${Math.floor(diffSecs)} ${secs}`;
  }

  const diffMins = diffSecs / SECONDS_IN_MINUTE;

  if (diffMins < 60) {
    const mins = diffMins === 1 ? 'min' : 'mins';
    return `${Math.floor(diffMins)} ${mins}`;
  }

  const diffHours = diffMins / MINUTES_IN_HOUR;

  if (diffHours < 24) {
    const hours = diffHours === 1 ? 'h' : 'h';
    return `${Math.floor(diffHours)}${hours}`;
  }

  const diffDays = diffHours / HOURS_IN_DAY;

  if (diffDays < 7) {
    const days = diffDays === 1 ? 'd' : 'd';
    return `${Math.floor(diffDays)}${days}`;
  }

  const diffWeeks = diffDays / DAYS_IN_WEEK;

  const weeks = diffWeeks === 1 ? 'wk' : 'wk';
  return `${Math.floor(diffWeeks)}${weeks}`;
};

const DataURIToBlob = (dataURI: string) => {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const urlsFromText = (text: string): string[] => {
  const urls = text
    .toLowerCase()
    .match(
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/gi
    );

  return urls || [];
};

export const uploadFileRequest = async ({
  file,
  fileType,
  onFinish,
  onCancel,
  title = '',
}: {
  file:
  | Blob
  | {
    uri: string;
    name: string;
    type: string;
  };
  title: string;
  fileType: FileType;
  onFinish: ({ filePath, title }: { filePath: string; title: string }) => void;
  onCancel: () => void;
}) => {
  const idToken = await auth.currentUser?.getIdToken();
  if (!idToken) return onCancel();

  const headers = {
    Authorization: `Bearer ${idToken}`,
  };

  if (Platform.OS !== 'web') {
    headers['content-type'] = 'multipart/form-data';
  }

  const formData = new FormData();

  // @ts-expect-error
  formData.append('file', file);

  fetch(`${API_BASE_URL}chat/uploadFile?type=${fileType}`, {
    method: 'POST',
    headers,
    body: formData,
  })
    .then(async (res) => {
      const { filePath } = await res.json();

      onFinish({ filePath, title });
    })
    .catch((e) => {
      // alert(e);
      onCancel();
      console.log(e);
    });
};

export const uploadFile = async ({
  fileType,
  onFinish,
  onCancel,
}: {
  fileType: FileType;
  onFinish: ({ filePath, title }: { filePath: string; title: string }) => void;
  onCancel: () => void;
}) => {
  const isSimulator = !Device.isDevice;

  const idToken = await auth.currentUser?.getIdToken();
  if (!idToken) return onCancel();

  let uri: string;
  let name: string;
  let type: string;
  let title: string = '';

  if (fileType === 'image') {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [4, 3],
      quality: 0.5,
      // Due to error with allowsMultipleSelection
      allowsEditing: isSimulator,
      allowsMultipleSelection: false,
    });

    if (result.canceled) return onCancel();

    const file = result.assets[0];

    name = file.uri.split('/').pop();
    uri = file.uri;
    type = `${file.type}/${file.uri.split('.').pop()}`;
  } else {
    const file = await DocumentPicker.getDocumentAsync({});
    if (file.type !== 'success') return onCancel();

    uri = file.uri;
    name = file.name;
    type = file.mimeType;
    title = file.name;
  }

  let fileObj: any;

  if (Platform.OS === 'web') {
    fileObj = DataURIToBlob(uri);
  } else {
    fileObj = {
      uri,
      name,
      type,
    };
  }

  return uploadFileRequest({
    file: fileObj,
    fileType,
    onFinish,
    onCancel,
    title,
  });
};

export const shareThread = async ({
  threadId,
  i18n,
}: {
  threadId: string;
  i18n: I18N;
}) => {
  const threadUrl = `https://app.lailachat.com/share/${threadId}`;
  try {
    const messageText = 'Check out this chatbot conversation on Laila Chat AI:';
    const result = await Share.share({
      message: `${i18n[messageText] || messageText} ` + threadUrl,
    });
    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        // shared with activity type of result.activityType
      } else {
        // shared
      }
    } else if (result.action === Share.dismissedAction) {
      // dismissed
    }
  } catch (e) {
    console.error(e);
  }
};

export const windowHeight = () => {
  if (Platform.OS === 'web') {
    return window.innerHeight;
  }
  return Platform.OS === 'android' && Platform.Version >= 29
    ? Dimensions.get('window').height + StatusBar.currentHeight
    : Dimensions.get('window').height;
};

export const useNotificationListener = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const subscription = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        const data = response.notification.request.content.data;

        if (data.threadId && data.commentId) {
          navigate('/');
          setTimeout(() => navigate(`/thread/${data.threadId}/comments`), 200);
          return;
        } else if (data.threadId) {
          navigate('/');
          setTimeout(() => navigate(`/thread/${data.threadId}`), 200);
          return;
        } else {
          navigate('/home');
        }

        // Any custom logic to see whether the URL needs to be handled
        //...

        // Let React Navigation handle the URL
      }
    );

    return () => {
      subscription.remove();
    };
  }, []);
};

export function assertNever(shouldBeNever: never) { }

const alertPolyfill = (title, description, options, extra) => {
  const result = window.confirm(
    [title, description].filter(Boolean).join('\n')
  );

  if (result) {
    const confirmOption = options.find(({ style }) => style !== 'cancel');
    confirmOption && confirmOption.onPress();
  } else {
    const cancelOption = options.find(({ style }) => style === 'cancel');
    cancelOption && cancelOption.onPress();
  }
};

export const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

export const fitFontSize = ({
  text,
  maxFontSize,
  maxWidth,
}: {
  text: string;
  maxFontSize: number;
  maxWidth: number;
}) => {
  // approximate these by taking some representative samples
  const letterToWidthRatio = 0.82;
  const buffer = 3;

  let maxWordLength: number = text.length;

  const words = text.split(' ');
  if (words.length > 1) {
    const largestWord = words.reduce((a, b) => (a.length > b.length ? a : b));
    maxWordLength = largestWord.length;
  }

  const newFontSize = Math.min(
    maxFontSize,
    Math.ceil(maxWidth / (maxWordLength * letterToWidthRatio) + buffer)
  );

  if (!isNaN(newFontSize)) {
    return newFontSize;
  } else {
    return maxFontSize;
  }
};



export const sizing = {
  px: '1px',
  '0': 0,
  '0.5': 2,
  '1': 4,
  '1.5': 6,
  '2': 8,
  '2.5': 10,
  '3': 12,
  '3.5': 14,
  '4': 16,
  '5': 20,
  '6': 24,
  '7': 28,
  '8': 32,
  '9': 36,
  '10': 40,
  '12': 48,
  '16': 64,
  '20': 80,
  '24': 96,
  '32': 128,
  '40': 160,
  '48': 192,
  '56': 224,
  '64': 256,
  '72': 288,
  '80': 320,
  '96': 384,
  '1/2': '50%',
  '1/3': '33.333%',
  '2/3': '66.666%',
  '1/4': '25%',
  '2/4': '50%',
  '3/4': '75%',
  '1/5': '20%',
  '2/5': '40%',
  '3/5': '60%',
  '4/5': '80%',
  '1/6': '16.666%',
  '2/6': '33.333%',
  '3/6': '50%',
  '4/6': '66.666%',
  '5/6': '83.333%',
  full: '100%',
};