import {
  ArrowBackIcon,
  HStack,
  VStack,
  Input,
  Icon,
  KeyboardAvoidingView,
  ScrollView,
} from 'native-base';
import { useRef, useState } from 'react';
import {
  TextInput as RNINput,
  ScrollView as RNScrollView,
  Pressable,
} from 'react-native';
import { FontAwesome, Octicons, Ionicons } from '@expo/vector-icons';
import {
  useCommentFavoriteMutation,
  useCommentMutation,
  useThreadQuery,
} from '../api';
import { useNavigate, useParams } from '../components/Router';
import UserBadge from '../components/UserBadge';
import { useUser } from '../hooks';
import Loading, { useLoading } from '../components/Loading';
import { useOutletContext } from 'react-router-native';
import Options from '../components/Options';
import { api } from '../apiTypes';
import { Text } from '../ui';
import { usei18n } from '../utils/i18n';
import { colors } from '../utils/theme';

const CommentCard = ({
  comment,
  onFavorite,
  replyUsername,
  onReply,
  isFavorited,
}: {
  comment: api['db']['Comment'] & {
    creator: api['db']['PublicUser'];
    numFavorites: number;
  };
  onReply: (
    comment: api['db']['Comment'] & {
      creator: api['db']['PublicUser'];
      numFavorites: number;
    }
  ) => void;
  replyUsername: string;
  isFavorited: boolean;
  onFavorite: () => void;
}) => (
  <VStack bg='dark' p={2} rounded='lg'>
    <HStack justifyContent='space-between'>
      <UserBadge user={comment.creator} />
      <Options type='comment' comment={comment} />
    </HStack>
    {replyUsername && <Text color='textSecondary'>{`@${replyUsername}`}</Text>}
    <Text translate={false}>
      {replyUsername != null
        ? comment.text.split(' ').slice(1).join(' ')
        : comment.text}
    </Text>
    <HStack alignSelf='flex-end' alignItems='center' space='md'>
      <Pressable onPress={() => onReply(comment)}>
        <HStack space={1}>
          <Icon
            size='md'
            color='textSecondary'
            as={<Octicons name='reply' />}
          />
          <Text color='textSecondary'>Reply</Text>
        </HStack>
      </Pressable>

      <HStack alignItems='center' space='1'>
        <Pressable onPress={onFavorite}>
          <Icon
            size='sm'
            color={isFavorited ? 'red.500' : 'textSecondary'}
            as={<FontAwesome name={isFavorited ? 'heart' : 'heart-o'} />}
          />
        </Pressable>
        <Text translate={false}>{comment.numFavorites}</Text>
      </HStack>
    </HStack>
  </VStack>
);

const ThreadHeader = ({
  id,
  title,
  onGoBack,
}: {
  id: string;
  title: string;
  onGoBack: () => void;
}) => (
  <HStack justifyContent='space-between' alignItems='center'>
    <Pressable onPress={onGoBack} hitSlop={25}>
      <ArrowBackIcon style={{ height: 30, width: 30 }} />
    </Pressable>
    <Text>{title}</Text>
    <HStack />
  </HStack>
);

const Comments = () => {
  const [topHeight] = useOutletContext<[number]>();
  const { id: threadId } = useParams();
  const [user] = useUser();

  const {
    data: threadData,
    refetch: refetchThread,
    optimisticUpdate: optimisticThread,
  } = useThreadQuery(
    { id: threadId },
    { skip: threadId == null, cache: false }
  );

  const navigate = useNavigate();
  const inputRef = useRef<RNINput>();
  const scrollRef = useRef<RNScrollView>();

  const [addComment, { loading }] = useCommentMutation({
    onMutate: refetchThread,
  });
  const [addFavorite] = useCommentFavoriteMutation({ onMutate: refetchThread });

  const { translations: i18n } = usei18n();

  const [commentText, setCommentText] = useState('');
  const [replyTo, setReplyTo] = useState<string>();

  useLoading(loading);

  if (threadId == null || threadData == null) {
    return <Loading />;
  }

  const hasFavoritedComment = (
    comment: api['db']['Comment'] & {
      favorites: api['db']['CommentFavorite'][];
    }
  ) => (comment.favorites ?? []).find((f) => f.createdBy === user.uid) != null;

  const replyUserName = (userId: string) =>
    threadData.comments.find((c) => c.createdBy === userId)?.creator?.name;

  return (
    <KeyboardAvoidingView
      behavior='position'
      keyboardVerticalOffset={topHeight}
    >
      <VStack p={2} space={2} justifyContent='space-between' h='100%'>
        <ThreadHeader {...threadData} onGoBack={() => navigate(-1)} />
        <ScrollView h='80%' ref={scrollRef}>
          <VStack space={2}>
            {threadData.comments
              .sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt))
              .map((comment) => (
                <CommentCard
                  key={comment.id}
                  replyUsername={
                    comment.text.startsWith('@')
                      ? replyUserName(comment.text.split(' ')[0].slice(1))
                      : undefined
                  }
                  onReply={(comment) => {
                    setReplyTo(comment.createdBy);
                    inputRef?.current.focus();
                  }}
                  comment={{
                    ...comment,
                    creator: comment.creator!,
                    numFavorites: comment._count.favorites ?? 0,
                  }}
                  isFavorited={hasFavoritedComment(comment)}
                  onFavorite={() => {
                    optimisticThread({
                      ...threadData,

                      comments: threadData.comments.map((c) =>
                        c.id === comment.id
                          ? {
                              ...c,
                              _count: {
                                favorites:
                                  c._count.favorites +
                                  (hasFavoritedComment(comment) ? -1 : 1),
                              },
                              favorites: hasFavoritedComment(comment)
                                ? []
                                : ([
                                    {
                                      createdBy: user.uid,
                                    },
                                  ] as any),
                            }
                          : c
                      ),
                    });
                    addFavorite({
                      commentId: comment.id,
                      favorited: !hasFavoritedComment(comment),
                    });
                  }}
                />
              ))}
          </VStack>
        </ScrollView>
        <HStack bg='bgSecondary' rounded='full'>
          <Input
            ref={inputRef}
            placeholder={i18n['Write a comment'] || 'Write a comment'}
            // bg='white'
            color='text'
            _focus={{
              bg: 'bgSecondary',
            }}
            rounded='full'
            placeholderTextColor='text'
            w='100%'
            variant='unstyled'
            onChangeText={setCommentText}
            leftElement={
              replyUserName(replyTo) != null && (
                <Text color='primary' m={1} fontSize='xs'>
                  {'@' + replyUserName(replyTo)}
                </Text>
              )
            }
            rightElement={
              commentText.length > 0 ? (
                <Pressable
                  onPress={async () => {
                    await addComment({
                      threadId,
                      text: replyTo
                        ? `@${replyTo} ${commentText}`
                        : commentText,
                    });
                    setReplyTo(undefined);
                    setCommentText('');
                    setTimeout(() => scrollRef?.current.scrollToEnd(), 300);
                    inputRef?.current.blur();
                  }}
                >
                  <Ionicons name='send' size={16} color={colors.text} />
                </Pressable>
              ) : null
            }
            value={commentText}
          />
        </HStack>
      </VStack>
    </KeyboardAvoidingView>
  );
};

export default Comments;
