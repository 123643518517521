import Ionicons from '@expo/vector-icons/Ionicons';
import Material1 from '@expo/vector-icons/MaterialIcons';
import { Box, Button, HStack, Text, VStack } from '../ui';
import Logo from './Logo';
import { Pressable } from 'react-native';
import { useLocation, useNavigate } from './Router';
import { Divider, Icon } from 'native-base';
import { isDesktop } from '../utils';
import { useDrawer } from './Drawer';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import UserBadge from './UserBadge';
import { clearCache, useUpdateUserMutation, useUserQuery } from '../api';
import { useEffect, useState } from 'react';

import Animated, {
  useSharedValue,
  withTiming,
  useAnimatedStyle,
  BounceIn,
  Easing,
} from 'react-native-reanimated';
import { signOut } from '../firebase';
import { useModal } from './Modal';
import { useUser } from '../hooks';
import { useReload } from '../reloader';
import Tokens from './Tokens';

const pages: {
  title: string;
  icon: {
    default: JSX.Element;
    selected: JSX.Element;
  };
  path: string;
}[] = [
  {
    title: 'Home',
    icon: {
      default: <Material1 name='home-filled' />,
      selected: <Material1 name='home-filled' />,
    },
    path: '/home',
  },
  // {
  //   title: 'Prompts',
  //   icon: {
  //     default: <Ionicons name='color-wand'  />,
  //     selected: <Ionicons name='color-wand' color='white' />,
  //   },
  //   path: '/prompts',
  // },
  {
    title: 'Community',
    icon: {
      default: <Material1 name='groups' />,
      selected: <Material1 name='groups' />,
    },
    path: '/explore',
  },
  {
    title: 'Chats',
    icon: {
      default: <Ionicons name='chatbubble' />,
      selected: <Ionicons name='chatbubble' />,
    },
    path: '/chats',
  },

  // {
  //   title: 'Profile',
  //   icon: {
  //     default: <FontAwesome name='user-circle' />,
  //     selected: <FontAwesome name='user-circle-o' />,
  //   },
  //   path: '/profile',
  // },
];

const SideNavbar = () => {
  const randomWidth = useSharedValue(10);
  const location = useLocation();

  const { openModal } = useModal();

  const [updateUser] = useUpdateUserMutation();

  const config = {
    duration: 500,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
  };

  const style = useAnimatedStyle(() => {
    return {
      width: withTiming(randomWidth.value, config),
    };
  });
  const navigate = useNavigate();
  const { closeDrawer, isOpen: drawerIsOpen } = useDrawer();

  const {
    loading: userIsLoading,
    data: userData,
    refetch: refetchUser,
  } = useUserQuery({});
  const [user] = useUser();

  const { reloadData } = useReload();

  useEffect(() => {
    if (reloadData.tokens > 0) {
      refetchUser();
      return;
    }
    if (userData?.email == '' || userData?.email == null) {
      refetchUser({ shouldUseCache: true });
    }
  }, [reloadData]);

  const [userOptionsAreShowing, setUserOptionsAreShowing] = useState(false);

  useEffect(() => {
    setUserOptionsAreShowing(false);
  }, [location, drawerIsOpen]);

  return (
    <VStack
      w={isDesktop() ? '250px' : '100%'}
      bg='bgSecondary'
      justifyContent='space-between'
      h={'100%'}
      // h={totalHeight * (1 - topPctOfView)}
      style={{
        position: 'fixed',
        left: 0,
      }}
      pt={isDesktop() ? 8 : 2}
    >
      <VStack>
        {isDesktop() ? (
          <Box pl={6}>
            <Logo />
          </Box>
        ) : (
          <HStack justifyContent='space-between' px={2} pl={3}>
            <Pressable onPress={closeDrawer}>
              <HStack pt={3} space={1} alignItems='center'>
                <Icon
                  as={<AntDesign name='arrowleft' />}
                  color='primary'
                  size={5}
                />
                <Text color='secondary' fontWeight={500} fontSize={14}>
                  Return
                </Text>
              </HStack>
            </Pressable>
            <Icon
              as={<Ionicons name='ios-menu-outline' />}
              color='primary'
              onPress={closeDrawer}
              size={6}
            />
          </HStack>
        )}
        <Box mt={6} mb={2} ml={2}>
          <Tokens tokens={userData?.tokens ?? 0} />
        </Box>
        <Button
          roundedLeft='none'
          w={isDesktop() ? '195px' : '65%'}
          // mt={6}
          mb={2}
          py={3}
          onPress={() => navigate('/chat')}
        >
          <HStack space={0.5} alignItems='center'>
            <Text color='bgSecondary' fontSize={18} fontWeight={500}>
              +
            </Text>
            <Text color='bgSecondary' fontSize={18} fontWeight={500}>
              New Chat
            </Text>
          </HStack>
        </Button>
        <VStack pl={4} space={2}>
          {pages.map(({ path, title, icon }) => (
            <Pressable
              key={path}
              onPress={() => {
                navigate(path);
              }}
            >
              <HStack alignItems='center' space={4}>
                <Icon
                  // mb='1'

                  as={location.pathname === path ? icon.selected : icon.default}
                  color={location.pathname === path ? 'primary' : 'secondary'}
                  size={5}
                />
                <Text
                  color={location.pathname === path ? 'primary' : 'secondary'}
                  fontSize={18}
                  fontWeight={500}
                >
                  {title}
                </Text>
              </HStack>
            </Pressable>
          ))}
        </VStack>
      </VStack>

      <VStack space={2}>
        {!user?.isAnonymous && userData?.planId == null && (
          <Pressable onPress={() => navigate('/plan')}>
            <VStack space={1} pl={3}>
              <Text px={2} color='primary' fontSize={16} fontWeight={500}>
                Plus Plan
              </Text>
              <Text px={2} color='secondary' fontSize={16} fontWeight={500}>
                Update your plan to unlock all of the benefits.
              </Text>
            </VStack>
          </Pressable>
        )}
        {/* <Divider />
        <Pressable>
          <HStack>
            <Icon as={<Ionicons name='ios-download-outline' />} />
            <Text>Download App</Text>
          </HStack>f
        </Pressable> */}

        <VStack>
          {userOptionsAreShowing && (
            <VStack space={1} pb={2}>
              <Pressable onPress={() => navigate('/profile')}>
                <HStack bg='gray' space={4} py={2} px={3} alignItems='center'>
                  <Icon
                    size={6}
                    color='secondary'
                    as={<Ionicons size={24} name='settings-sharp' />}
                  />
                  <Text fontSize={18} fontWeight={500} color='secondary'>
                    Settings
                  </Text>
                </HStack>
              </Pressable>
              <Pressable
                // onPress={() => navigate('/profile')}

                onPress={async () => {
                  await signOut();
                  await clearCache();
                  navigate('/home');
                }}
              >
                <HStack bg='gray' space={4} py={2} px={3} alignItems='center'>
                  <Icon
                    pl={1}
                    size={6}
                    color='secondary'
                    as={<Material1 name='logout' />}
                  />
                  <Text fontSize={18} fontWeight={500} color='secondary'>
                    Sign Out
                  </Text>
                </HStack>
              </Pressable>
            </VStack>
          )}

          {user?.isAnonymous ? (
            <VStack bg='gray' py={2} pl={3}>
              <Button
                w='60%'
                onPress={() => {
                  openModal({ type: 'register' });
                }}
              >
                <Text color='bgSecondary'>Sign In</Text>
              </Button>
            </VStack>
          ) : (
            <Pressable
              onPress={() => {
                setUserOptionsAreShowing(!userOptionsAreShowing);
              }}
            >
              <VStack bg='gray' py={2} pl={3}>
                <UserBadge user={userData} />
              </VStack>
            </Pressable>
          )}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default SideNavbar;
