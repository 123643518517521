import { HStack, Image, Text } from 'native-base';
import CachedImage from './CachedImage';
import { api } from '../apiTypes';
import { usei18n } from '../utils/i18n';
import { useAssets } from 'expo-asset';

export const DefaultImage = ({ size }: { size: number }) => {
  const [assets] = useAssets([require('../assets/profile_icon.png')]);
  return (
    <Image
      alt='Profile Image'
      rounded='full'
      bg='gray.200'
      size={size}
      source={{ uri: assets?.[0]?.uri }}
    />
  );
};
export const UserImage = ({
  user,
  size = 6,
}: {
  user?: api['db']['PublicUser'];
  size?: number;
}) =>
  user?.profileImage != null ? (
    <CachedImage
      size={size}
      rounded='full'
      source={{
        uri: user.profileImage,
      }}
      // rounded='full'
      // size={6}
    />
  ) : (
    <DefaultImage size={size} />
  );

const UserBadge = ({ user }: { user: api['db']['User'] }) => {
  const { translations: i18n } = usei18n();
  return (
    <HStack space='xs' alignItems='center'>
      <UserImage user={user} size={8} />
      <Text fontSize={16} color='secondary' fontWeight={500}>
        {user?.name ??
          user?.email ??
          `${i18n.User || 'User'} ${(user?.id || '').slice(0, 7)}`}
      </Text>
    </HStack>
  );
};

export default UserBadge;
