import { Button, HStack, Text, VStack } from '../ui';

import { FontAwesome, MaterialIcons, Ionicons } from '@expo/vector-icons';
import { howLongAgo, shareThread } from '../utils';
import { useNavigate } from './Router';
import UserBadge from './UserBadge';
import { useUser } from '../hooks';
import {
  API_BASE_URL,
  useThreadFavoriteMutation,
  useThreadUpvoteMutation,
} from '../api';
import ChatPromptCard from './ChatPromptCard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../apiTypes';
import { colors } from '../utils/theme';
import { usei18n } from '../utils/i18n';

type ThreadInteractionProps = {
  upvoted: boolean;
  numVotes: number;
  numComments: number;
  onUpvote: () => void;
  onFavorite: () => void;
  onShare: () => void;
  favorited: boolean;
};

export const threadIsUpvoted = (
  thread: api['db']['Thread'] & { votes: api['db']['ThreadVote'][] },
  userId: string
) =>
  thread == null
    ? false
    : (thread.votes ?? []).find((v) => v.createdBy === userId) != null;

export const threadIsFavorited = (
  thread: api['db']['Thread'] & { favorites: api['db']['ThreadFavorite'][] },
  userId: string
) =>
  thread == null
    ? false
    : (thread.favorites ?? []).find((v) => v.createdBy === userId) != null;

type InteractiveThread = api['db']['Thread'] & {
  messages: api['db']['Message'][];
  votes: api['db']['ThreadVote'][];
  favorites: api['db']['ThreadFavorite'][];
  creator: api['db']['PublicUser'];

  _count: { votes: number; comments: number };
};

export const ThreadInteraction = <T extends InteractiveThread>({
  thread,
  onChange,
  onOptimisticUpdate,
}: // numVotes,
// onUpvote,
// upvoted,
// onShare,
// favorited,
// onFavorite,
// numComments = 0,
{
  onChange: () => void;
  onOptimisticUpdate: (thread: T) => void;
  thread: T;
}) => {
  const navigate = useNavigate();
  const [user] = useUser();
  const { translations: i18n } = usei18n();

  const [upvoteThread] = useThreadUpvoteMutation({ onMutate: onChange });
  const [favoriteThread] = useThreadFavoriteMutation({
    onMutate: onChange,
  });

  const upvoted = threadIsUpvoted(thread, user.uid);
  const numVotes = thread._count.votes;
  const numComments = thread._count.comments;
  // onShare={() => onShare(thread)}

  const upvoteFunc = () => {
    onOptimisticUpdate({
      ...thread,
      votes: threadIsUpvoted(thread, user.uid)
        ? []
        : ([
            {
              createdBy: user.uid,
            },
          ] as any),
      _count: {
        ...thread._count,
        votes:
          thread._count.votes + (threadIsUpvoted(thread, user.uid) ? -1 : 1),
      },
    });

    upvoteThread({
      threadId: thread.id,
      upvoted: !threadIsUpvoted(thread, user.uid),
    });
  };
  const favorited = threadIsFavorited(thread, user.uid);
  const favoriteFunc = () => {
    onOptimisticUpdate({
      ...thread,
      favorites: threadIsFavorited(thread, user.uid)
        ? []
        : ([
            {
              createdBy: user.uid,
            },
          ] as any),
    });

    // onOptimisticUpdate({
    //   ...publicThreadsData,
    //   threads: publicThreadsData.threads.map((t) =>
    //     t.id === thread.id
    //       ? {
    //           ...t,
    //           favorites: threadIsFavorited(thread, user.uid)
    //             ? []
    //             : ([
    //                 {
    //                   createdBy: user.uid,
    //                 },
    //               ] as any),
    //         }
    //       : t
    //   ),
    // });

    favoriteThread({
      threadId: thread.id,
      favorited: !threadIsFavorited(thread, user.uid),
    });

    AsyncStorage.removeItem(API_BASE_URL + 'chat/threads' + '/cacheExpiration');
  };

  const buttonProps = {
    m: 0,
    p: 0,
  };
  const iconSize = 18;

  return (
    <HStack justifyContent='space-between' h={8}>
      <HStack space={2} w='35%'>
        <Button
          // bg={upvoted ? 'rgb(64,60,189)' : 'secondary'}
          bg='none'
          onPress={upvoteFunc}
          w='50%'
          {...buttonProps}
        >
          <HStack alignItems='center' space={1}>
            <FontAwesome
              name='arrow-up'
              size={iconSize}
              color={upvoted ? colors.primary : colors.secondary}
            />
            <Text>{numVotes}</Text>
          </HStack>
        </Button>
        <Button
          // bg={'secondary'}
          bg='none'
          onPress={() => navigate(`/thread/${thread.id}/comments`)}
          w='50%'
          {...buttonProps}
        >
          <HStack alignItems='center' space={1}>
            <MaterialIcons name='chat' size={iconSize} color={colors.text} />
            <Text>{numComments}</Text>
          </HStack>
        </Button>
      </HStack>

      <HStack space={2}>
        <Button
          onPress={() => shareThread({ threadId: thread.id, i18n })}
          // bg='secondary'
          bg='none'
          my={0}
          py={0}
        >
          <Ionicons
            name='ios-share-outline'
            size={iconSize}
            color={colors.text}
          />
        </Button>

        <Button
          onPress={favoriteFunc}
          bg='none'
          // bg={favorited ? 'rgb(64,60,189)' : 'secondary'}
          my={0}
          py={0}
        >
          <FontAwesome
            name={favorited ? 'bookmark' : 'bookmark-o'}
            size={iconSize}
            color={colors.text}
          />
        </Button>
      </HStack>
    </HStack>
  );
};

type Prompt = api['categories']['response'][number]['prompts'][number];

const ThreadCard = ({
  thread,
  prompt,
  canInteract = true,
  onChange = () => {},
  onOptimisticUpdate = () => {},
}: (
  | {
      thread: InteractiveThread;
      canInteract: true;
      prompt: Prompt | null;
    }
  | {
      thread: api['db']['Thread'] & {
        creator: api['db']['PublicUser'];
        messages: api['db']['Message'][];
      };
      canInteract?: false;
      prompt: Prompt | null;
    }
) & {
  onChange?: () => void;
  onOptimisticUpdate?: (thread: InteractiveThread) => void;
}) => {
  const messages = thread?.messages || [];
  222;
  const threadSummary =
    messages.length > 1
      ? messages[1].data?.startsWith('{"file":') ||
        messages[1].data?.startsWith('{"inputs":') ||
        messages[1].data?.startsWith('{"type":')
        ? ''
        : messages[1].data
      : '...';

  return (
    <VStack
      // bg='purple.900'
      p={2}
      // borderColor='textSecondary'
      // borderWidth={1}
      rounded='lg'
      space={2}
      // borderColor={'purple.500'}
      // borderWidth='1'
    >
      <HStack justifyContent='space-between'>
        <HStack alignItems='center' space={2}>
          <UserBadge user={thread.creator} />
          <Text fontSize='xl'>·</Text>
          <Text>{howLongAgo(thread.createdAt)}</Text>
        </HStack>
        {prompt != null && (
          <ChatPromptCard
            fontSize={11}
            showImage={false}
            prompt={prompt}
            selected={false}
          />
        )}
      </HStack>
      <Text numberOfLines={2} fontSize='lg' translate={false}>
        {thread.title}
      </Text>
      <Text numberOfLines={3} m={2} color='text'>
        {threadSummary}
      </Text>

      {canInteract && (
        <ThreadInteraction
          thread={thread as InteractiveThread}
          onChange={onChange}
          onOptimisticUpdate={onOptimisticUpdate}
        />
      )}
    </VStack>
  );
};

export default ThreadCard;
