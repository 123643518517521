import { Center, HStack, Icon, Pressable, VStack } from 'native-base';
import Ionicons from '@expo/vector-icons/Ionicons';
import Material1 from '@expo/vector-icons/MaterialIcons';
import { useLocation, useNavigate } from './Router';
import { Text } from '../ui';

const pages: {
  title: string;
  icon: {
    default: JSX.Element;
    selected: JSX.Element;
  };
  path: string;
}[] = [
  {
    title: 'Home',
    icon: {
      default: <Material1 name='home-filled' />,
      selected: <Material1 name='home-filled' />,
    },
    path: '/home',
  },
  // {
  //   title: 'Prompts',
  //   icon: {
  //     default: <Ionicons name='color-wand'  />,
  //     selected: <Ionicons name='color-wand' color='white' />,
  //   },
  //   path: '/prompts',
  // },
  {
    title: 'Chats',
    icon: {
      default: <Ionicons name='chatbubble' />,
      selected: <Ionicons name='chatbubble' />,
    },
    path: '/chats',
  },
  {
    title: 'Community',
    icon: {
      default: <Material1 name="groups" />,
      selected: <Material1 name="groups" />,
    },
    path: '/explore',
  },
  // {
  //   title: 'Profile',
  //   icon: {
  //     default: <FontAwesome name='user-circle' />,
  //     selected: <FontAwesome name='user-circle-o' />,
  //   },
  //   path: '/profile',
  // },
];

const NavFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <HStack
      px={8}
      // bg="black"
      bg='bg'
      alignItems='center'

      // shadow={6}
    >
      {pages.map((page, idx) => (
        <Pressable
          onPress={() => {
            navigate(page.path);
          }}
          key={idx}
          //   curso='pointer'
          // opacity={location.pathname === page.path ? 1 : 0.5}
          // size="2xl"
          pt='2'
          flex={1}
        >
          <Center>
            <VStack alignItems='center'>
              <Icon
                // mb='1'

                as={
                  location.pathname === page.path
                    ? page.icon.selected
                    : page.icon.default
                }
                color={
                  location.pathname === page.path ? 'primary' : 'secondary'
                }
                size='xl'
              />
              <Text
                color={
                  location.pathname === page.path ? 'primary' : 'secondary'
                }
                fontSize='12'
              >
                {page.title}
              </Text>
            </VStack>
            {/* <Text color='white' fontSize='12'>
              {page.title}
            </Text> */}
          </Center>
        </Pressable>
      ))}
    </HStack>
  );
};

export default NavFooter;
