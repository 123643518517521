export const colors = {
  bg: 'rgb(243,243,243)',
  bgSecondary: 'rgb(253,253,253)',
  primary: 'rgb(95,111,238)',

  secondary: 'rgb(113,113,113)',
  text: 'rgb(48,48,48)',
  gray: 'rgba(180,180,180, .5)',
  // dark: 'rgb(10,11,37)',
  // border: 'rgb(55,54,93)',
  // textSecondary: 'rgb(133,137,181)',
};

const theme = {
  colors,
  components: {
    Icon: {
      defaultProps: { color: 'text' },
    },
    Button: {
      defaultProps: {
        _hover: {
          bg: 'blue.600',
        },
        bg: 'primary',
        rounded: 'full',
      },
    },
  },
  fontConfig: {
    Main: {
      100: {
        normal: 'Light_300',
        italic: 'Regular_Italic_400',
      },
      200: {
        normal: 'Light_300',
        italic: 'Regular_Italic_400',
      },
      300: {
        normal: 'Light_300',
        italic: 'Regular_Italic_400',
      },
      400: {
        normal: `Regular_400`,
        italic: `Regular_Italic_400`,
      },
      500: {
        normal: 'Medium_500',
      },
      600: {
        normal: 'SemiBold_600',
      },
      700: {
        normal: 'Bold_700',
      },
      // Add more variants
      //   700: {
      //     normal: 'Roboto-Bold',
      //   },
      //   800: {
      //     normal: 'Roboto-Bold',
      //     italic: 'Roboto-BoldItalic',
      //   },
      //   900: {
      //     normal: 'Roboto-Bold',
      //     italic: 'Roboto-BoldItalic',
      //   },
    },
  },

  // Make sure values below matches any of the keys in `fontConfig`
  fonts: {
    heading: 'Main',
    body: 'Main',
    mono: 'Main',
  },
};

export default theme;
