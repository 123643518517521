import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFileRequest } from '../utils';

const Dropzone = ({
  children,
  onUpload,
  onLoading,
}: {
  children: React.ReactNode;
  onUpload: (uri: string) => void;
  onLoading?: (loading: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (onLoading) {
      onLoading(loading);
    }
  }, [loading]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0) return;
    const file = acceptedFiles[0];

    setLoading(true);

    uploadFileRequest({
      file,
      title: 'Image',
      fileType: 'image',
      onFinish: ({ filePath }) => {
        onUpload(filePath);
        setLoading(false);
      },
      onCancel: () => {
        setLoading(false);
      },
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  return (
    <div
      style={{ width: '100%', height: '100%', cursor: 'pointer' }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default Dropzone;
