import {
  ArrowBackIcon,
  Box,
  HStack,
  Icon,
  KeyboardAvoidingView,
  Pressable,
  Text,
  useToast,
} from 'native-base';
import Markdown from 'react-native-markdown-display';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  API_BASE_URL,
  dev,
  useCategoriesQuery,
  useConfigQuery,
  useCreateThreadMutation,
  useSendMessageMutation,
  useThreadQuery,
  useThreadsQuery,
  useUserQuery,
} from '../api';
import Loading, { useLoading } from '../components/Loading';
import { useLocation, useNavigate, useParams } from '../components/Router';
import {
  ActivityIndicator,
  Dimensions,
  Image,
  Linking,
  ScrollView as RNScrollView,
  View,
} from 'react-native';
import { AntDesign, Ionicons, MaterialIcons } from '@expo/vector-icons';
import SendMessage, { SendMessageRef } from '../components/SendMessage';
import ThreadHistory from '../components/ThreadHistory';
import { useOutletContext } from 'react-router-native';
import { ThreadInteraction } from '../components/ThreadCard';
import { DefaultImage, UserImage } from '../components/UserBadge';
import useTimeout, { useUser } from '../hooks';
import FileCard from '../components/FileCard';
import ThreadOptions from '../components/Options';
import ThreadTitleEditor from '../components/ThreadTitleEditor';
import { Navigate } from '../components/Router';
import ChatPromptCard from '../components/ChatPromptCard';
import Bot from '../icons/Bot';
import Clipboard from '../utils/clipboard';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../apiTypes';
import MobileStoreButton from '../components/MobileStoreButton';
import { usei18n } from '../utils/i18n';
import EventSource from '../utils/EventSource';
import { isDesktop, isWeb, windowHeight } from '../utils';
import CachedImage from '../components/CachedImage';
import { VStack } from '../ui';
import StructuredMessage from '../components/StructuredMessage';
import { useReload } from '../reloader';

type Props = {
  isPublic?: boolean;
};

const topPct = ({
  threadData,
  hasFiles,
  hasImagePrompt,
}: {
  threadData: api['thread']['response'];
  hasFiles: boolean;
  hasImagePrompt: boolean;
}) => {
  const hasFilesPct = hasFiles ? 0.1 : 0;
  const hasImagePct = hasImagePrompt ? 0.04 : 0;

  const smallScreenDeduction = Dimensions.get('screen').height > 800 ? 0 : 0.1;
  if (threadData == null) return 1;

  if (threadData.prompt == null)
    return 0.87 - smallScreenDeduction - hasFilesPct - hasImagePct;

  if (threadData.isPublic)
    return 0.8 - smallScreenDeduction - hasFilesPct - hasImagePct;

  return 0.78 - smallScreenDeduction - hasFilesPct - hasImagePct;
};

const sendMessageAPI = async ({
  threadId,
  message,
  userToken,
  onResponse,
  onEnd,
  retry = false,
}: {
  threadId: string;
  message: string;
  userToken: string;
  onResponse: (message: string) => void;
  onEnd: (error: boolean) => void;
  retry?: boolean;
}) => {
  let newContent = '';

  const debouncedResponse = debounce(onResponse, 20);

  const body: api['sendMessage']['params'] = {
    files: [],
    stream: true,
    threadId,
    data: message,
    retry,
  };

  // const url = API_BASE_URL + 'sendmessage';
  let url = dev
    ? 'http://localhost:5001/dreambooth-31489/us-central1/sendmessage'
    : 'https://sendmessage-a2drywstmq-uc.a.run.app';
  // let url =

  const queryParams = {
    files: JSON.stringify([]),
    stream: 'true',
    threadId,
    data: message,
    retry: retry ? 'true' : 'false',
  };

  const params = new URLSearchParams(queryParams);

  if (isWeb) {
    url = `${url}?${params.toString()}`;
  }

  //Initiate the requests
  const es = new EventSource(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
    method: 'POST',
    body: JSON.stringify(body),
    // pollingInterval: 1000,
  });

  // Listen the server until the last piece of text
  const listener = (event) => {
    console.log({ event });
    if (event.type === 'open') {
      onResponse('');
      console.log('Open SSE connection.');
    } else if (event.type === 'message') {
      if (event.data !== '[DONE]') {
        const data = JSON.parse(event.data);
        const delta = data.choices[0].delta;
        const finish_reason = data.choices[0].finish_reason;

        if (finish_reason === 'stop') {
          if (es.removeAllEventListeners) {
            es?.removeAllEventListeners();
          }

          es?.close();
          onEnd(!!data?.choices?.[0].error);
        } else {
          if (delta && delta.content) {
            // Update content with new data
            newContent = newContent + delta.content;

            debouncedResponse(newContent);
          }
        }
      } else {
        es.close();
      }
    } else if (event.type === 'error') {
      console.error('Connection error:', event.message);
    } else if (event.type === 'exception') {
      console.error('Error:', event.message, event.error);
    }
  };

  // Add listener
  es.addEventListener('open', listener);
  es.addEventListener('message', listener);
  es.addEventListener('error', listener);
};

let isProgramaticScroll = false;

const scrollToEnd = (scrollRef: any) => {
  isProgramaticScroll = true;
  scrollRef?.current?.scrollToEnd({
    animated: false,
  });
};

const debouncedScrollToEnd = debounce(scrollToEnd, 200);
const Thread = ({ isPublic = false }: Props) => {
  const height = windowHeight();

  const [user] = useUser();
  const { data: userData } = useUserQuery({});
  const params = useParams();
  const location = useLocation();
  const { search } = location;
  const [initialIndex, setInitialIndex] = useState<number>();
  const [hasCreatedNew, setHasCreatedNew] = useState(false);
  const [hasStreamingError, setHasStreamingError] = useState(false);

  const [isReceivingLiveMessage, setIsReceivingLiveMessage] = useState(false);

  const currentOffset = useRef(0);

  const { language, i18n } = usei18n();

  const threadId = params.id;

  const { reload } = useReload();

  const {
    data: threadData,
    refetch: refetchThread,
    loading: loadingThread,
    optimisticUpdate: optimisticThread,
  } = useThreadQuery(
    { id: threadId },
    // No auth for web as is public
    { skip: threadId == null, cache: false, auth: !isPublic }
  );

  console.log({ messages: threadData?.messages });

  const { data: configData } = useConfigQuery();

  const page = useMemo(
    () => (configData?.pages || []).find((p) => p.id === threadData?.pageId),
    [threadData?.pageId]
  );

  const { data: categoriesData } = useCategoriesQuery({
    pageId: threadData?.pageId,
  });

  useEffect(() => {
    const params = new URLSearchParams(search);
    const newParam = params.get('new');

    if (
      newParam == null ||
      user == null ||
      userData == null ||
      categoriesData == null
    ) {
      return;
    }

    const {
      message,
      files,
      prompt: inputPrompt,
      initialIndex,
      pageId,
    } = JSON.parse(newParam) as {
      message: string;
      files: Parameters<
        React.ComponentProps<typeof SendMessage>['onSendMessage']
      >[0]['files'];
      prompt?: api['categories']['response'][number]['prompts'][number];
      initialIndex: number;
      pageId: string | null;
    };

    if (initialIndex != null) {
      setInitialIndex(initialIndex);
    }

    if (threadId == null) return;

    let title = message;
    if (title.length === 0 && prompts.length > 0) {
      const promptLabel = i18n(inputPrompt).label;
      title = promptLabel ?? '';
    }

    const newThreadData = {
      id: threadId,
      createdBy: user.uid,
      title,
      isPublic: false,
      pageId,
      prompt: inputPrompt?.id || null,
      createdAt: new Date(),
      isDeleted: false,
      files: files.map((f, idx) => ({
        id: `file-${idx}`,
        isProcessed: false,
        threadId,
        createdAt: new Date(),
        ...f,
      })),
      messages: [
        {
          id: 'latestUser',
          data: message,
          createdAt: new Date(new Date().getTime() - 1),
          isBot: false,
          threadId,
          isCanceled: false,
          isDeleted: false,
          userId: user.uid,
        },
        {
          id: 'live',
          data: null,
          createdAt: new Date(),
          isBot: true,
          threadId,
          isCanceled: false,
          isDeleted: false,
          userId: null,
        },
      ],
      creator: userData,
      votes: [],
      favorites: [],
      comments: [],
      _count: {
        votes: 0,
        comments: 0,
      },
    };
    optimisticThread(newThreadData);

    createThread({
      id: threadId,
      message,
      files,
      prompts: inputPrompt != null ? [inputPrompt.id] : [],
      language,
      pageId,
    }).then(async (res) => {
      AsyncStorage.removeItem(
        API_BASE_URL + 'chat/threads' + '/cacheExpiration'
      );

      if (inputPrompt?.type === 'text-to-image') {
        sendMessage({
          threadId,
          data: message,
          files: files,
          stream: false,
        }).then(() => {
          reload('tokens');
        });
        return;
      }

      if (files.length > 0) {
        sendMessage({
          threadId: threadId,
          data: message,
          files: [],
          stream: false,
        }).then(() => {
          reload('tokens');
        });
        return;
      }

      setIsReceivingLiveMessage(true);

      sendMessageAPI({
        threadId,
        message,
        userToken: await user.getIdToken(),
        // TODO refactor as this duplicated the other call for this function
        onResponse: (liveMessage) => {
          // if (message === '') {
          //   setTimeout(refetchThread, 100);
          // }
          // setLiveMessage(message);
          optimisticThread({
            ...newThreadData,
            messages: [
              {
                id: 'latestUser',
                data: message,
                createdAt: new Date(new Date().getTime() - 1),
                isBot: false,
                threadId,
                isCanceled: false,
                isDeleted: false,
                userId: user?.uid,
              },
              {
                id: 'live',
                data: liveMessage,
                createdAt: new Date(),
                isBot: true,
                threadId,
                isCanceled: false,
                isDeleted: false,
                userId: null,
              },
            ],
          });
          if (!hasScrolledRef.current) {
            isProgramaticScrollRef.current = true;
            debouncedScrollToEnd(scrollRef);
          }
        },
        onEnd: (hasError) => {
          reload('tokens');
          setIsReceivingLiveMessage(false);

          setHasStreamingError(hasError);

          if (hasError) return;

          setTimeout(async () => {
            await refetchThread().then((thread) => {
              const newThreads = (threadsRef.current?.threads || []).map((t) =>
                t.id === thread?.id ? thread : t
              );

              if (newThreads.find((t) => t.id === thread?.id) == null) {
                if (thread != null) {
                  newThreads.push(thread);
                }
              }

              optimisticThreads({
                ...(threadsData || {
                  favorites: [],
                  pageFavorites: [],
                  recentPages: [],
                }),
                threads: newThreads,
              });
            });
            setTimeout(() => {
              if (isDesktop()) {
                sendMessageRef.current?.focusInput();
              }
              if (!hasScrolledRef.current) {
                debouncedScrollToEnd(scrollRef);
              }
            }, 200);
          }, 500);
          setLiveMessage(undefined);
        },
      });
    });

    setHasCreatedNew(true);
    // clear new params

    navigate('.', { replace: true });

    // const new2 = params.get('new2');
    // console.log({new1, new2})
  }, [user, search, userData, categoriesData]);

  const [liveMessage, setLiveMessage] = useState<string | undefined>(undefined);

  const outletContext = useOutletContext<[number, number]>();

  const [hasFiles, setHasFiles] = useState(false);
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const hasScrolledRef = useRef<boolean>();

  const sendMessageRef = useRef<SendMessageRef>();

  hasScrolledRef.current = userHasScrolled;

  const isProgramaticScrollRef = useRef<boolean>();

  const [historyIsOpen, setHistoryIsOpen] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [counter, setCounter] = useState(0);

  const toast = useToast();

  const [createThread, { data: createdThread, loading: isCreatingThread }] =
    useCreateThreadMutation();

  const { data: threadsData, optimisticUpdate: optimisticThreads } =
    useThreadsQuery({});

  const threadsRef = useRef<typeof threadsData>();
  threadsRef.current = threadsData;

  const prompts = (categoriesData ?? []).flatMap((c) => c.prompts);
  const prompt = prompts.find(
    (p) => p.id === threadData?.prompt?.split(',')?.[0]
  );

  const timeoutRef = useRef<NodeJS.Timeout>();

  const [sendMessage, { loading: isSendingMessage }] = useSendMessageMutation({
    onMutate: refetchThread,
  });

  // useLoading(isSendingMessage);

  // useEffect(() => {
  //   if (threadData == null) return;
  //   // scrollRef?.current?.scrollToEnd({
  //   //   animated: false,
  //   // });
  // }, [threadData])

  useTimeout(() => {
    if (threadData == null) return;
    const messages = threadData?.messages ?? [];
    const messagesLength = messages.length;

    const hasNoMessages = messagesLength === 0;

    const lastMessage =
      messages.length > 0
        ? messages.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          )[messages.length - 1]
        : null;

    const awaitingBotResponse = lastMessage?.isBot && lastMessage?.data == null;

    const hasFilesProcessing = (threadData?.files ?? []).some(
      (f) => !f.isProcessed
    );

    const hasLiveMessage = threadData?.messages?.some((m) => m.id === 'live');

    const shouldRefetch =
      (!hasLiveMessage ||
        (hasLiveMessage && prompt?.type === 'text-to-image')) &&
      (hasNoMessages || awaitingBotResponse || hasFilesProcessing);

    if (shouldRefetch) {
      setCounter((c) => c + 1);
      refetchThread().then(() => {
        if (!hasScrolledRef.current) {
          setTimeout(() => debouncedScrollToEnd(scrollRef), 300);
        }
      });
    }
  }, 2000);

  const scrollRef = useRef<RNScrollView>(null);
  const publicScrollRef = useRef<any>(null);
  const navigate = useNavigate();

  const onCopy = useCallback((text: string) => {
    Clipboard.copy(text).then(() =>
      toast.show({
        title: 'Copied',
      })
    );
  }, []);

  const isNews = (threadData?.prompt || '').endsWith('news');

  const isCreator = threadData != null && threadData.createdBy === user?.uid;

  // console.log(threadData.messages);

  const Thread = useMemo(() => {
    if (threadData == null || threadId == null) return null;

    let messages = Array.from(threadData?.messages) ?? [];

    messages.push(
      ...(threadData?.files || []).map((f) => ({
        ...f,
        isBot: false,
        isCanceled: false,
        data: '',
        userId: threadData.createdBy,
        isDeleted: false,
      }))
    );

    const awaitingBotResponse =
      messages.length > 0 &&
      !Array.from(messages).sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )[messages.length - 1].isBot &&
      (threadData?.files?.length ?? 0) > 0;

    if (awaitingBotResponse) {
      messages.push({
        createdAt: new Date(),
        id: 'awaiting-bot',
        threadId,
        isBot: true,
        isCanceled: false,
        data: null,
        userId: threadData.createdBy,
        isDeleted: false,
      });
    }

    if (hasStreamingError) {
      messages.push({
        createdAt: new Date(),
        id: 'error',
        threadId,
        isBot: true,
        isCanceled: false,
        data: null,
        userId: threadData.createdBy,
        isDeleted: false,
      });

      messages = messages.filter((m) => m.id !== 'live');
    }

    messages = messages.filter((m, idx) =>
      idx === 0
        ? m.data !== '' && m.data !== prompt?.label
        : m.data != null
        ? !(m.data.startsWith('{variation:') && m.data.endsWith('}'))
        : true
    );

    messages = messages.filter((m) => !(page?.model != null && !m.isBot));

    messages = messages.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    if (liveMessage) {
      const messageObj = {
        id: 'live',
        data: liveMessage,
        createdAt: new Date(),
        isBot: true,
        threadId,
        isCanceled: false,
        isDeleted: false,
        userId: null,
      };
      const lastMessage = messages[messages.length - 1];

      if (lastMessage.isBot && lastMessage.data == null) {
        messages[messages.length - 1] = messageObj;
      } else {
        messages = [...messages, messageObj];
      }
    }

    const totalHeight =
      height - (outletContext?.[0] ?? 0) - (outletContext?.[1] ?? 0);

    const hasImagePrompt =
      prompts.find((p) => p.id === threadData.prompt)?.type === 'image';
    const topPctOfView = topPct({ threadData, hasFiles, hasImagePrompt });

    if (!hasCreatedNew && (loadingThread || threadData == null)) {
      return <Loading />;
    }

    return (
      <VStack
        justifyContent='space-between'
        bg='bg'
        h={isPublic ? '100vh' : '100%'}
        // bg={isPublic ? 'bg' : undefined}
        style={{ overflow: 'hidden' }}
      >
        <VStack
          h={isWeb ? undefined : totalHeight * topPctOfView}
          flexGrow={1}
          style={{ overflow: 'hidden' }}
        >
          <HStack
            mx={3}
            my={1}
            justifyContent='space-between'
            alignItems='center'
          >
            {isPublic ? (
              <Box />
            ) : (
              <HStack alignItems='center' space={1}>
                <Pressable
                  onPress={() => {
                    if (hasCreatedNew && initialIndex) {
                      navigate(`/chat?initialIndex=${initialIndex}`);

                      return;
                    }
                    navigate(-1);
                  }}
                  hitSlop={25}
                  style={{ zIndex: 999 }}
                >
                  <Icon
                    as={<Ionicons name='chevron-back-outline' />}
                    color='primary'
                    size={7}
                  />
                </Pressable>
                {threadData.pageId != null ? (
                  <CachedImage
                    size={6}
                    // size={size}
                    rounded='full'
                    source={
                      isWeb
                        ? {
                            uri: `/static/images/pages/${threadData.pageId}.png`,
                          }
                        : {
                            uri: `gs://chat-api-metazooie/promptImages/page_${threadData.pageId}_0.png`,
                          }
                    }
                    // rounded='full'
                    // size={6}
                  />
                ) : (
                  <DefaultImage size={6} />
                )}
                <Text fontWeight={600}>
                  {page?.i18n?.[language]?.name || page?.name || 'Laila'}
                </Text>
              </HStack>
            )}
            {isEditingTitle ? (
              <ThreadTitleEditor
                thread={threadData}
                onSave={() => {
                  refetchThread().then(() => setIsEditingTitle(false));
                }}
              />
            ) : (
              <Text opacity={0} maxW={'70%'} numberOfLines={1} fontSize='md'>
                {threadData.title}
              </Text>
            )}

            {isPublic ? (
              <Box />
            ) : (
              <ThreadOptions
                thread={threadData}
                onChange={refetchThread}
                onRename={() => {
                  setTimeout(() => setIsEditingTitle(true), 50);
                }}
                isCreator={isCreator}
              />
            )}
          </HStack>

          <RNScrollView
            ref={scrollRef}
            style={{
              // overflow: 'scroll',
              maxHeight: isWeb
                ? isDesktop()
                  ? height - 60 * 2 - 30 - 60
                  : height - 30 - 60
                : undefined,
              flexGrow: 1,
              overflow: 'scroll',

              // backgroundColor: 'green',
            }}
            onScroll={(e) => {
              let direction =
                e.nativeEvent.contentOffset.y - currentOffset.current >= -30
                  ? 'down'
                  : 'up';

              if (direction === 'up') {
                setUserHasScrolled(true);
              }

              currentOffset.current = e.nativeEvent.contentOffset.y;
            }}
            onScrollBeginDrag={() => {
              setUserHasScrolled(true);
            }}
            // style={{ maxHeight: 100 }}
          >
            <VStack space='2' h='100%' marginBottom={isPublic ? 16 : 0}>
              {messages.map((message, idx) => (
                <VStack
                  key={idx}
                  p={4}
                  space='2'
                  bg={message.isBot ? 'bg' : 'bgSecondary'}
                >
                  <HStack justifyContent='space-between' alignItems='center'>
                    {message.isBot ? (
                      threadData.pageId != null ? (
                        <CachedImage
                          size={6}
                          // size={size}
                          rounded='full'
                          source={
                            isWeb
                              ? {
                                  uri: `/static/images/pages/${threadData.pageId}.png`,
                                }
                              : {
                                  uri: `gs://chat-api-metazooie/promptImages/page_${threadData.pageId}_0.png`,
                                }
                          }
                          // rounded='full'
                          // size={6}
                        />
                      ) : (
                        <Bot />
                      )
                    ) : (
                      <UserImage
                        user={!isPublic ? threadData.creator : undefined}
                      />
                    )}
                    {!('isProcessed' in message) && (
                      <HStack alignItems='center' space={1}>
                        {prompt?.type === 'text-to-image' &&
                          message.isBot &&
                          message.data != null && (
                            <MaterialIcons
                              name='refresh'
                              size={24}
                              color='text'
                              onPress={() => {
                                const msgData = `{variation:${message.id}}`;
                                sendMessage({
                                  threadId,
                                  data: msgData,
                                  files: [],
                                });

                                optimisticThread({
                                  ...threadData,
                                  messages: [
                                    ...threadData.messages,
                                    {
                                      id: 'live',
                                      data: null,
                                      createdAt: new Date(),
                                      isBot: true,
                                      threadId,
                                      isCanceled: false,
                                      isDeleted: false,
                                      userId: null,
                                    },
                                  ],
                                });
                                setTimeout(
                                  () => debouncedScrollToEnd(scrollRef),
                                  300
                                );
                              }}
                            />
                          )}
                        <MaterialIcons
                          name='content-copy'
                          color='text'
                          size={20}
                          onPress={() => onCopy(message?.data || '')}
                        />
                      </HStack>
                    )}
                  </HStack>
                  {'isProcessed' in message ? (
                    <FileCard file={message as unknown as api['db']['File']} />
                  ) : message.data == null || message.data === '' ? (
                    message.id === 'error' ? (
                      <HStack space={1} alignItems='center'>
                        <Text color='red.500' fontWeight={500}>
                          Error
                        </Text>
                        <MaterialIcons
                          name='refresh'
                          size={24}
                          color='text'
                          onPress={async () => {
                            setHasStreamingError(false);
                            setTimeout(
                              () => debouncedScrollToEnd(scrollRef),
                              300
                            );

                            setUserHasScrolled(false);
                            setIsReceivingLiveMessage(true);
                            sendMessageAPI({
                              message: '',
                              retry: true,
                              userToken: await user?.getIdToken(),
                              threadId,
                              onResponse: (liveMessage) => {
                                optimisticThread({
                                  ...threadData,
                                  messages: [
                                    ...threadData.messages,
                                    {
                                      id: 'live',
                                      data: liveMessage,
                                      createdAt: new Date(),
                                      isBot: true,
                                      threadId,
                                      isCanceled: false,
                                      isDeleted: false,
                                      userId: null,
                                    },
                                  ],
                                });
                                if (!hasScrolledRef.current) {
                                  debouncedScrollToEnd(scrollRef);
                                }
                              },
                              onEnd: (hasError) => {
                                reload('tokens');
                                setIsReceivingLiveMessage(false);

                                setHasStreamingError(hasError);

                                if (hasError) return;
                                setTimeout(async () => {
                                  await refetchThread().then((thread) => {
                                    setLiveMessage(undefined);
                                    const newThreads = (
                                      threadsRef.current?.threads || []
                                    ).map((t) =>
                                      t.id === thread?.id ? thread : t
                                    );

                                    if (
                                      newThreads.find(
                                        (t) => t.id === thread?.id
                                      ) == null
                                    ) {
                                      if (thread != null) {
                                        newThreads.push(thread);
                                      }
                                    }

                                    optimisticThreads({
                                      ...(threadsRef.current || {
                                        favorites: [],
                                        pageFavorites: [],
                                        recentPages: [],
                                      }),
                                      threads: newThreads,
                                    });
                                  });

                                  setTimeout(() => {
                                    if (isDesktop()) {
                                      sendMessageRef.current?.focusInput();
                                    }
                                    if (!hasScrolledRef.current) {
                                      debouncedScrollToEnd(scrollRef);
                                    }
                                  }, 200);
                                }, 500);
                              },
                            });
                          }}
                        />
                      </HStack>
                    ) : (
                      <ActivityIndicator
                        style={{ alignSelf: 'flex-start', marginBottom: 4 }}
                      />
                    )
                  ) : (
                    <VStack space='sm'>
                      {isNews ? (
                        Object.keys(JSON.parse(message.data)).map((url) => (
                          <Pressable
                            onPress={() => {
                              Linking.openURL(url);
                            }}
                          >
                            <Text fontSize='md' color='blue.300'>
                              {message.data == null
                                ? ''
                                : JSON.parse(message.data)[url]}
                            </Text>
                          </Pressable>
                        ))
                      ) : (prompt?.type === 'text-to-image' ||
                          (message?.data?.startsWith('{"file": "gs') &&
                            message?.data?.includes('image')) ||
                          message?.data?.startsWith('{"type')) &&
                        message.isBot ? (
                        <StructuredMessage message={message} page={page} />
                      ) : (
                        // @ts-expect-error
                        <Markdown
                          style={{
                            body: {
                              color: 'text',
                              fontFamily: 'Regular_400',
                              fontSize: 16,
                            },
                            table: {
                              marginTop: 8,
                              borderColor: 'text',
                              borderLeftWidth: 0,
                              borderBottomWidth: 0,
                            },
                            blockquote: {
                              backgroundColor: 'gray',
                            },
                            thead: {
                              fontFamily: 'Medium_500',
                              backgroundColor: 'white',
                            },
                            td: {
                              borderColor: 'text',
                              borderLeftWidth: 1,
                              // borderWidth: .5,
                            },

                            tr: {
                              borderColor: 'text',
                            },
                            th: {
                              borderColor: 'text',
                              borderLeftWidth: 1,
                            },
                            code_inline: {
                              backgroundColor: 'gray',
                              fontFamily: 'Regular_400',
                            },
                            code_block: {
                              backgroundColor: 'gray',
                              fontFamily: 'Regular_400',
                            },
                            fence: {
                              backgroundColor: 'gray',
                              fontFamily: 'Regular_400',
                            },
                          }}
                        >
                          {message.data ?? '...'}
                        </Markdown>
                      )}
                    </VStack>
                  )}
                </VStack>
              ))}
            </VStack>
          </RNScrollView>
        </VStack>

        <VStack
          // bg='red.500'
          h={isCreator && !isWeb ? totalHeight * (1 - topPctOfView) : undefined}
          my={1}
          pb={1}
        >
          {threadData.isPublic && !isPublic ? (
            <ThreadInteraction
              thread={threadData}
              onChange={refetchThread}
              onOptimisticUpdate={optimisticThread}
            />
          ) : (
            <Box h={8} />
          )}

          {(categoriesData || []).length > 0 && threadData.prompt != null && (
            <HStack space='md' m={1}>
              <ChatPromptCard
                imageSize={50}
                fontSize={hasImagePrompt ? 11 : undefined}
                prompt={prompt}
                selected
              />
            </HStack>
          )}

          {isCreator && !isPublic && !isNews && (
            <VStack
              style={{
                position: 'fixed',
                width: isDesktop() ? 640 : '100%',
                bottom: isDesktop() ? 48 : 0,
              }}
            >
              <SendMessage
                ref={sendMessageRef}
                numTokens={
                  page?.tokens ??
                  prompt?.tokens ??
                  configData?.defaultNumTokens ??
                  0
                }
                disabled={isSendingMessage || isReceivingLiveMessage}
                onSendMessage={async (message) => {
                  // if (
                  // threadData.files.length === 0 &&
                  // message.files.length === 0
                  // ) {
                  // Always use streaming

                  optimisticThread({
                    ...threadData,
                    messages: [
                      ...threadData.messages,
                      {
                        id: 'latestUser',
                        data: message.message,
                        createdAt: new Date(new Date().getTime() - 1),
                        isBot: false,
                        threadId,
                        isCanceled: false,
                        isDeleted: false,
                        userId: user?.uid,
                      },
                      {
                        id: 'live',
                        data: null,
                        createdAt: new Date(),
                        isBot: true,
                        threadId,
                        isCanceled: false,
                        isDeleted: false,
                        userId: null,
                      },
                    ],
                  });

                  if (prompt?.type === 'text-to-image') {
                    sendMessage({
                      threadId: threadData.id,
                      data: message.message,
                      files: message.files,
                      stream: false,
                    });
                    setTimeout(() => debouncedScrollToEnd(scrollRef), 300);

                    return;
                  }

                  if (true) {
                    setHasStreamingError(false);
                    setTimeout(() => debouncedScrollToEnd(scrollRef), 300);

                    setUserHasScrolled(false);
                    setIsReceivingLiveMessage(true);
                    sendMessageAPI({
                      message: message.message,
                      userToken: await user?.getIdToken(),
                      threadId,
                      onResponse: (liveMessage) => {
                        optimisticThread({
                          ...threadData,
                          messages: [
                            ...threadData.messages,
                            {
                              id: 'latestUser',
                              data: message.message,
                              createdAt: new Date(new Date().getTime() - 1),
                              isBot: false,
                              threadId,
                              isCanceled: false,
                              isDeleted: false,
                              userId: user?.uid,
                            },
                            {
                              id: 'live',
                              data: liveMessage,
                              createdAt: new Date(),
                              isBot: true,
                              threadId,
                              isCanceled: false,
                              isDeleted: false,
                              userId: null,
                            },
                          ],
                        });
                        if (!hasScrolledRef.current) {
                          debouncedScrollToEnd(scrollRef);
                        }
                      },
                      onEnd: (hasError) => {
                        reload('tokens');
                        setIsReceivingLiveMessage(false);
                        setHasStreamingError(hasError);
                        if (hasError) return;
                        setTimeout(async () => {
                          await refetchThread().then((thread) => {
                            setLiveMessage(undefined);
                            const newThreads = (
                              threadsRef.current?.threads || []
                            ).map((t) => (t.id === thread?.id ? thread : t));

                            if (
                              newThreads.find((t) => t.id === thread?.id) ==
                              null
                            ) {
                              if (thread != null) {
                                newThreads.push(thread);
                              }
                            }

                            optimisticThreads({
                              ...(threadsRef.current || {
                                favorites: [],
                                pageFavorites: [],
                                recentPages: [],
                              }),
                              threads: newThreads,
                            });
                          });

                          setTimeout(() => {
                            if (isDesktop()) {
                              sendMessageRef.current?.focusInput();
                            }
                            if (!hasScrolledRef.current) {
                              debouncedScrollToEnd(scrollRef);
                            }
                          }, 200);
                        }, 500);
                      },
                    });

                    return;
                  }

                  sendMessage({
                    threadId,
                    data: message.message,
                    files: message.files,
                    stream: false,
                  });
                  setUserHasScrolled(false);
                  optimisticThread({
                    ...threadData,
                    messages: [
                      ...threadData.messages,
                      {
                        id: 'latestUser',
                        data: message.message,
                        createdAt: new Date(new Date().getTime() - 1),
                        isBot: false,
                        threadId,
                        isCanceled: false,
                        isDeleted: false,
                        userId: user?.uid,
                      },
                    ],
                  });
                  setTimeout(scrollRef?.current?.scrollToEnd, 100);
                }}
                onAddFile={() => {
                  if (!hasFiles) {
                    setHasFiles(true);
                  }
                }}
                onRemoveFiles={() => {
                  if (hasFiles) {
                    setHasFiles(false);
                  }
                }}
              />
            </VStack>
          )}
        </VStack>
      </VStack>
    );
  }, [
    isSendingMessage,
    prompt,
    isEditingTitle,
    threadData,
    hasFiles,
    liveMessage,
    height,
    hasStreamingError,
  ]);

  if (threadData == null) {
    return <Loading />;
  }

  if (threadData.isDeleted) {
    return <Navigate to='/home' />;
  }

  if (isCreator && page?.model) {
    return <Navigate to={`/page/${page.id}?threadId=${threadId}`} />;
  }

  if (historyIsOpen) {
    return (
      <HStack h='full'>
        <Box w='85%'>
          <ThreadHistory
            activeThreadId={threadId}
            onClose={() => setHistoryIsOpen(false)}
          />
        </Box>

        <Pressable onPress={() => setHistoryIsOpen(false)}>
          <Box h='full' opacity={0.4}>
            {Thread}
          </Box>
        </Pressable>
      </HStack>
    );
  }

  return (
    <>
      <KeyboardAvoidingView
        h='100%'
        enabled={!isEditingTitle && !isWeb}
        behavior='position'
        keyboardVerticalOffset={outletContext?.[0] ?? 0}
        ref={publicScrollRef}
        overflow={isPublic ? 'scroll' : undefined}
      >
        {Thread}
      </KeyboardAvoidingView>
      {isPublic && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: Dimensions.get('screen').width / 2 - 80,
          }}
        >
          <MobileStoreButton />
        </View>
      )}
    </>
  );
};

export default Thread;
