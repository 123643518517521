import { createContext, useContext, useState } from 'react';

const reloadData = {
  home: 0,
  prompts: 0,
  profile: 0,
  app: 0,
  tokens: 0,
};


type Data = typeof reloadData;

type Page = keyof Data;

const ReloadContext = createContext<{
  reloadData: Data;
  reload: (page: Page) => void;
}>({ reloadData, reload: () => {} });

export const useReload = () => {
  const { reloadData, reload } = useContext(ReloadContext);
  return { reloadData, reload };
};

export function ReloadContextProvider({ children }) {
  const [reloadDataStore, setReloadDataStore] =
    useState<Data>(reloadData);

  return (
    <ReloadContext.Provider
      value={{
        reloadData: reloadDataStore,
        reload: (page: Page) =>
          setReloadDataStore(dataStore => ({
            ...dataStore,
            [page]: dataStore[page] + 1,
          })),
      }}
    >
      {children}
    </ReloadContext.Provider>
  );
}
