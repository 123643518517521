import { Drawer } from 'react-native-drawer-layout';
import {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import SideNavbar from './SideNavbar';
import { Pressable, TextInput as RNInput, ScrollView } from 'react-native';
import useSearchHook from '../hooks/useSearch';
import { useLocation, useNavigate } from './Router';
import { Divider, HStack, VStack } from '../ui';
import { Icon, Input } from 'native-base';
import { usei18n } from '../utils/i18n';
import { debounce, groupBy, sum } from 'lodash';
import { Ionicons } from '@expo/vector-icons';
import TopNavbar from './TopNavbar';
import Navtabs from './Navtabs';
import PageCard from './PageCard';
import ThreadCard from './ThreadCard';
import { useThreadsQuery } from '../api';
import { assertNever, isDesktop } from '../utils';
import ChatPromptCard from './ChatPromptCard';
import { PromptListItem } from '../pages/Chat';

const SearchContext = createContext<{
  openSearch: () => void;
  closeSearch: () => void;
  isSearchOpen: boolean;
}>({
  openSearch: () => {},
  closeSearch: () => {},
  isSearchOpen: false,
});

export const useSearch = () => useContext(SearchContext);

const Search = () => {
  const { i18n } = usei18n();
  const navigate = useNavigate();

  const location = useLocation();
  const { isSearchOpen, closeSearch } = useSearch();

  const tabs = ['Pages', 'Prompts', 'Published', 'Chats'] as const;
  const [activeTab, setActiveTab] = useState<(typeof tabs)[number]>('Pages');

  //   const { data, optimisticUpdate } = useThreadsQuery({}, { cache: false });

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSetSearchTerm = useMemo(
    () => debounce(setSearchTerm, 300),
    []
  );

  const [inputText, setInputText] = useState('');

  const { publicThreads, threads, pages, messages, categories } = useSearchHook(
    {
      searchTerm,
    }
  );

  useEffect(() => {
    if (isSearchOpen) {
      setActiveTab('Pages');

      inputRef.current?.focus();
    }

    setSearchTerm('');
    setInputText('');
  }, [isSearchOpen]);

  useEffect(() => {
    if ((inputText || '').length < 3) return;

    debouncedSetSearchTerm(inputText);
  }, [inputText]);
  const inputRef = useRef<RNInput>(null);

  const { translations } = usei18n();

  const placeholder = 'Search conversations';

  if (!isSearchOpen) return null;

  const renderTab = () => {
    switch (activeTab) {
      case 'Pages': {
        return (
          <VStack space={2} mx={2}>
            {(pages || []).map((page) => (
              <PageCard page={page} horizontal />
            ))}
          </VStack>
        );
      }
      case 'Prompts': {
        return (
          <VStack space={2}>
            {categories.map((cat, idx) => (
              <PromptListItem
                key={idx}
                label={i18n(cat).label}
                prompts={cat.prompts}
                selectedPromptIds={[]}
                showLabel
                onSelectPrompt={(selected) => {
                  if (selected.length === 0) return;

                  const pageId = location.pathname.includes('/page/')
                    ? location.pathname.split('/page/')[1]
                    : undefined;

                  if (pageId) {
                    navigate(`/page/${pageId}?promptId=${selected[0]}`, {
                      replace: true,
                    });
                    return;
                  }

                  navigate(`/chat?promptId=${selected[0]}`);
                }}
              />
            ))}
          </VStack>
        );
      }
      // return (
      //   <VStack space={2} divider={<Divider />}>
      //     {(threadsData?.threads || []).slice(0, 20).map((thread) => (
      //       <ThreadCard
      //         thread={thread}
      //         prompt={null}
      //         onOptimisticUpdate={(thread) =>
      //           optimisticThreads({
      //             ...threadsData,
      //             threads: (threadsData?.threads || []).map((t) =>
      //               t.id === thread.id ? thread : t
      //             ),
      //           })
      //         }
      //       />
      //     ))}
      //   </VStack>
      // );
      case 'Published': {
        return (
          <VStack space={2} mx={2} divider={<Divider />}>
            {publicThreads
              .filter((t) => t.isPublic)
              .slice(0, 20)
              .map((thread) => (
                <Pressable
                  onPress={() => {
                    navigate(`/thread/${thread.id}`);
                  }}
                >
                  <ThreadCard
                    canInteract={false}
                    thread={thread}
                    prompt={null}
                    //   onOptimisticUpdate={(thread) =>
                    //     optimisticThreads({
                    //       ...threadsData,
                    //       threads: (threadsData?.threads || []).map((t) =>
                    //         t.id === thread.id ? thread : t
                    //       ),
                    //     })
                    //   }
                  />
                </Pressable>
              ))}
          </VStack>
        );
      }
      case 'Chats': {
        return (
          <VStack space={2} divider={<Divider />} mx={2}>
            {threads.map((thread) => (
              <Pressable
                onPress={() => {
                  navigate(`/thread/${thread.id}`);
                }}
              >
                <ThreadCard thread={thread} prompt={null} canInteract={false} />
              </Pressable>
            ))}
          </VStack>
        );
      }
      default:
        assertNever(activeTab);
    }
  };
  const tabCounts = {
    Pages: pages?.length,
    Published: publicThreads?.length,
    Chats: threads?.length,
    Prompts: sum(categories.flatMap((c) => c.prompts.length)),
  };

  return (
    <VStack
      position='absolute'
      h='100%'
      w='100%'
      style={{
        alignSelf: isDesktop() ? 'center' : undefined,
        // height: WINDOW_HEIGHT - heights[0] - heights[1],
        width: isDesktop() ? 700 : undefined,
        paddingVertical: isDesktop() ? 60 : undefined,
        // marginHorizontal:
        //   isWeb && Dimensions.get('window').width > 720 ? 200 : undefined,
      }}
    >
      <TopNavbar hideAdd hideSearch leftIcon='close' onClose={closeSearch} />

      <VStack
        py={2}
        opacity={0.9}
        bg='bg'
        space={2}
        flexGrow={1}
        flexShrink={1}
      >
        <HStack px={4}>
          <Input
            ref={inputRef}
            placeholder={translations['Search'] || placeholder}
            //   variant='filled'
            variant='unstyled'
            width='100%'
            fontSize={13}
            h={8}
            borderColor='text'
            // onFocus={onFocus}
            // onBlur={onBlur}
            borderWidth={1}
            borderRadius='10'
            placeholderTextColor='text'
            color='text'
            rounded='full'
            py='1'
            px='2'
            value={inputText}
            onChangeText={setInputText}
            InputRightElement={
              searchTerm.length > 0 ? (
                <Icon
                  ml='2'
                  onPress={() => {
                    setInputText('');
                    setSearchTerm('');

                    //   debouncedSetSearchTerm('');
                    inputRef.current?.blur();
                    // onBlur?.();
                    // if (expandable) {
                    //   setShouldShowInput(false);
                    // }
                  }}
                  size='5'
                  mr={2}
                  color='text'
                  as={<Ionicons name='close-circle' />}
                />
              ) : (
                <Icon
                  ml='2'
                  size='4'
                  mr={2}
                  color='text'
                  as={<Ionicons name='ios-search' />}
                />
              )
            }
          />
        </HStack>
        <Navtabs
          tabs={tabs.filter((tab) => tabCounts[tab] > 0)}
          onChange={(tab) => setActiveTab(tab)}
          counts={tabCounts}
          activeTab={activeTab}
        />
        <ScrollView>{renderTab()}</ScrollView>
      </VStack>
    </VStack>
  );
};

export function SearchProvider({ children }) {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsSearchOpen(false);
  }, [location]);

  return (
    <SearchContext.Provider
      value={{
        isSearchOpen,
        openSearch: () => setIsSearchOpen(true),
        closeSearch: () => setIsSearchOpen(false),
      }}
    >
      <>
        {children}
        <Search />
      </>
    </SearchContext.Provider>
  );
}
