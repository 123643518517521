import { FontAwesome5, FontAwesome } from '@expo/vector-icons';
import { Icon } from 'native-base';
import { Path, Svg } from 'react-native-svg';
import { assertNever, sizing } from '../utils';
import { Pressable } from 'react-native';

// https://fontawesome.com/icons/x-twitter?f=brands&s=solid
const TwitterIcon = ({ size }: { size: number }) => (
  <Svg height={`${sizing[size]}px`} viewBox='0 0 512 512'>
    <Path d='M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z' />
  </Svg>
);

const SocialMediaButton = ({
  onPress,
  type,
  size,
}: {
  onPress: () => void;
  type: 'facebook' | 'twitter' | 'tiktok' | 'web' | 'instagram' | 'youtube';
  size?: number;
}) => {
  let icon = <></>;
  switch (type) {
    case 'facebook':
      icon = <FontAwesome5 name='facebook' />;
      break;
    case 'tiktok':
      icon = <FontAwesome5 name='tiktok' />;
      break;
    case 'instagram':
      icon = <FontAwesome5 name='instagram' />;
      break;
    case 'web':
      icon = <FontAwesome5 name='globe' />;
      break;
    case 'twitter':
      return (
        <Pressable onPress={onPress}>
          <TwitterIcon size={size} />
        </Pressable>
      );
      break;
    case 'youtube':
      icon = <FontAwesome name='youtube-play' />;
      break;
    default:
      assertNever(type);
  }

  return <Icon as={icon} onPress={onPress} size={size} />;
};

export default SocialMediaButton;
