import { Drawer } from 'react-native-drawer-layout';
import { createContext, useState, useContext, useEffect } from 'react';
import SideNavbar from './SideNavbar';
import { useLocation } from './Router';

const DrawerContext = createContext<{
  isOpen: boolean;
  toggleDrawer: () => void;
  openDrawer: () => void;
  closeDrawer: () => void;
}>({
  isOpen: false,
  toggleDrawer: () => {},
  openDrawer: () => {},
  closeDrawer: () => {},
});

export const useDrawer = () => {
  const { isOpen, toggleDrawer, openDrawer, closeDrawer } =
    useContext(DrawerContext);
  return { isOpen, toggleDrawer, openDrawer, closeDrawer };
};

export function DrawerProvider({ children }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <DrawerContext.Provider
      value={{
        isOpen: isDrawerOpen,
        openDrawer: () => setIsDrawerOpen(true),
        closeDrawer: () => setIsDrawerOpen(false),
        toggleDrawer: () => setIsDrawerOpen(!isDrawerOpen),
      }}
    >
      <Drawer
        open={isDrawerOpen}
        useLegacyImplementation={false}
        drawerStyle={{
          zIndex: isDrawerOpen ? undefined : -1,
          opacity: isDrawerOpen ? 1 : 0,
          width: '70%',
        }}
        onOpen={() => setIsDrawerOpen(true)}
        onClose={() => setIsDrawerOpen(false)}
        renderDrawerContent={() => <SideNavbar />}
      >
        {children}
      </Drawer>
    </DrawerContext.Provider>
  );
}
