import { useEffect, useState } from 'react';
import { Box, HStack, Text } from '../ui';
import { Pressable, ScrollView } from 'react-native';
import { colors } from '../utils/theme';

type Props<T extends string> = {
  tabs: readonly T[];
  onChange: (newTab: T) => void;
  counts?: Record<T, number>;
  activeTab: T;
};

const Navtabs = <T extends string>({
  tabs,
  activeTab,
  onChange,
  counts,
}: Props<T>) => {
  useEffect(() => {
    if (tabs.length === 0) return;
    if (!tabs.includes(activeTab)) {
      onChange(tabs[0]);
    }
  }, [tabs]);

  if (tabs.length === 0) return null;

  return (
    <>
      <HStack zIndex={99} w='100%'>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {tabs.map((tab) => (
            <Pressable
              key={tab}
              onPress={() => {
                onChange(tab);
              }}
            >
              <>
                <Text
                  px={2}
                  color='secondary'
                  fontWeight={activeTab === tab ? 500 : 400}
                >
                  <Text>{tab}</Text>
                  <Text>{counts?.[tab] ? ` (${counts[tab]})` : ''}</Text>
                </Text>
                <Box
                  mx={1}
                  style={
                    activeTab === tab
                      ? {
                          borderColor: colors.primary,
                          borderWidth: 3,
                          borderRadius: 2,
                        }
                      : {}
                  }
                />
              </>
            </Pressable>
          ))}
        </ScrollView>
      </HStack>
      <Box
        // w='100%'
        // ml={"100px"}
        style={{ borderBottomColor: colors.secondary, borderBottomWidth: 1 }}
        position='relative'
        bottom={1}
      />
    </>
  );
};

export default Navtabs;
