import 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';
import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
// import { StatusBar } from 'expo-status-bar';
import { useColorScheme, StatusBar as RNStatusBar } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';

import {
  NativeBaseProvider,
  Stack,
  Text,
  HStack,
  extendTheme,
  Badge,
  Spinner,
  VStack,
  View,
  KeyboardAvoidingView,
} from 'native-base';
import {
  Router,
  Route,
  Routes,
  useNavigate,
  Outlet,
  Navigate,
} from './components/Router';
import {
  useFonts,
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
  Poppins_400Regular_Italic,
} from '@expo-google-fonts/poppins';

// import loadable from '@loadable/component';
// const Profile = loadable(() => import('./pages/Profile'));
// const Chat = loadable(() => import('./pages/Chat'));
// const Thread = loadable(() => import('./pages/Thread'));
// const Explore = loadable(() => import('./pages/Explore'));
// const Comments = loadable(() => import('./pages/Comments'));
// const Home = loadable(() => import('./pages/Home'));
// const Landing = loadable(() => import('./pages/Landing'));
// const Pricing = loadable(() => import('./pages/Pricing'))
// const Tutorial = loadable(() => import('./pages/Tutorial'));
// const Auth = loadable(() => import('./pages/Auth'));
// const Chats = loadable(() => import('./pages/Chats'));
// const PageGroup = loadable(() => import('./pages/PageGroup'));
// const AdminConfig = loadable(() => import('./pages/AdminConfig'));
// const Plan = loadable(() => import('./pages/Plan'));

import Chat from './pages/Chat';
import Thread from './pages/Thread';
import Explore from './pages/Explore';
import Comments from './pages/Comments';
import Home from './pages/Home';
import Landing from './pages/Landing';
import Pricing from './pages/Pricing';
import Tutorial from './pages/Tutorial';
import Auth from './pages/Auth';
import Chats from './pages/Chats';
import PageGroup from './pages/PageGroup';
import AdminConfig from './pages/AdminConfig';
import Plan from './pages/Plan';
import Profile from './pages/Profile';

import HomeMobileOld from './pages/Home_mobile_old';

import { useConfigQuery, useUserQuery } from './api';
import { useLocation } from './components/Router.web';
import {
  isDesktop,
  isWeb,
  useNotificationListener,
  windowHeight,
} from './utils';

import NavFooter from './components/NavFooter';
import Loading, { LoadingProvider } from './components/Loading';

import { useAnonUser, useUser } from './hooks';
import { Dimensions, Platform } from 'react-native';
import { ReloadContextProvider, useReload } from './reloader';
import { ConfigProvider, useConfig, usei18n } from './utils/i18n';

import theme from './utils/theme';

import { api } from './apiTypes';
import { signOut } from './firebase';

import SideNavbar from './components/SideNavbar';

import { DrawerProvider } from './components/Drawer';
import { SearchProvider } from './components/Search';
import { ModalProvider } from './components/Modal';
// import Payment from './pages/Payment';
import PaymentSuccess from './pages/PaymentSuccess';

const App = () => {
  useNotificationListener();
  const [user, loadingUser] = useUser();

  const { loading: i18nLoading, language } = usei18n();

  const { data: userData, loading: loadingUserData } = useUserQuery(
    {},
    { skip: user == null }
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [heights, setHeights] = useState([0, 0]);

  useAnonUser();

  // useEffect(() => {
  //   const isPublic = location.pathname.startsWith('/share');
  //   const isPage =
  //     location.pathname.startsWith('/page') ||
  //     location.pathname.startsWith('/p');
  //   const isThread = location.pathname.startsWith('/thread');
  //   const isSignout = location.pathname.startsWith('/signOut');
  //   const isAuth = location.pathname.startsWith('/auth');

  //   const isHome = location.pathname.startsWith('/home');

  //   if (
  //     (!loadingUser && user == null) ||
  //     (user != null &&
  //       !loadingUserData &&
  //       userData == null &&
  //       !isPublic &&
  //       !((isPage || isThread || isHome) && isWeb) &&
  //       !isAuth &&
  //       !isSignout)
  //   ) {
  //     navigate('/landing');
  //   }
  // }, [user, loadingUserData]);

  const onLayoutRootView = useCallback(async () => {
    // This tells the splash screen to hide immediately! If we call this after
    // `setAppIsReady`, then we may see a blank screen while the app is
    // loading its initial state and rendering its first pixels. So instead,
    // we hide the splash screen once we know the root view has already
    // performed layout.
    await SplashScreen.hideAsync();
  }, []);

  const { data: configData } = useConfigQuery(
    {},
    { skip: !isDesktop() ? true : !location.pathname.includes('/page/') }
  );

  // useEffect(() => {
  //   // refetchPredictions();
  //   refetchUser();
  // }, [reloadData['app']]);
  // useEffect(() => {
  //   // if (predictionsData == null) return;

  //   // console.log({
  //   //   partial: predictionsData
  //   //     .filter((p) => !p.processed)
  //   //     .map((p) => p.partialProcessed),
  //   // });

  //   const numPendingPredictions = (predictionsData ?? [])
  //     .filter((p) => !p.processed)
  //     .reduce(
  //       (acc, p) => acc + parseInt(p.numOutputs) - p.partialProcessed.length,
  //       0
  //     );

  //   if (numPendingPredictions > 0) {
  //     setTimeout(() => {
  //       refetchPredictions().then(() => reload('home'));
  //     }, 1000 * 5);
  //   }
  // }, [predictionsData]);

  // useEffect(() => {
  //   if (modelTrainingData == null) return;

  //   if (modelTrainingData.status === 'succeeded') return;

  //   setTimeout(refetchModel, 1000 * 30);
  // }, [modelTrainingData]);

  if (loadingUser || userData == null || i18nLoading) {
    return <Loading />;
  }

  if (location.pathname === '/') {
    if (user?.isAnonymous) {
      return <Navigate to='/home' />;
    } else {
      return <Navigate to='/chats' />;
    }
  }

  // if (!loadingUser && user == null) {
  //   return <Navigate to='/landing' />;
  // }

  const shouldHaveSafeAreaTop = !['/landing', '/pricing', '/tutorial'].includes(
    location.pathname
  );

  const shouldShowFooter = false;
  // const shouldShowFooter = !(
  //   ['/', '/landing', '/pricing', '/tutorial', '/chat'].includes(
  //     location.pathname
  //   ) ||
  //   location.pathname.includes('/share') ||
  //   location.pathname.includes('/page/') ||
  //   location.pathname.includes('/thread/') ||
  //   isDesktop()
  // );

  // const numPendingPredictions = (predictionsData ?? [])
  //   .filter((p) => !p.processed)
  //   .reduce(
  //     (acc, p) => acc + parseInt(p.numOutputs) - p.partialProcessed.length,
  //     0
  //   );

  // const isTrainingModel =
  //   modelTrainingData?.status != null &&
  //   modelTrainingData?.status !== 'succeeded';

  // console.log({ numPendingPredictions, userData });
  // console.log({ heights });

  // const dim = Platform.OS === 'android' ? 'window' : 'window';

  // const windowHeight = Dimensions.get(dim).height;

  const WINDOW_HEIGHT = windowHeight();

  const pageId = location.pathname.includes('/page/')
    ? location.pathname.split('/page/')[1]
    : undefined;
  const isImg2Img =
    configData?.pages?.find((p) => p.id === pageId)?.model?.type ===
    'image2image';

  const shouldShowSidebar = isDesktop() && !isImg2Img;

  const reducedMargins = isDesktop() && !location.pathname.includes('/home');

  return (
    <Stack
      justifyContent='space-between'
      onLayout={onLayoutRootView}
      // overflowY={isWeb ? 'hidden' : undefined}
    >
      <VStack w='full' h='full' flexGrow={1} bg='bg'>
        {/* <ScrollView h='full'> */}

        <HStack
          safeAreaTop={shouldHaveSafeAreaTop ? true : undefined}
          bg='bg'
          w='full'
          px={4}
          // py={2}
          alignItems='center'
          justifyContent='space-between'
          onLayout={(event) => {
            var { x, y, width, height } = event.nativeEvent.layout;
            setHeights(([_, h2]) => [height, h2]);
          }}
        >
          {/* {isTrainingModel || numPendingPredictions > 0 ? (
            <Badge rounded='full' bg='gray.300'>
              <HStack space='xs'>
                <Spinner />
                <Text color='black'>
                  {isTrainingModel
                    ? `Training model`
                    : `${numPendingPredictions} ${
                        numPendingPredictions === 1 ? 'image' : 'images'
                      } generating`}
                </Text>
              </HStack>
            </Badge>
          ) : (
            <Logo />
          )}
        
          {!loading && user != null && (
            <Tokens numTokens={userData?.tokens ?? 0} />
          )}
        */}
        </HStack>

        <View
          style={{
            alignSelf: reducedMargins ? 'center' : undefined,
            height: WINDOW_HEIGHT - heights[0] - heights[1],
            width: reducedMargins ? 700 : undefined,
            paddingLeft: reducedMargins ? 60 : undefined,
            paddingVertical: reducedMargins ? 60 : undefined,
            // marginHorizontal:
            //   isWeb && Dimensions.get('window').width > 720 ? 200 : undefined,
          }}
        >
          <Outlet context={[heights[0], heights[1]]} />
        </View>
        {/* </ScrollView> */}

        {shouldShowFooter && (
          <Stack
            // bg='rgb(18,18,35)'
            safeAreaBottom={Platform.OS === 'android' ? 2 : 6}
            onLayout={(event) => {
              var { x, y, width, height } = event.nativeEvent.layout;
              setHeights(([h1, _]) => [h1, height]);
            }}
          >
            <NavFooter />
          </Stack>
        )}
      </VStack>
      {shouldShowSidebar && <SideNavbar />}
    </Stack>
  );
};

// old colors
// colors: {
//   secondary: 'rgb(56,54,106)',
//   bg: 'rgb(16,15,53)',
//   bgOld: 'rgb(18,18,35)',
//   dark: 'rgb(10,11,37)',
//   border: 'rgb(55,54,93)',
//   textSecondary: 'rgb(133,137,181)',
// },

// const AppContainer = ({ colors }: { colors: api['config']['colors'] }) => {
const AppContainer = () => {
  const [fontsLoaded] = useFonts({
    Light_300: Poppins_300Light,
    Regular: Poppins_400Regular,
    Regular_400: Poppins_400Regular,
    Medium_500: Poppins_500Medium,
    SemiBold_600: Poppins_600SemiBold,
    Bold_700: Poppins_700Bold,
    Regular_Italic_400: Poppins_400Regular_Italic,
    // Inter: require('@tamagui/font-inter/otf/Inter-Medium.otf'),
    // InterBold: require('@tamagui/font-inter/otf/Inter-Bold.otf'),
  });

  const colorScheme = useColorScheme();

  const extendedTheme = extendTheme({ ...theme });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <>
      <StatusBar style='dark' hidden={isWeb} />
      <Router>
        <SafeAreaProvider>
          <ReloadContextProvider>
            <ConfigProvider>
              <NativeBaseProvider theme={extendedTheme}>
                {/* <TamaguiProvider config={config}> */}
                {/* <Theme name={colorScheme === 'dark' ? 'dark' : 'light'}> */}
                <LoadingProvider>
                  <ModalProvider>
                    <SearchProvider>
                      <DrawerProvider>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Routes>
                            <Route path='/' element={<App />}>
                              <Route path='/home' element={<Home />} />
                              <Route path='/profile' element={<Profile />} />
                              <Route path='/admin' element={<AdminConfig />} />
                              {/* <Route path='/train' element={<Train />} /> */}
                              {/* <Route path='/prompts' element={<Prompts />} /> */}
                              {/* <Route path='/predictions' element={<Predictions />} /> */}
                              <Route path='/landing' element={<Landing />} />
                              <Route
                                path='/chat'
                                element={<Chat isWeb={isWeb} />}
                              />
                              <Route path='/chats' element={<Chats />} />
                              <Route
                                path='/page/:id'
                                element={<Chat isPage isWeb={isWeb} />}
                              />
                              <Route
                                path='/pageGroup/:id'
                                element={<PageGroup />}
                              />
                              <Route
                                path='/p/:id'
                                element={<Chat isPage isWeb={isWeb} />}
                              />
                              <Route path='/pricing' element={<Pricing />} />
                              <Route path='/plan' element={<Plan />} />
                              <Route
                                path='/paymentSuccess'
                                element={<PaymentSuccess />}
                              />

                              <Route path='/tutorial' element={<Tutorial />} />
                              <Route path='/explore' element={<Explore />} />
                              <Route path='/thread/:id' element={<Thread />} />
                              <Route
                                path='/share/:id'
                                element={<Thread isPublic />}
                              />
                              <Route
                                path='/web/:id'
                                element={<Chat isWeb isPage />}
                              />
                              <Route path='/auth/action' element={<Auth />} />
                              <Route
                                path='/signOut'
                                element={React.createElement(() => {
                                  signOut();
                                  return <View />;
                                })}
                              />

                              <Route
                                path='/thread/:id/comments'
                                element={<Comments />}
                              />
                            </Route>
                          </Routes>
                        </Suspense>
                      </DrawerProvider>
                    </SearchProvider>
                  </ModalProvider>
                </LoadingProvider>
                {/* </Theme> */}
                {/* </TamaguiProvider> */}
              </NativeBaseProvider>
            </ConfigProvider>
          </ReloadContextProvider>
        </SafeAreaProvider>
      </Router>
    </>
  );
};

// const ColorContainer = () => {
//   const { config: colors } = useConfig();
//   return (
//     <ConfigProvider>
//       <AppContainer colors={theme.colors} />
//     </ConfigProvider>
//   );
// };
export default AppContainer;
