import {
  CloseIcon,
  HStack,
  Pressable,
  Spinner,
  Text,
  VStack,
} from 'native-base';
import { api } from '../apiTypes';

const FileCard = ({
  file,
  onRemove,
}: {
  file: Pick<api['db']['File'], 'type' | 'value' | 'title' | 'description'> & {
    isProcessed?: boolean;
  };
  onRemove?: () => void;
}) => (
  <VStack alignSelf='flex-start'>
    <HStack justifyContent='space-between' space='sm'>
      {onRemove && (
        <Pressable alignSelf='center' onPress={onRemove}>
          <CloseIcon />
        </Pressable>
      )}
      {file?.isProcessed === false && <Spinner />}
      <VStack>
        <Text numberOfLines={2}>{file.title}</Text>
        <Text color='textSecondary' fontSize='xs'>
          {file.type === 'url' ? `${file.type}:  ${file.value}` : file.type}
        </Text>
      </VStack>
    </HStack>
  </VStack>
);

export default FileCard;
