import React, { Component, ComponentProps, useRef } from 'react';
import {
  View,
  Platform,
  Image as RNImage,
  ImageBackground,
} from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as Crypto from 'expo-crypto';
import { API_BASE_URL } from '../api';
import { Image as NBImage } from 'native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { log } from '../utils';
import { auth } from '../firebase';

type Props = ComponentProps<typeof NBImage> & {
  source: {
    uri: string;
  };
  isBackground?: boolean;
  children?: React.ReactNode;
  onLoad?: () => void;
  onExtendedLoad?: (width: number, height: number) => void;
  fallbackComponent?: React.ReactNode;
  // Extend height to given width
  extend?: boolean;
};

const getSignedUrl = async ({
  uri,
  idToken,
}: {
  uri: string;
  idToken: string;
}): Promise<string> => {
  let cacheExpired = true;

  const cacheExpiration = await AsyncStorage.getItem(`${uri}/cacheExpiration`);

  if (cacheExpiration != null && new Date(cacheExpiration) > new Date()) {
    cacheExpired = false;
  }

  if (!cacheExpired) {
    const imageUrl = await AsyncStorage.getItem(uri);

    if (imageUrl != null) {
      return imageUrl;
    } else {
      throw new Error('No image');
    }
  }

  const response = await fetch(API_BASE_URL + 'chat/signUrl', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    },
    body: JSON.stringify({
      uri,
      fileType: 'image',
    }),
  });

  const now = new Date();
  const cacheTimeMs = 1000 * 60 * 59;
  const cacheExpiryTime = new Date(now.getTime() + cacheTimeMs);
  await AsyncStorage.setItem(
    `${uri}/cacheExpiration`,
    cacheExpiryTime.toISOString()
  );

  const { url } = await response.json();
  await AsyncStorage.setItem(uri, url);

  return url;
};

const getImageFilesystemKey = async (remoteURI) => {
  const hashed = await Crypto.digestStringAsync(
    Crypto.CryptoDigestAlgorithm.SHA256,
    remoteURI
  );
  return `${FileSystem.cacheDirectory}${hashed}`;
};

const downloadImage = async (remoteURI, filesystemURI) => {
  let remote = remoteURI;

  const idToken = await auth.currentUser.getIdToken(true);

  if (remoteURI.startsWith('gs://')) {
    const url = await getSignedUrl({
      uri: remoteURI,
      idToken,
    });

    remote = url;
  }
  const imageObject = await FileSystem.downloadAsync(remote, filesystemURI);

  return imageObject;
};

export const cacheImage = async (remoteURI: string) => {
  if (Platform.OS === 'web') {
    if (!remoteURI.startsWith('gs://')) {
      return remoteURI;
    }

    const idToken = await auth.currentUser.getIdToken(true);

    const uri = remoteURI;

    const expiration = await AsyncStorage.getItem(`${uri}/cacheExpiration`);

    if (expiration != null && new Date(expiration) > new Date()) {
      const imgURI = await AsyncStorage.getItem(uri);

      return imgURI;
    }

    const url = await getSignedUrl({
      uri,
      idToken,
    });

    return url;
  }

  const filesystemURI = await getImageFilesystemKey(remoteURI);

  const metadata = await FileSystem.getInfoAsync(filesystemURI);

  if (metadata.exists && metadata.size > 10000) {
    return;
  }

  await downloadImage(remoteURI, filesystemURI);

  return filesystemURI;
};

export default class CachedImage extends Component<Props> {
  ref = React.createRef<RNImage>();

  state = {
    fallback: false,
    imgURI: '',
    retries: 0,
    adjustedWidth: undefined,
    adjustedHeight: undefined,
  };

  async componentDidMount() {
    if (Platform.OS === 'web') {
      if (!this.props.source.uri.startsWith('gs://')) {
        this.setState({
          imgURI: this.props.source.uri,
        });
        return;
      }
      const idToken = await auth.currentUser.getIdToken(true);

      const uri = this.props.source.uri;

      const expiration = await AsyncStorage.getItem(`${uri}/cacheExpiration`);

      if (expiration != null && new Date(expiration) > new Date()) {
        const imgURI = await AsyncStorage.getItem(uri);

        this.setState({
          imgURI,
        });

        return;
      }

      const url = await getSignedUrl({
        uri,
        idToken,
      });

      this.setState({
        imgURI: url,
      });
    }

    const filesystemURI = await getImageFilesystemKey(this.props.source.uri);

    await this.loadImage(filesystemURI, this.props.source.uri);
  }

  async componentDidUpdate(prevProps) {
    const filesystemURI = await getImageFilesystemKey(this.props.source.uri);

    if (
      prevProps.source.uri === this.props.source.uri ||
      this.props.source.uri === this.state.imgURI ||
      filesystemURI === this.state.imgURI
    ) {
      return null;
    }

    if (Platform.OS === 'web') {
      if (!this.props.source.uri.startsWith('gs://')) {
        this.setState({
          imgURI: this.props.source.uri,
        });
        return;
      }

      const idToken = await auth.currentUser.getIdToken(true);

      const url = await getSignedUrl({
        uri: this.props.source.uri,
        idToken,
      });

      this.setState({
        imgURI: url,
      });
      this.forceUpdate();
      return;
    }

    await this.loadImage(filesystemURI, this.props.source.uri);
  }

  async loadImage(filesystemURI, remoteURI) {
    try {
      if (Platform.OS === 'web') {
        return filesystemURI;
      }

      // Use the cached image if it exists
      const metadata = await FileSystem.getInfoAsync(filesystemURI);

      if (metadata.exists && metadata.size > 10000) {
        this.setState({
          imgURI: metadata.uri,
        });
        return;
      }

      // otherwise download to cache
      const imageObject = await downloadImage(remoteURI, filesystemURI);

      const metadataNew = await FileSystem.getInfoAsync(filesystemURI);

      if (metadataNew.size < 10000) {
        setTimeout(async () => {
          const imageObject = await downloadImage(remoteURI, filesystemURI);

          this.setState({
            imgURI: imageObject.uri,
          });
        }, 5000);

        return;
      }

      this.setState({
        imgURI: imageObject.uri,
      });
    } catch (err) {
      log('Image loading error:', err);
      this.setState({ imgURI: remoteURI });
    }
  }

  onError = () => {
    this.setState({
      fallback: true,
    });
    return;
    if ((this.state?.retries || 0) > 3) {
      this.setState({
        fallback: true,
      });
      return;
    }

    setTimeout(() => {
      cacheImage(this.props.source.uri).then((imgURI) => {
        console.log({ imgURI });
        this.setState({
          ...this.state,
          imgURI,
          retries: this.state?.retries || 0 + 1,
        });
      });
    }, 250);
  };

  getMeta = (url, cb) => {
    if (!url) return;
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  render() {
    const width =
      this.props.width || this.props.w || (this.props?.style || {}).width;
    if (
      this.state.imgURI != null &&
      this.props.extend &&
      this.state.adjustedHeight == null &&
      typeof width === 'number'
    ) {

      this.getMeta(this.state.imgURI, (err, img) => {
        const { naturalWidth, naturalHeight } = img;
        if (naturalWidth == null || naturalHeight == null) return;

        const ratio = naturalHeight / naturalWidth;
        const adjustedHeight = width * ratio;

        this.props.onExtendedLoad?.(width, adjustedHeight);

        this.setState({
          ...this.state,
          adjustedWidth: width,
          adjustedHeight,
        });
      });
    }

    let resolvedProps = this.props;
    if (this.state.fallback && this.props.fallbackComponent) {
      return this.props.fallbackComponent;
    }

    let resolvedStyle = this.props.style;

    if (this.state.adjustedWidth != null) {
      resolvedProps = {
        ...resolvedProps,
        width: this.state.adjustedWidth,
        height: this.state.adjustedHeight,
      };

      if (resolvedStyle != null && typeof resolvedStyle === 'object') {
        resolvedStyle = {
          ...resolvedStyle,
          width: this.state.adjustedWidth,
          height: this.state.adjustedHeight,
        };

        resolvedProps.style = resolvedStyle;
      }
    }

    return (
      <>
        {this.props.isBackground ? (
          // @ts-ignore
          <ImageBackground
            alt='image'
            onError={this.onError}
            {...resolvedProps}
            source={this.state.imgURI ? { uri: this.state.imgURI } : null}
          >
            {this.props.children}
          </ImageBackground>
        ) : (
          <NBImage
            alt='image'
            ref={this.ref}
            onError={this.onError}
            {...resolvedProps}
            source={this.state.imgURI ? { uri: this.state.imgURI } : null}
          />
        )}
      </>
    );
  }
}
