import CompareSlider from '../components/CompareSlider';
import { api } from '../apiTypes';
import { usei18n } from '../utils/i18n';
import { useState } from 'react';
import { HStack, Text, VStack } from '../ui';
import CachedImage from './CachedImage';
import { MaterialIcons } from '@expo/vector-icons';
import { Icon, Menu } from 'native-base';
import { Pressable } from 'react-native';
import { useSignUrlMutation } from '../api';
import { isDesktop, randomId } from '../utils';
import { useLoading } from './Loading';
import { colors } from '../utils/theme';

type Action = 'reuse-params' | 'reuse-original' | 'reuse-upscaled';

export const Img2ImgMessage = ({
  width = 300,
  // height = 300,
  inputs,
  data: { inputs: inputValues, output },
  onAction,
}: {
  width?: number;
  // height?: number;
  inputs: api['input'][];
  data: {
    type: 'image';
    inputs: Record<string, any>;
    output: string;
  };
  onAction?: (action: Action) => void;
}) => {
  const { i18n } = usei18n();

  const [position, setPosition] = useState(50);

  const [adjustedHeight, setAdjustedHeight] = useState<number>();

  const height = adjustedHeight || 0;

  const [signUrl] = useSignUrlMutation();
  const [loading, setLoading] = useState(false);

  useLoading(loading);

  const imageInput = inputs.find((i) => i.type === 'image');

  const inputImageUri: string =
    imageInput == null ? undefined : inputValues[imageInput.id];

  const Img2ImgOptions: { label: string; onPress: () => void }[] = [
    {
      label: 'Reuse parameters',
      onPress: () => onAction?.('reuse-params'),
    },
    {
      label: 'Reuse original image',
      onPress: () => onAction?.('reuse-original'),
    },
    {
      label: 'Reuse upscaled image',
      onPress: () => onAction?.('reuse-upscaled'),
    },
  ];

  return (
    <VStack w={width} h={height} opacity={height === 0 ? 0 : 1}>
      <HStack
        position='absolute'
        zIndex={999}
        alignSelf='flex-start'
        alignItems='center'
        top={0.5}
        left={1}
      >
        {onAction != null && (
          <Menu
            w='190'
            // placement='top'
            bg='bgSecondary'
            // offset={16}
            // crossOffset={80}
            _backdrop={{}}
            _presenceTransition={{}}
            _overlay={{}}
            trigger={(triggerProps) => (
              <Pressable {...triggerProps}>
                <Icon
                  color='primary'
                  size={5}
                  as={<MaterialIcons name='refresh' />}
                />
              </Pressable>
            )}
          >
            {Img2ImgOptions.map(({ label, onPress }, idx) => (
              <Menu.Item key={idx} onPress={onPress} px={0}>
                <HStack
                  justifyContent='space-between'
                  w='100%'
                  alignItems='center'
                  justifyItems='end'
                >
                  <Text>{label}</Text>
                </HStack>
              </Menu.Item>
            ))}
          </Menu>
        )}
        <Pressable
          onPress={async () => {
            setLoading(true);
            const { url } = await signUrl({
              fileType: 'image',
              uri: output,
            });

            const response = await fetch(url);

            const blob = await response.blob();

            const blobUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = blobUrl;
            // the filename you wan
            a.download = `laila_${randomId()}.png`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(blobUrl);
            setLoading(false);
          }}
        >
          <Icon
            color='primary'
            size={5}
            as={<MaterialIcons name='file-download' />}
          />
        </Pressable>
      </HStack>
      <HStack
        zIndex={999}
        //   maxW="40%"
        position='absolute'
        space={0.5}
        alignSelf='flex-end'
        top={1}
        right={1}
      >
        {inputs
          .filter((i) => i.type === 'number')
          .map((input) => (
            <VStack bg='secondary' opacity={0.8} p={0.5} rounded='sm'>
              <Text color='bgSecondary' fontSize={8}>
                {i18n(input).label}: {inputValues[input.id]}
              </Text>
            </VStack>
          ))}
      </HStack>
      <CompareSlider
        onPositionChange={setPosition}
        // style={{ width: 300, height: 300 }}
        itemOne={
          <VStack alignItems='center'>
            <CachedImage
              extend
              isBackground
              h={height}
              w={width}
              // resizeMode='contain'
              style={{
                flexGrow: 1,
                width,
                height,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              // @ts-ignore
              imageStyle={{
                borderRadius: 8,
                backgroundColor: colors.bgSecondary,
              }}
              rounded='md'
              // resizeMethod='resize'
              //   resizeMode='contain'
              source={{
                //   uri: imageUri,
                uri: inputImageUri,
                //   uri: 'gs://chat-api-metazooie/images/ae7c1f8c-93ec-402b-9646-77b42ff325a9.png',
              }}
            >
              <VStack
                bg='bg'
                opacity={position > 96 ? 0 : 0.7}
                alignSelf='flex-start'
                p={0.5}
                ml={1}
                rounded='sm'
              >
                <Text fontWeight={500}>Before</Text>
              </VStack>
            </CachedImage>
          </VStack>
        }
        itemTwo={
          <VStack alignItems='center'>
            <CachedImage
              onExtendedLoad={(width, height) => setAdjustedHeight(height)}
              extend
              isBackground
              bg='gray.500'
              style={{
                flexGrow: 1,
                width,
                height,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              // @ts-ignore
              imageStyle={{
                borderRadius: 8,
                backgroundColor: colors.bgSecondary,
              }}
              h={height}
              w={width}
              rounded='md'
              //   resizeMethod='resize'
              //   resizeMode='contain'
              source={{
                //   uri: imageUri,
                uri: output,
              }}
            >
              <VStack
                bg='bg'
                opacity={position < 4 ? 0 : 0.7}
                alignSelf='flex-end'
                p={0.5}
                mr={1}
                rounded='sm'
              >
                <Text fontWeight={500}>After</Text>
              </VStack>
            </CachedImage>
          </VStack>
        }
      />
    </VStack>
  );
};

const StructuredMessage = ({
  message,
  page,
  onAction,
}: {
  message: api['thread']['response']['messages'][0];
  page: api['config']['response']['pages'][0];
  onAction?: (action: Action) => void;
}) => {
  const data = JSON.parse(message.data || '{}');

  if (data?.type === 'image' && data?.inputs != null) {
    return (
      <Img2ImgMessage
        onAction={onAction}
        inputs={page?.model?.inputs || []}
        data={data}
      />
    );
  }

  if (data?.type === 'image') {
    return (
      <CachedImage
        rounded='md'
        extend
        w={isDesktop() ? 512 : 300}
        source={{
          uri: (
            data as {
              file: string;
              type: 'image';
            }
          ).file,
        }}
      />
    );
  }

  return null;
};

export default StructuredMessage;
