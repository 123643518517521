import { Icon, KeyboardAvoidingView } from 'native-base';
import { ScrollView, Pressable, FlatList as RNFlatList } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { Keyboard } from 'react-native';

import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { Ionicons } from '@expo/vector-icons';
import _omit from 'lodash/omit';

import {
  useCategoriesQuery,
  useConfigQuery,
  usePageFavoriteMutation,
  usePublicThreadsQuery,
  useThreadsQuery,
  useUserQuery,
} from '../api';
import { useLocation, useNavigate, useParams } from '../components/Router';
import SendMessage, { SendMessageRef } from '../components/SendMessage';
import ThreadHistory from '../components/ThreadHistory';
import { Platform } from 'react-native';

import ChatPromptCard from '../components/ChatPromptCard';
import { Component } from 'react';
import { Text, VStack, HStack, Box, Button } from '../ui';
import { isDesktop, isWeb as isWebUtil, uuid } from '../utils';
import { api } from '../apiTypes';
import Loading from '../components/Loading';
import { usei18n } from '../utils/i18n';
import { colors } from '../utils/theme';
import Search, { SearchRef } from '../components/SearchOld';
import CachedImage from '../components/CachedImage';
import SocialMediaButton from '../components/SocialMediaButton';
import { useUser } from '../hooks';
import Bot from '../icons/Bot';

import RegisterModal, { useModal } from '../components/Modal';
import { User } from 'firebase/auth';
import TopNavbar from '../components/TopNavbar';
import { DefaultImage } from '../components/UserBadge';
import ImageToImage from './ImageToImage';
import PageCard from '../components/PageCard';

type ListItemProps = {
  prompts: api['categories']['response'][number]['prompts'];
  label: string;
  onSelectPrompt: (promptIds: string[]) => void;
  selectedPromptIds: string[];
  scrollRef: React.RefObject<ScrollView>;
  showLabel: boolean;
};
export class PromptListItem extends Component<
  ListItemProps,
  { hasScrolled: boolean; hasSelected: boolean }
> {
  constructor(props: ListItemProps) {
    super(props);
    // @ts-ignore
    this.scrollRef = createRef();
    this.state = { hasScrolled: false, hasSelected: false };
  }

  shouldComponentUpdate({
    prompts,
    selectedPromptIds,
    showLabel,
  }: Readonly<ListItemProps>) {
    return (
      selectedPromptIds.some(
        (id) => prompts.find((p) => p.id === id) != null
      ) ||
      this.props.selectedPromptIds.some(
        (id) => prompts.find((p) => p.id === id) != null
      ) ||
      this.props.prompts.length !== prompts.length ||
      this.props.showLabel !== showLabel
    );
  }
  componentDidUpdate(
    prevProps: Readonly<ListItemProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      prevProps.selectedPromptIds !== this.props.selectedPromptIds &&
      !this.state.hasScrolled &&
      !this.state.hasSelected
    ) {
      if (this.props.selectedPromptIds.length === 0) return;
      if (!this.scrollRef) return;

      const promptId = this.props.selectedPromptIds[0];
      const index = this.props.prompts.findIndex((p) => p.id === promptId);
      const prompt = this.props.prompts.find((p) => p.id === promptId);

      if (prompt == null) return;

      if (prompt.type === 'image') {
        this.scrollRef?.current?.scrollTo({
          x: index * (100 + 5),
          animated: true,
        });
      } else {
        const cutoff =
          Math.floor(this.props.prompts.length / 2) +
          (this.props.prompts.length % 2);
        const trueIndex = index <= cutoff ? index : index - cutoff;

        // .slice(
        //   0,
        //   Math.floor(prompts.length / 2) + (prompts.length % 2)
        // )
        this.scrollRef?.current?.scrollTo({
          x: trueIndex * (100 + 5),
          animated: true,
        });
      }
    }
  }
  render() {
    const {
      label,
      prompts,
      onSelectPrompt: setSelectedPromptIds,
      selectedPromptIds,
      showLabel,
    } = this.props;

    const hasImage = prompts.find((p) => ['image', 'page'].includes(p.type));

    return (
      <VStack key={label}>
        {showLabel && (
          <Text color='text' fontSize='xl' ml={3} mb={3} mt={4}>
            {label}
          </Text>
        )}
        <ScrollView
          keyboardShouldPersistTaps='handled'
          ref={this.scrollRef}
          onScrollBeginDrag={() =>
            this.setState({
              ...this.state,
              hasScrolled: true,
            })
          }
          horizontal={!isDesktop()}
          showsHorizontalScrollIndicator={false}
          style={{
            width: '100%',
          }}
        >
          {hasImage ? (
            <VStack space={2}>
              <HStack
                px={2}
                space={3}
                flexWrap={isDesktop() ? 'wrap' : undefined}
              >
                {prompts.map((prompt, idx) => {
                  if (prompt == null) {
                    console.log('null prompt');
                    return <></>;
                  }
                  const promptCardProps = {
                    fontSize: isWebUtil ? 12 : undefined,
                    key: prompt.id + idx,
                    prompt,
                    selected: selectedPromptIds.includes(prompt.id),
                    onPress: () => {
                      if (selectedPromptIds.includes(prompt.id)) {
                        setSelectedPromptIds([]);
                        return;
                      }

                      setSelectedPromptIds([prompt.id]);

                      Keyboard.dismiss();

                      this.setState({
                        ...this.state,
                        hasSelected: true,
                      });
                    },
                  };

                  return <ChatPromptCard {...promptCardProps} />;
                })}
              </HStack>
            </VStack>
          ) : (
            <VStack space={2}>
              <HStack
                px={2}
                space={isDesktop() ? 0 : 3}
                flexWrap={isDesktop() ? 'wrap' : undefined}
              >
                {prompts
                  .slice(
                    0,
                    isDesktop()
                      ? prompts.length
                      : Math.floor(prompts.length / 2) + (prompts.length % 2)
                  )
                  .map((prompt, idx) => {
                    if (prompt == null) {
                      console.log('null prompt');
                      return <></>;
                    }
                    const promptCardProps = {
                      fontSize: isWebUtil ? 12 : undefined,
                      key: prompt.id + idx,
                      prompt,
                      selected: selectedPromptIds.includes(prompt.id),
                      onPress: () => {
                        Keyboard.dismiss();
                        if (selectedPromptIds.includes(prompt.id)) {
                          setSelectedPromptIds([]);
                          return;
                        }
                        setSelectedPromptIds([prompt.id]);
                        this.setState({
                          ...this.state,
                          hasSelected: true,
                        });
                      },
                    };

                    return (
                      <Box mt={isDesktop() ? 2 : 0} mx={isDesktop() ? 1 : 0}>
                        <ChatPromptCard {...promptCardProps} />
                      </Box>
                    );
                  })}
              </HStack>
              <HStack px={2} space={3}>
                {(isDesktop() ? [] : prompts)
                  .slice(
                    Math.floor(prompts.length / 2) + (prompts.length % 2),
                    prompts.length
                  )
                  .map((prompt) => (
                    <ChatPromptCard
                      fontSize={isWebUtil ? 12 : undefined}
                      key={prompt.id}
                      prompt={prompt}
                      selected={selectedPromptIds.includes(prompt.id)}
                      onPress={() => {
                        Keyboard.dismiss();
                        if (selectedPromptIds.includes(prompt.id)) {
                          setSelectedPromptIds([]);
                          return;
                        }
                        setSelectedPromptIds([prompt.id]);
                        this.setState({
                          ...this.state,
                          hasSelected: true,
                        });
                      }}
                    />
                  ))}
              </HStack>
            </VStack>
          )}
        </ScrollView>
      </VStack>
    );
  }
}

const WEB_HEADER_SIZE = 260;

const Chat = ({
  isPage = false,
  isWeb = false,
}: {
  isPage?: boolean;
  isWeb?: boolean;
}) => {
  const { id: pageId } = useParams();
  const location = useLocation();

  const [initialPromptId, setInitialPromptId] = useState<string>();

  const [hasSuggestedQuestions, setHasSuggestedQuestions] = useState(false);
  const [isChatFocused, setIsChatFocused] = useState(false);

  const [selectedPromptIds, setSelectedPromptIds] = useState<string[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const promptId = params.get('promptId');
    if (promptId != null) {
      setTimeout(() => {
        setInitialPromptId(promptId);
        setSelectedPromptIds([promptId]);
        navigate('.', { replace: true });
      }, 200);
    }
  }, [location]);

  const [historyIsOpen, setHistoryIsOpen] = useState(false);

  const { data: userData, refetch } = useUserQuery();

  const [user] = useUser();
  const userRef = useRef<User | null>();
  userRef.current = user;

  const {
    data: threadsData,
    refetch: refetchThreads,
    optimisticUpdate: optimisticUpdateThreads,
  } = useThreadsQuery({}, { skip: isWeb && userData == null });
  const { data: publicThreadsData } = usePublicThreadsQuery(
    {},
    { skip: isWeb && userData == null }
  );

  const [isLoadingImage, setIsLoadingImage] = useState(true);

  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const isSearching = searchTerm.length >= 2;
  const limit = 3;
  const [shouldShowSearch, setShouldShowSearch] = useState(false);

  const { data: configData } = useConfigQuery(
    {},
    { skip: isWeb && userData == null }
  );

  const page = (configData?.pages || []).find((p) => p.id === pageId);

  const [categories, setCategories] = useState<
    (api['categories']['response'][number] & { idx: number })[]
  >([]);

  const loadAllCategories = isSearching || initialPromptId;

  const { data: categoriesData } = useCategoriesQuery(
    loadAllCategories ? { pageId } : { offset, limit, pageId },
    {
      skip: isWeb && userData == null,
    }
  );

  const [addPageFavorite] = usePageFavoriteMutation({
    onMutate: refetchThreads,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const promptId = params.get('promptId');
    if (promptId != null || initialPromptId == null) return;

    if (selectedPromptIds.length > 0) return;

    setInitialPromptId(undefined);
    setCategories([]);
    setOffset(0);

    setSelectedPromptIds([]);

    setSearchTerm('');
    setShouldShowSearch(false);
    searchRef?.current?.clearInput();
  }, [selectedPromptIds]);

  useEffect(() => {
    // if (pageId == null) return;
    setCategories([]);
    setOffset(0);

    setSelectedPromptIds([]);

    setSearchTerm('');
    setShouldShowSearch(false);
    searchRef?.current?.clearInput();
  }, [pageId]);

  const { i18n, translations } = usei18n();
  const { openModal } = useModal();

  useEffect(() => {
    if (isSearching) return;
    if (loadAllCategories) {
      setCategories((categoriesData || []).map((c, idx) => ({ ...c, idx })));
      return;
    }

    setCategories((t) => [
      ...(t ?? []),
      ...(categoriesData ?? []).map((c, idx) => ({ ...c, idx })),
    ]);
  }, [categoriesData]);

  useEffect(() => {
    if (!isSearching) {
      setCategories([]);
      setOffset(0);
      return;
    }
    const cats =
      (categoriesData || []).length > 0 ? categoriesData : categories;

    setCategories(
      (cats || [])
        .map((c) => ({
          ...c,
          prompts: c.prompts.filter((p) => {
            const label = i18n(p).label;

            return label.toLowerCase().includes(searchTerm.toLowerCase());
          }),
        }))
        .filter((c) => c.prompts.length > 0)
        .map((c, idx) => ({
          ...c,
          idx,
        }))
    );
  }, [searchTerm]);

  const messageRef = useRef<SendMessageRef>();
  const categoryListRef = useRef<RNFlatList>();
  const searchRef = useRef<SearchRef>();

  const navigate = useNavigate();

  const { recentlyUsed } = useMemo(() => {
    const promptsUsed = (threadsData?.threads || [])
      .filter((t) => t.prompt != null)
      .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
      .flatMap((t) => t.prompt);

    const recentlyUsed = Array.from(new Set(promptsUsed));
    return { recentlyUsed };
  }, [(threadsData?.threads || []).length]);

  const popularPrompts = (publicThreadsData?.popularPrompts || []).slice(0, 8);

  const prompts = (categories ?? []).flatMap((c) => c.prompts);

  const categoriesLength = (categories ?? []).length;

  const selectedPrompt =
    selectedPromptIds?.[0] != null
      ? prompts.find((p) => p.id === selectedPromptIds[0])
      : undefined;

  const onSendMessage = ({
    message,
    files,
    prompt,
  }: Parameters<
    React.ComponentProps<typeof SendMessage>['onSendMessage']
  >[0] & {
    prompt?: api['categories']['response'][number]['prompts'][number];
  }) => {
    if (pageId) {
      refetchThreads({ shouldUseCache: true, newParams: { pageId } });
    }
    // refetchThreads({ shouldUseCache: true });

    // const numMessagesInLastDay = (threadsData?.threads || [])
    //   .flatMap((t) => t.messages)
    //   .filter(
    //     (m) =>
    //       !m.isBot &&
    //       new Date(m.createdAt) > new Date(Date.now() - 24 * 60 * 60 * 1000)
    //   ).length;

    // if (
    //   userRef?.current?.isAnonymous &&
    //   numMessagesInLastDay >= (configData?.numAnonMessages ?? 9999)
    // ) {
    //   openModal({
    //     type: 'register',
    //   });
    //   return;
    // }

    // const tokens = userData?.tokens ?? 0;

    // const tokensToUse =
    //   page?.tokens ?? prompt?.tokens ?? configData?.defaultNumTokens ?? 0;

    // if (tokens < tokensToUse) {
    //   openModal({
    //     type: 'insufficient-tokens',
    //   });
    //   return;
    // }

    // if (userData?.subscriptionReceipt == null && userData?.stripeId == null) {
    //   const hasExceededFreeLimit =
    //     numMessagesInLastDay >= (configData?.numDailyFreeMessages ?? 9999);

    //   if (hasExceededFreeLimit) {
    //     openModal({
    //       type: 'limit-reached',
    //     });
    //     return;
    //   }
    // }

    const threadId = uuid();

    setTimeout(() => {
      navigate(
        `/thread/${threadId}?new=${JSON.stringify({
          message,
          files,
          prompt: prompt || selectedPrompt,
          pageId,
        })}`
      );
    }, 50);
  };

  const ChatMain = useMemo(() => {
    const recentlyUsedCategory = initialPromptId
      ? []
      : recentlyUsed
          .map((p) => prompts.find((p2) => p === p2.id))
          .filter((p) => p != null);

    const popularPromptCategory = initialPromptId
      ? []
      : popularPrompts
          .map((p) => prompts.find((p2) => p === p2.id))
          .filter((p) => p != null);

    let filteredCategories = categories;

    if (initialPromptId) {
      filteredCategories = filteredCategories
        .map((c) => ({
          ...c,
          prompts: c.prompts.filter((p) => p.id === initialPromptId),
        }))
        .filter((c) => c.prompts.length > 0);
    }

    const listCategories = [
      ...(isSearching || pageId != null || !hasSuggestedQuestions
        ? []
        : [
            ...(recentlyUsedCategory.length > 0
              ? [
                  {
                    label:
                      translations['🕙 Recently Used'] || '🕙 Recently Used',
                    prompts: recentlyUsedCategory,
                    idx: -2,
                  },
                ]
              : []),
            ...(popularPromptCategory.length > 0
              ? [
                  {
                    label: translations['📈 Trending'] || '📈 Trending',
                    prompts: popularPromptCategory,
                    idx: -1,
                  },
                ]
              : []),
          ]),

      ...(!hasSuggestedQuestions
        ? [filteredCategories?.[0]]
        : filteredCategories),
    ];

    const PageHeader = (
      <>
        <VStack alignItems='center'>
          {pageId == null ? (
            <DefaultImage size={100} />
          ) : (
            <CachedImage
              size={100}
              fallbackComponent={<Box bg='gray' rounded='full' size={100} />}
              rounded='full'
              source={{
                uri: `/static/images/pages/${pageId}.png`,
              }}
              onLoadEnd={() => setIsLoadingImage(false)}
            />
          )}
          <Text fontSize='md' fontWeight={700} color='secondary'>
            {pageId == null ? 'Laila' : i18n(page).name}
          </Text>

          <Text color='secondary' textAlign='center' mx={3}>
            {i18n(page).description}
          </Text>

          {pageId == null ? (
            <></>
          ) : (threadsData?.pageFavorites || []).find((p) => p.id === pageId) !=
            null ? (
            <Button
              mt={4}
              h={8}
              w={32}
              bg='secondary'
              rounded='md'
              onPress={() => {
                optimisticUpdateThreads({
                  ...threadsData,
                  pageFavorites: threadsData.pageFavorites.filter(
                    (f) => f.id !== pageId
                  ),
                });
                addPageFavorite({
                  pageId,
                  favorited: false,
                });
              }}
            >
              <Text color='bg'>Following</Text>
            </Button>
          ) : (
            <Button
              mt={4}
              h={8}
              w={32}
              rounded='md'
              onPress={() => {
                optimisticUpdateThreads({
                  ...threadsData,
                  pageFavorites: (threadsData.pageFavorites || []).concat(
                    _omit(page, 'categories')
                  ),
                });
                addPageFavorite({
                  pageId,
                  favorited: true,
                });
              }}
            >
              <Text color='bg'>Follow</Text>
            </Button>
          )}
        </VStack>

        <HStack space={1} my={2}>
          {Object.keys(page?.socialMedia || {}).map((type) => (
            <SocialMediaButton
              key={type}
              type={type as any}
              size={4}
              onPress={() =>
                WebBrowser.openBrowserAsync(page?.socialMedia[type])
              }
            />
          ))}
        </HStack>
      </>
    );

    const PageInfoContent = (
      <VStack>
        <VStack
          alignItems='center'
          h={WEB_HEADER_SIZE}
          justifyContent='flex-end'
          space={'md'}
        >
          {PageHeader}
          <Text fontSize='md' fontWeight={700} color='secondary'>
            How can I help you today?
          </Text>

          {!hasSuggestedQuestions && (
            <Pressable
              onPress={() => {
                if (selectedPromptIds.length > 0) {
                  setSelectedPromptIds([]);
                }
                setHasSuggestedQuestions(true);
              }}
            >
              <HStack
                alignItems='center'
                bg='white'
                rounded='full'
                shadow='7'
                px={2}
              >
                <Text fontSize='xs' color='secondary'>
                  Suggest Questions
                </Text>
                <Box m={2}>
                  <Bot size={16} />
                </Box>
              </HStack>
            </Pressable>
          )}
          <Box />
        </VStack>
      </VStack>
    );

    const SearchContent = (
      <HStack alignSelf='center' mb={4} mx={6}>
        <Box w='100%' opacity={shouldShowSearch ? 1 : 0}>
          <Search
            ref={searchRef}
            placeholder='Search'
            type='prompt'
            withResults={false}
            onSearch={(term) => {
              setSearchTerm(term);
            }}
            onEndSearch={() => {
              setShouldShowSearch(false);
            }}
            onSelect={(prompt) => {
              setSelectedPromptIds([prompt.id]);

              const index = categories.findIndex((c) =>
                c.prompts.find((p) => p.id === prompt.id)
              );

              if (index === -1) {
                return;
              }

              categoryListRef?.current?.scrollToIndex({
                animated: true,
                // To account for Recently Used/Trending
                index: 20 + 2,
              });
            }}
          />
        </Box>
      </HStack>
    );

    const CategoriesList = (
      <RNFlatList
        ListHeaderComponent={
          <>
            {PageInfoContent}
            {SearchContent}
          </>
        }
        ListFooterComponent={<VStack h='120px' />}
        keyboardShouldPersistTaps='handled'
        ref={categoryListRef}
        onEndReached={() => setOffset((o) => o + limit)}
        onEndReachedThreshold={0.5}
        contentContainerStyle={{
          justifyContent: 'flex-end',
          flexGrow: 1,
        }}
        style={{}}
        data={listCategories}
        renderItem={({ item: category, index }) => (
          <PromptListItem
            key={index}
            showLabel={!isWeb || hasSuggestedQuestions}
            label={i18n(category).label}
            prompts={category?.prompts || []}
            selectedPromptIds={selectedPromptIds}
            onSelectPrompt={(promptIds) => {
              if (promptIds.length > 0) {
                const prompt = prompts.find((p) => p.id === promptIds[0]);

                if (
                  !(prompt?.default && prompt.examples && prompt.description)
                ) {
                  onSendMessage({
                    message: i18n(prompt).label,
                    files: [],
                    prompt,
                  });
                  return;
                }

                setSelectedPromptIds(promptIds);

                messageRef?.current?.clearInput();

                if (prompt.default || prompt.examples) {
                  return;
                }
                messageRef.current?.focusInput();
              } else {
                setSelectedPromptIds([]);
              }
            }}
          />
        )}
      />
    );

    const isModel = page?.model != null;

    return (
      <VStack
        h={'100%'}
        opacity={!isModel && isLoadingImage && pageId != null ? 0 : 1}
      >
        <TopNavbar
          leftContent={
            isModel && isDesktop() ? (
              <PageCard size='sm' page={page} horizontal />
            ) : undefined
          }
          hideSearch={isModel}
          leftIcon={isDesktop() && !isModel ? 'back' : undefined}
          onHistory={() => setHistoryIsOpen(true)}
        />

        {isModel && !isDesktop() && <Box my={1} />}

        {isModel ? (
          isDesktop() ? (
            <ImageToImage page={page} />
          ) : (
            <VStack alignContent='center'>
              {PageHeader}
              <ImageToImage page={page} />
            </VStack>
          )
        ) : (
          CategoriesList
        )}

        {!isModel && (
          <VStack
            style={{
              position: 'fixed',
              width: isDesktop() ? 640 : '100%',
              bottom: isDesktop() ? 48 : 0,
            }}
          >
            <SendMessage
              ref={messageRef}
              numTokens={
                page?.tokens ??
                selectedPrompt?.tokens ??
                configData?.defaultNumTokens ??
                0
              }
              prompt={selectedPrompt}
              placeholderText={
                selectedPromptIds.length === 0
                  ? undefined
                  : translations['Type questions or hit send →'] ||
                    'Type questions or hit send →'
              }
              onSendMessage={onSendMessage}
              onFocus={() => setIsChatFocused(true)}
              onBlur={() => setIsChatFocused(false)}
            />
          </VStack>
        )}
      </VStack>
    );
  }, [
    initialPromptId,
    page,
    categoriesLength,
    selectedPromptIds,
    publicThreadsData,
    threadsData,
    searchTerm,
    shouldShowSearch,
    hasSuggestedQuestions,
    isLoadingImage,
    userData,
    user,
  ]);

  if (isWeb && (userData == null || configData == null)) {
    return (
      <VStack h='full' alignItems='center' justifyItems='center'>
        <Loading />
      </VStack>
    );
  }

  if (historyIsOpen) {
    return (
      <HStack h='full'>
        <Box w='85%'>
          <ThreadHistory
            pageId={pageId}
            activeThreadId={null}
            onClose={() => setHistoryIsOpen(false)}
          />
        </Box>

        <Pressable onPress={() => setHistoryIsOpen(false)}>
          <Box h='full' opacity={0.3} pointerEvents='none'>
            {ChatMain}
          </Box>
        </Pressable>
      </HStack>
    );
  }

  if (Platform.OS === 'web') {
    return <>{ChatMain}</>;
  }

  return (
    <KeyboardAvoidingView enabled={isChatFocused} behavior='position'>
      {ChatMain}
    </KeyboardAvoidingView>
  );
};

export default Chat;
