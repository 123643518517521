import { Icon } from 'native-base';
import { Box, HStack, Text } from '../ui';
import { FontAwesome } from '@expo/vector-icons';
import Token from './icons/Token';

const Tokens = ({ tokens }: { tokens: number }) => (
  <HStack ml={2} alignItems='center' space={1}>
    <Token />
    <Text>{tokens}</Text>
  </HStack>
);

export default Tokens;
