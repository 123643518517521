import { Box, HStack, Icon, Menu, Pressable, useToast } from 'native-base';

import {
  useReportMutation,
  useThreadFavoriteMutation,
  useUpdateThreadMutation,
} from '../api';
import { useUser } from '../hooks';
import { threadIsFavorited } from './ThreadCard';
import { MaterialIcons, FontAwesome, Ionicons } from '@expo/vector-icons';
import { isWeb, shareThread } from '../utils';
import Modal from './BasicModal';
import { useState } from 'react';
import { api } from '../apiTypes';
import { Text } from '../ui';
import { colors } from '../utils/theme';
import { usei18n } from '../utils/i18n';

type Option = {
  label: string;
  onPress: () => void;
  icon: React.ReactElement;
  destructive?: boolean;
};

const ThreadOptions = ({
  type = 'thread',
  comment,
  message,
  thread,
  onChange,
  onRename,
  isCreator = true,
}:
  | {
      type: 'message';
      comment?: api['db']['Comment'];
      message: api['db']['Message'];
      thread?: api['db']['Thread'] & {
        favorites: api['db']['ThreadFavorite'][];
      };
      onChange?: () => void;
      onRename?: () => void;
      isCreator?: boolean;
    }
  | {
      type: 'comment';
      comment: api['db']['Comment'];
      message?: api['db']['Message'];
      thread?: api['db']['Thread'] & {
        favorites: api['db']['ThreadFavorite'][];
      };
      onChange?: () => void;
      onRename?: () => void;
      isCreator?: boolean;
    }
  | {
      type?: 'thread';
      comment?: api['db']['Comment'];
      message?: api['db']['Message'];
      thread: api['db']['Thread'] & {
        favorites: api['db']['ThreadFavorite'][];
      };
      onChange: () => void;
      onRename?: () => void;
      isCreator?: boolean;
    }) => {
  const [user] = useUser();
  const [updateThread] = useUpdateThreadMutation({ onMutate: onChange });
  const { translations: i18n } = usei18n();

  const [favoriteThread] = useThreadFavoriteMutation({
    onMutate: onChange,
  });

  const [report] = useReportMutation({
    onMutate: onChange,
  });

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const toast = useToast();

  const options: Option[] = [];

  if (type === 'message') {
    options.push({
      label: 'Report Message',
      onPress: () => {
        report({
          messageId: message.id,
        }).then(() => {
          toast.show({
            title: 'Message Reported',
            variant: 'info',
          });
        });
      },
      icon: <FontAwesome name='flag-o' />,
    });
  } else if (type === 'comment') {
    options.push({
      label: 'Report Comment',
      onPress: () => {
        report({
          commentId: comment.id,
        }).then(() => {
          toast.show({
            title: 'Comment Reported',
            variant: 'info',
          });
        });
      },
      icon: <FontAwesome name='flag-o' />,
    });
  } else if (type === 'thread' && isCreator) {
    const favoriteOption: Option = {
      label: !threadIsFavorited(thread, user.uid)
        ? 'Add To Favorites'
        : 'Remove As Favorite',
      onPress: () =>
        favoriteThread({
          threadId: thread.id,
          favorited: !threadIsFavorited(thread, user.uid),
        }),
      icon: <FontAwesome name='heart-o' />,
    };

    const creatorOptions: Option[] = onRename
      ? [
          {
            label: 'Rename',
            onPress: onRename,
            icon: <MaterialIcons name='edit' />,
          },
        ]
      : [];

    creatorOptions.push(
      ...[
        {
          label: thread.isPublic ? 'Make Private' : 'Make Public',
          onPress: () =>
            updateThread({
              id: thread.id,
              isPublic: !thread.isPublic,
            }),
          icon: <FontAwesome name='hashtag' />,
        },
        favoriteOption,
        {
          label: 'Share',
          onPress: async () => {
            if (!thread.isPublic) {
              await updateThread({
                id: thread.id,
                isPublic: !thread.isPublic,
              });
            }
            shareThread({ threadId: thread.id, i18n });
          },
          icon: <Ionicons name='ios-share-outline' />,
        },
      ]
    );

    options.push(...creatorOptions);

    options.push({
      label: 'Remove',
      onPress: () => setDeleteModalIsOpen(true),
      icon: <FontAwesome name='trash-o' />,
      destructive: true,
    });
  } else if (type === 'thread' && !isCreator) {
    const favoriteOption: Option = {
      label: !threadIsFavorited(thread, user.uid)
        ? 'Add To Favorites'
        : 'Remove As Favorite',
      onPress: () =>
        favoriteThread({
          threadId: thread.id,
          favorited: !threadIsFavorited(thread, user.uid),
        }),
      icon: <FontAwesome name='heart-o' />,
    };

    if (thread.isPublic && !isCreator) {
      options.push(favoriteOption, {
        label: 'Share',
        onPress: () => shareThread({ threadId: thread.id, i18n }),
        icon: <Ionicons name='ios-share-outline' />,
      });
    }

    options.push({
      label: 'Report Conversation',
      onPress: () => {
        report({
          threadId: thread.id,
        }).then(() => {
          toast.show({
            title: 'Conversation Reported',
            variant: 'info',
          });
        });
      },
      icon: <FontAwesome name='flag-o' />,
    });
  }

  return (
    <>
      <Menu
        w='190'
        // placement='top'
        bg='bgSecondary'
        // offset={16}
        // crossOffset={80}
        _backdrop={{}}
        _presenceTransition={{}}
        _overlay={{}}
        trigger={(triggerProps) => (
          <Pressable {...triggerProps}>
            <MaterialIcons name='more-horiz' size={24} color={'black'} />
          </Pressable>
        )}
      >
        {options.map(({ label, icon, onPress, destructive }, idx) => (
          <Menu.Item key={idx} onPress={onPress} px={0}>
            <HStack
              justifyContent='space-between'
              w='100%'
              alignItems='center'
              justifyItems='end'
            >
              <Text color={destructive ? 'red.500' : 'text'}>{label}</Text>
              <Icon
                as={icon}
                color={destructive ? 'red.500' : 'text'}
                justifyContent='center'
              />
            </HStack>
          </Menu.Item>
        ))}
      </Menu>
      <Modal
        onSubmit={() => {
          setDeleteModalIsOpen(false);
        }}
        title='Delete Conversation'
        body={`Are you sure you want to delete this conversation?`}
        isOpen={deleteModalIsOpen}
        onClose={() => {
          setDeleteModalIsOpen(false);
          updateThread({
            id: thread.id,
            isDeleted: true,
          }).then(onChange);
        }}
      />
    </>
  );
};

export default ThreadOptions;
