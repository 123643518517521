import { Input, Pressable } from 'native-base';
import { Feather } from '@expo/vector-icons';
import { useState } from 'react';
import { useUpdateThreadMutation } from '../api';
import { useLoading } from './Loading';
import { api } from '../apiTypes';
import { colors } from '../utils/theme';

const ThreadTitleEditor = ({
  thread,
  onSave,
}: {
  thread: api['db']['Thread'];
  onSave: () => void;
}) => {
  const [newThreadName, setNewThreadName] = useState<string>(thread.title);
  const [updateThread, { loading }] = useUpdateThreadMutation({
    onMutate: onSave,
  });

  useLoading(loading);

  return (
    <Input
      rounded='full'
      placeholder='New name'
      bg='bgSecondary'
      color='text'
      _focus={{
        bg: 'bgSecondary',
      }}
      placeholderTextColor='text'
      w='70%'
      variant='unstyled'
      value={newThreadName}
      onChangeText={setNewThreadName}
      InputRightElement={
        <Pressable
          mr={2}
          onPress={() => {
            updateThread({
              id: thread.id,
              title: newThreadName,
            });
          }}
        >
          <Feather name='save' color={colors.text} size={20} />
        </Pressable>
      }
    />
  );
};

export default ThreadTitleEditor;
