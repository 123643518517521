import { useState, useEffect } from 'react';
import { type api } from './apiTypes';
import { useUser } from './hooks';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { log } from './utils';
import { diff } from 'deep-diff';

// Set to true to use firebase emulator
export const dev = false;
export const API_BASE_URL = dev
  ? 'http://localhost:5001/dreambooth-31489/us-central1/'
  : 'https://dreambooth-31489.web.app/api/';

type CacheStrategy =
  // only cache-and-network implemented
  'cache-and-network' | 'network-only' | 'cache-first' | 'cache-only';

const createUseQuery =
  <
    P extends {
      params: Record<string, any> | null;
      response: Record<string, any> | null;
    }
  >(
    apiPath: string
  ) =>
  (
    params: Partial<P['params']> = {},
    {
      skip = false,
      cache = true,
      cacheStrategy = 'cache-and-network',
      auth = true,
      // 1 hour
      cacheTimeMs = 1000 * 60 * 60,
      // 5 seconds
      retryMs = 1000 * 5,
      maxRetries = 3,
    }: {
      skip?: boolean;
      lazy?: boolean;
      cache?: boolean;
      cacheStrategy?: CacheStrategy;
      cacheTimeMs?: number;
      retryMs?: number;
      auth?: boolean;
      maxRetries?: number;
    } = {}
  ) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error>();
    const [data, setData] = useState<P['response'] | null | undefined>();
    const [user, userIsLoading] = useUser();
    const [retries, setRetries] = useState(maxRetries);

    const fetchData = async ({
      useCache = true,
      newParams,
      cacheOnly = false,
    }: {
      useCache?: boolean;
      newParams?: P['params'];
      cacheOnly?: boolean;
    } = {}): Promise<P['response'] | null | undefined> => {
      const resolvedParams = newParams || params;

      Object.keys(resolvedParams).forEach((key) =>
        resolvedParams[key] == null ? delete resolvedParams[key] : {}
      );

      const queryParams = new URLSearchParams(
        resolvedParams as Record<string, any>
      );

      const url =
        API_BASE_URL +
        `${apiPath}` +
        (Object.keys(resolvedParams).length > 0 ? `?${queryParams}` : '');

      // console.log({ url, size: queryParams.size });

      if (cache && useCache) {
        let cacheExpired = true;

        const cacheExpiration = await AsyncStorage.getItem(
          `${url}/cacheExpiration`
        );

        if (cacheExpiration != null && new Date(cacheExpiration) > new Date()) {
          cacheExpired = false;
        }

        log({
          url,
          now: new Date().toISOString(),
          cacheExpired,
          cacheExpiration,
        });

        if (!cacheExpired) {
          const cachedData = await AsyncStorage.getItem(url);

          if (cachedData != null) {
            setLoading(false);
            const dataJson = JSON.parse(cachedData);

            if (!cacheOnly) {
              setData(dataJson);
            }

            return dataJson;
          }
        }
      }

      const headers = {
        'Content-Type': 'application/json',
      };

      if (auth) {
        if (user == null) {
          setLoading(false);
          return;
        }
        const userToken = await user?.getIdToken();
        headers['Authorization'] = `Bearer ${userToken}`;
      }

      let cachedData;
      if (cacheStrategy === 'cache-and-network') {
        const cachedDataStr = await AsyncStorage.getItem(url);
        cachedData = cachedDataStr != null ? JSON.parse(cachedDataStr) : null;

        if (cachedData != null) {
          setLoading(false);
          if (!cacheOnly) {
            setData(cachedData);
          }
        }
      }

      const res = await fetch(url, {
        method: 'GET',
        headers,
      }).catch((err) => {
        setError(err);
      });

      if (!res || !res.ok) {
        if (retries > 0) {
          setTimeout(() => fetchData(), retryMs);
          setRetries((r) => r - 1);
        }
        setLoading(false);
        return;
      }

      log(res.url);

      const data = await res.json();

      const now = new Date();
      const cacheExpiryTime = new Date(now.getTime() + cacheTimeMs);

      await AsyncStorage.setItem(
        `${url}/cacheExpiration`,
        cacheExpiryTime.toISOString()
      );

      if (cacheStrategy === 'cache-and-network') {
        if (cachedData != null) {
          const differences = diff(cachedData, data);

          const hasDifferences = differences != null && differences?.length > 0;

          if (!hasDifferences) {
            return cachedData;
          }
        }
      }

      await AsyncStorage.setItem(url, JSON.stringify(data));

      setLoading(false);
      setData(data);

      return data;
    };

    useEffect(() => {
      if (skip || userIsLoading || user == null) return;
      setLoading(true);
      fetchData();
    }, [user, skip, JSON.stringify(params), userIsLoading]);

    return {
      loading,
      data,
      error,
      refetch: ({
        shouldUseCache = false,
        background = true,
        newParams = undefined,
        // Only hit the cache, don't update state
        cacheOnly = false,
      }: {
        shouldUseCache?: boolean;
        background?: boolean;
        cacheOnly?: boolean;
        newParams?: P['params'];
      } = {}) => {
        if (!background) {
          setLoading(true);
        }
        return fetchData({
          useCache: shouldUseCache,
          newParams,
          cacheOnly,
        });
      },
      optimisticUpdate: (newData: typeof data) => {
        const queryParams = new URLSearchParams(params as Record<string, any>);

        const url =
          API_BASE_URL +
          `${apiPath}` +
          (Object.keys(params).length > 0 ? `?${queryParams}` : '');

        AsyncStorage.setItem(url, JSON.stringify(newData));

        if (skip) return;
        setData(newData);
      },
    };
  };

type CreateUseMutationReturn<
  P extends {
    params: Record<string, any> | null;
    response: Record<string, any> | null;
  }
> = [
  (params: P['params']) => Promise<P['response']>,
  {
    loading: boolean;
    error: Error | undefined;
    data: P['response'] | undefined;
  }
];

export const clearCache = async () => {
  const keys = await AsyncStorage.getAllKeys();
  await AsyncStorage.multiRemove(keys);
};

export const createUseMutation =
  <
    P extends {
      params: Record<string, any> | null;
      response: Record<string, any> | null;
    }
  >(
    apiPath: string,
    method = 'POST'
  ) =>
  ({
    onMutate = () => {},
  }: {
    onMutate?: () => void;
  } = {}): CreateUseMutationReturn<P> => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
    const [data, setData] = useState<P['response']>();
    const [user] = useUser();

    const mutation = async (params: P) => {
      log('calling mutation', API_BASE_URL + `${apiPath}`);
      setLoading(true);
      const userToken = await user?.getIdToken();

      const res = await fetch(API_BASE_URL + `${apiPath}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(params),
      }).catch((err) => {
        setError(err);
      });

      if (!res || !res.ok) {
        setLoading(false);
        return;
      }

      const data = await res.json();

      setData(data);
      onMutate();
      setLoading(false);

      return data;
    };

    return [mutation, { loading, data, error }];
  };

export const usePredictMutation = createUseMutation<api['predict']>('predict');

export const usePromptsQuery = createUseQuery<api['prompts']>('prompts');

export const usePredictionsQuery =
  createUseQuery<api['predictions']>('predictions');

export type Predictions = api['predictions']['response'];
export type Prompts = api['prompts']['response'];

export const useModelTrainingStatus = createUseQuery<
  api['modelTrainingStatus']
>('modelTrainingStatus');

export const useFavorites = createUseQuery<{
  params: {};
  response: api['favorites']['response'];
}>('favorites');

export const useFavoritesMutation =
  createUseMutation<api['favorites']>('favorites');

export const useUserQuery = createUseQuery<{
  params: {};
  response: api['user']['response'];
}>('user');

export const useUpdateUserMutation = createUseMutation<api['user']>('user');

export const usePageGroupQuery =
  createUseQuery<api['pageGroup']>('chat/pageGroup');

export const useThreadQuery = createUseQuery<api['thread']>('chat/thread');
export const useThreadsQuery = createUseQuery<api['threads']>('chat/threads');

export const usePublicThreadsQuery =
  createUseQuery<api['publicThreads']>('chat/publicThreads');

export const useCategoriesQuery =
  createUseQuery<api['categories']>('chat/categories');

export const useSearchQuery = createUseQuery<api['search']>('chat/search');

export const useConfigQuery = createUseQuery<api['config']>('chat/config');

export const useCreateThreadMutation =
  createUseMutation<api['createThread']>('chat/thread');

export const useSendMessageMutation =
  createUseMutation<api['sendMessage']>('chat/message');

export const useUpdateThreadMutation = createUseMutation<api['updateThread']>(
  'chat/thread',
  'PUT'
);

export const useCommentFavoriteMutation = createUseMutation<
  api['addCommentFavorite']
>('chat/favoriteComment');

export const usePageFavoriteMutation =
  createUseMutation<api['addPageFavorite']>('chat/favoritePage');

export const useThreadFavoriteMutation = createUseMutation<
  api['addThreadFavorite']
>('chat/favoriteThread');

export const useCommentMutation =
  createUseMutation<api['addComment']>('chat/comment');

export const useThreadUpvoteMutation =
  createUseMutation<api['addThreadUpvote']>('chat/upvoteThread');

export const useReportMutation =
  createUseMutation<api['report']>('chat/report');

export const useDeleteAccountMutation = createUseMutation<{
  params: {};
  response: {};
}>('deleteAccount');

export const useSignUrlMutation =
  createUseMutation<api['signUrl']>('chat/signUrl');

export const useSubscribeMutation =
  createUseMutation<api['subscribe']>('chat/subscribe');

export const useRequestOfferSignatureMutation = createUseMutation<
  api['requestOfferSignature']
>('chat/requestOfferSignature');

export const usePreloadData = ({ skip = false }: { skip?: boolean }) => {
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(true);

  // const [predictionImagesLoading, setPredictionImagesLoading] = useState(true);
  // const [promptImagesLoading, setPromptImagesLoading] = useState(true);

  // const { loading: favoritesLoading } = useFavorites({}, { skip });
  const [user, userLoading] = useUser();
  // const { data: predictionsData } = usePredictionsQuery({}, { skip });
  // const { data: promptsData } = usePromptsQuery({}, { skip });
  const { loading: userInfoLoading } = useUserQuery(
    {},
    { cache: false, skip: skip || !complete }
  );

  const { loading: categoriesLoading } = useCategoriesQuery(
    {},
    { cache: false, skip: skip || !complete }
  );
  // const { loading: modelStatusIsLoading } = useModelTrainingStatus(
  //   {},
  //   // 5 min
  //   { cacheTimeMs: 1000 * 60 * 5, skip }
  // );

  const loadingList = [
    // predictionImagesLoading,
    // promptImagesLoading,
    // favoritesLoading,
    categoriesLoading,
    userLoading,
    userInfoLoading,
    // modelStatusIsLoading,
  ];

  useEffect(() => {
    if (loadingList.some((l) => l)) return;
    setLoading(false);
    setComplete(true);
  }, loadingList);

  // useEffect(() => {
  //   if (promptsData == null || user == null) return;

  //   Promise.all(
  //     promptsData.map((prompt) =>
  //       cacheImage(`${API_BASE_URL}image?promptId=${prompt.id}`)
  //     )
  //   ).then(() => setPromptImagesLoading(false));
  // }, [promptsData]);

  // useEffect(() => {
  //   if (predictionsData == null || user == null) return;

  //   Promise.all(
  //     predictionsData.flatMap((prediction) =>
  //       [...Array(parseInt(prediction.numOutputs)).keys()].map((idx) =>
  //         cacheImage(
  //           `${API_BASE_URL}image?id=${user.uid}&uri=${prediction.uri}_${idx}.png`
  //         )
  //       )
  //     )
  //   ).then(() => setPredictionImagesLoading(false));
  // }, [predictionsData]);

  return { loading };
};
