import { createContext, useContext, useEffect, useState } from 'react';
import { TranslationId, api } from '../apiTypes';
import { useConfigQuery, useUserQuery } from '../api';
import { useUser } from '../hooks';

const additionalTranslations = {
  Accept: {
    es: 'Aceptar',
  },

  'You have run out of tokens': {
    es: 'Te has quedado sin tokens',
  },
  'Receive FREE tokens by creating an account': {
    es: 'Recibe tokens GRATIS creando una cuenta',
  },
  Yes: {
    es: 'Sí',
  },
  'Upgrade to': {
    es: 'Actualizar a',
  },
  each: {
    es: 'cada',
  },
  'cancel-subscription': {
    en: 'Are you sure you want to cancel your subscription?',
    es: '¿Estás seguro que quieres cancelar tu suscripción?',
  },
  No: {
    es: 'No',
  },
  Payment: {
    es: 'Pago',
  },
  'payment.succeeded': {
    en: 'Success! Payment received',
    es: '¡Éxito! Pago recibido',
  },
  'payment.processing': {
    en: `Payment processing. We'll update you when payment is received`,
    es: `Procesando pago. Te actualizaremos cuando se reciba el pago`,
  },
  'payment.requires_payment_method': {
    en: 'Payment failed. Please try another payment method',
    es: 'Pago fallido. Por favor intenta con otro método de pago',
  },
  'payment.error': {
    en: 'An error has ocurred. Please try again or contact support',
    es: 'Ha ocurrido un error. Por favor intenta de nuevo o contacta a soporte',
  },
  Name: {
    es: 'Nombre',
  },
  "There's nothing here yet 👻": {
    es: 'Aún no hay actividad aquí 👻',
  },
  month: {
    es: 'mes',
  },
  'Sign In': {
    es: 'Iniciar Sesión',
  },
  'Your current plan': {
    es: 'Tu plan actual',
  },
  Contract: {
    es: 'Contratar',
  },
  'New Chat': {
    es: 'Nuevo Chat',
  },
  Return: {
    es: 'Regresar',
  },
  'Plus Plan': {
    es: 'Contrata Plus',
  },
  'Update your plan to unlock all of the benefits.': {
    es: 'Actualiza tu plan para desbloquear todos los beneficios.',
  },
  create: {
    en: 'Create',
    es: 'Crear',
  },
  images: {
    en: 'Images',
    es: 'Imágenes',
  },
  Recents: {
    es: 'Recientes',
  },
  Following: {
    es: 'Siguiendo',
  },
  Published: {
    es: 'Publicados',
  },
  Saved: {
    es: 'Guardados',
  },
  Settings: {
    es: 'Configuración',
  },
  'Enter your email address': {
    es: 'Introducir dirección de correo electrónico',
  },
  'Register for free to start chatting': {
    es: 'Regístrate gratis para iniciar a conversar',
  },
  of: {
    es: 'de',
  },
  and: {
    es: 'y',
  },
  'Terms of Service': {
    es: 'Términos del servicio',
  },
  'By continuing you agree to the': {
    es: 'Al continuar usted acepta los',
  },
  'We will send you an email with a link to register without a password': {
    es: 'Te enviaremos un correo electrónico con un enlace para un registro sin contraseña',
  },
  'Change to Plus': {
    es: 'Cambiar a Plus',
  },
  "You've reached the limit of free messages": {
    es: 'Has alcanzado el límite de mensajes gratis',
  },
  'Public Chats': {
    es: 'Chats Públicos',
  },
  Pages: {
    es: 'Páginas',
  },
  'Remove As Favorite': {
    es: 'Eliminar de Favoritos',
  },
  Follow: {
    es: 'Seguir',
  },
  Unfollow: {
    es: 'Dejar de seguir',
  },
  Search: {
    es: 'Buscar',
  },
};

const supportedLanguages = ['en', 'es'];

type SupportedLanguage = 'en' | 'es';

export type I18N = {
  [id in TranslationId]: string;
};

const ConfigContext = createContext<{
  translations: I18N;
  language: SupportedLanguage;
  refresh: () => void;
  setLanguage: (languageCode: SupportedLanguage) => void;
  loading: boolean;
  config: api['config']['response'];
  isAdmin: boolean;
  i18n: <T extends { i18n?: Record<string, any> }>(
    data: T | null | undefined
  ) => T;
}>({
  translations: {} as I18N,
  refresh: () => {},
  setLanguage: () => {},
  language: 'en',
  loading: true,
  config: {} as api['config']['response'],
  isAdmin: false,
  i18n: (data: Record<string, any> | null | undefined) => data as any,
});

export const usei18n = () => {
  const { translations, refresh, language, setLanguage, loading, i18n } =
    useContext(ConfigContext);
  return { translations, refresh, language, setLanguage, loading, i18n };
};

export const useConfig = () => {
  const { config, isAdmin } = useContext(ConfigContext);
  return { config, isAdmin };
};

export function ConfigProvider({ children }) {
  const { data: userData, refetch: refetchUser } = useUserQuery({});

  const [user] = useUser();
  const [languageCode, setLanguageCode] = useState<SupportedLanguage>('es');
  const [loading, setLoading] = useState(true);
  const [i18n, setI18n] = useState<I18N>({} as I18N);
  const [config, setConfig] = useState<api['config']['response']>(
    {} as api['config']['response']
  );

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (userData == null) {
      setTimeout(() => {
        refetchUser({ cacheOnly: true });
      }, 300);
      return;
    }

    const allowedUsers = [
      // 'gus@metazooie.com',
      'me@nthh.dev',
      // 'admin@digitalyou.ai',
    ];

    if (allowedUsers.includes(userData?.email || '')) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }

    setLanguageCode(userData.language as SupportedLanguage);

    setLoading(false);
  }, [user, userData]);

  const { data: configData } = useConfigQuery({}, { auth: false });

  useEffect(() => {
    if (configData != null) {
      setConfig(configData);
    }

    const mergedTranslations = {
      ...(configData?.translations || {}),
      ...additionalTranslations,
    };

    const translations = Object.keys(mergedTranslations).reduce<I18N>(
      (acc, curr) => ({
        ...acc,
        [curr]: mergedTranslations[curr][languageCode],
      }),
      {} as I18N
    );
    setI18n(translations);
  }, [configData, languageCode]);

  return (
    <ConfigContext.Provider
      value={{
        language: languageCode,
        loading,
        translations: i18n,
        i18n: (data) => {
          if (data == null) return {};
          return {
            ...data,
            ...(data?.i18n?.[languageCode] || {}),
          };
        },
        refresh: () => {
          refetchUser();
        },
        config,
        isAdmin,
        setLanguage: (newLanguage) => {
          if (supportedLanguages.includes(newLanguage)) {
            setLanguageCode(newLanguage);
          }
        },
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}
