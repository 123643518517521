import { Ionicons, Octicons } from '@expo/vector-icons';
import { Box, HStack } from '../ui';
import { Icon } from 'native-base';
import Logo from './Logo';
import { useDrawer } from './Drawer';
import { isDesktop } from '../utils';
import { useLocation, useNavigate } from './Router';
import { useSearch } from './Search';

type Props = {
  onSearch?: () => void;
  onAdd?: () => void;
  onHistory?: () => void;
  hideSearch?: boolean;
  hideAdd?: boolean;
  onClose?: () => void;
  centerContent?: React.ReactElement;
  leftContent?: React.ReactElement;
  leftIcon?: 'close' | 'drawer' | 'back' | 'none';
};

const TopNavbar = ({
  leftContent,
  centerContent,
  leftIcon = 'drawer',
  onSearch,
  onAdd = () => {},
  hideAdd = false,
  hideSearch = false,
  onClose = () => {},
  onHistory = () => {},
}: Props) => {
  const navigate = useNavigate();
  const { openDrawer } = useDrawer();
  const { openSearch } = useSearch();
  const location = useLocation();

  const renderLeftIcon = () => {
    switch (leftIcon) {
      case 'close':
        return (
          <Icon
            as={<Ionicons name='ios-close-outline' />}
            color='primary'
            onPress={onClose}
            size={7}
          />
        );
      case 'back':
        return window.history.length <= 2 ? (
          <HStack />
        ) : (
          <Icon
            as={<Ionicons name='chevron-back-outline' />}
            color='primary'
            onPress={() => navigate(-1)}
            size={7}
          />
        );
      case 'drawer':
        return (
          <Icon
            as={<Ionicons name='ios-menu-outline' />}
            color='primary'
            onPress={openDrawer}
            size={7}
          />
        );
      case 'none':
        return <HStack />;
    }
  };

  return (
    <HStack
      justifyContent='space-between'
      alignItems='center'
      py={3}
      px={3}
      bg='bg'
    >
      {leftContent != null ? (
        leftContent
      ) : isDesktop() && leftIcon === 'drawer' ? (
        <HStack />
      ) : (
        renderLeftIcon()
      )}
      {isDesktop() && centerContent == null ? (
        <HStack />
      ) : (
        centerContent || <Logo />
      )}
      <HStack space={2} alignItems='center'>
        <Icon
          color='primary'
          pointerEvents={hideSearch ? 'none' : 'auto'}
          opacity={hideSearch ? 0 : 1}
          as={<Ionicons name='ios-search' />}
          onPress={() => {
            console.log('search');
            if (onSearch) return onSearch();
            openSearch();
          }}
          size={6}
        />

        {location.pathname.startsWith('/page/') ||
        location.pathname === '/chat' ||
        location.pathname === '/chats' ? (
          <Icon
            as={<Octicons name='history' />}
            opacity={hideAdd ? 0 : 1}
            pointerEvents={hideAdd ? 'none' : 'auto'}
            color='primary'
            size='24px'
            onPress={onHistory}
          />
        ) : (
          <Icon
            opacity={hideAdd ? 0 : 1}
            pointerEvents={hideAdd ? 'none' : 'auto'}
            color='primary'
            onPress={() => {
              navigate('/chat');
              onAdd();
            }}
            as={<Octicons name='plus' />}
            size={7}
          />
        )}
      </HStack>
    </HStack>
  );
};

export default TopNavbar;
