import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { Box, VStack, Text } from '../ui';
import { Spinner } from 'native-base';
import { ActivityIndicator } from 'react-native';

type Action = { type: 'start'; message?: string } | { type: 'stop' };
type Dispatch = (action: Action) => void;
type State = { isLoading?: boolean; message?: string };

const LoadingContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function loadingReducer(state: State, action: Action) {
  switch (action.type) {
    case 'start': {
      return { isLoading: true, message: action.message };
    }
    case 'stop': {
      return { isLoading: false, message: undefined };
    }
  }
}

function LoadingProvider({ children }: { children?: React.ReactNode }) {
  const [state, dispatch] = useReducer(loadingReducer, {
    isLoading: false,
    message: undefined,
  });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <LoadingContext.Provider value={value}>
      <Loader isLoading={state.isLoading} message={state.message} />
      {children}
    </LoadingContext.Provider>
  );
}

const useLoadingStatus = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error('useLoadingStatus must be used within a LoadingProvider');
  }
  return context;
};

/**
 * Tracks the given variable and stops/starts Loading based on it
 */
const useLoading = (
  isLoading: boolean,
  { message = '' }: { message?: string } = {}
) => {
  const context = useContext(LoadingContext);

  if (context === undefined) {
    throw new Error('useLoading hook must be used within a LoadingProvider');
  }

  useEffect(() => {
    return () => context.dispatch({ type: 'stop' });
  }, []);

  useEffect(() => {
    let timeout;
    if (isLoading) {
      timeout = setTimeout(() => {
        context.dispatch({ type: 'start', message });
      }, 300);

      return () => {
        if (timeout) clearTimeout(timeout);
      };
      return;
    } else {
      context.dispatch({ type: 'stop' });
    }
  }, [isLoading]);
};

export { LoadingProvider, useLoadingStatus, useLoading };

/**
 * Starts loader when rendered and stops when unmounted
 */
type LoadingProps = {
  message?: string;
};
const Loading = ({ message }: LoadingProps) => {
  const context = useContext(LoadingContext);

  useEffect(() => {
    if (context === undefined) return;
    const timeout = setTimeout(() => {
      context.dispatch({ type: 'start', message });
    }, 300);

    return () => {
      clearTimeout(timeout);
      context.dispatch({ type: 'stop' });
    };
  }, []);

  if (context === undefined) {
    return <Loader />;
  }

  return null;
};

type LoaderProps = {
  isLoading?: boolean;
  message?: string;
};

const Loader = ({ isLoading = true, message }: LoaderProps) =>
  !isLoading ? (
    <></>
  ) : (
    <Box
      focusable={false}
      position='absolute'
      h='85%'
      w='100%'
      // bg="bg"
      flex={1}
      flexDirection='column'
      // bg='purple.300'f
      alignItems='center'
      justifyContent='space-around'
      // bg="rgba(0, 0, 0, 0.2)"
      zIndex={999}
      style={{ top: '15%' }}
    >
      <VStack alignItems='center'>
        <Box
          mt={-32}
          focusable={false}
          flexShrink={1}
          //   bg="white"
          // opacity={.7}
          h={50}
          w={50}
          borderRadius={25}
          alignItems='center'
          justifyContent='space-around'
        >
          <ActivityIndicator size={'large'} />
        </Box>

        <Text opacity={message == null ? 0 : 1} fontSize='lg'>
          {message ?? '...'}
        </Text>
      </VStack>
    </Box>
  );

export default Loading;
