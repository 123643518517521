import { Modal as RNModal, Pressable, View } from 'react-native';
import { Button, HStack, Text, VStack } from '../ui';
import { CloseIcon, Input } from 'native-base';
import GoogleIcon from './icons/GoogleIcon';
import theme from '../utils/theme';
import { linkWithGoogle, onRedirect, sendPasswordlessEmail } from '../firebase';
import { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from '../hooks';
import { usei18n } from '../utils/i18n';
import { assertNever, isDesktop } from '../utils';
import Bot from '../icons/Bot';
import { useNavigate } from './Router';
import { useSubscribeMutation, useUserQuery } from '../api';
import { useReload } from '../reloader';
import { useLoading } from './Loading';
import Token from './icons/Token';

type ModalType =
  | 'register'
  | 'limit-reached'
  | 'cancel-subscription'
  | 'insufficient-tokens-anon'
  | 'insufficient-tokens-user'
  | 'new-user-tokens';

const ModalContext = createContext<{
  openModal: ({ type }: { type: ModalType }) => void;
  closeModal: () => void;
  isOpen: boolean;
}>({
  openModal: () => {},
  closeModal: () => {},
  isOpen: false,
});

export const useModal = () => useContext(ModalContext);

type Props = {
  isVisible: boolean;
  onClose: () => void;
  type?: ModalType;
};

const Modal = ({ isVisible, onClose, type = 'limit-reached' }: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { reload } = useReload();

  const { openModal } = useModal();

  const [loading, setLoading] = useState(false);
  useLoading(loading);

  const [hasSentLink, setHasSentLink] = useState(false);

  const { data: userData } = useUserQuery(
    {},
    { skip: type !== 'new-user-tokens' }
  );
  // const [updateUser] = useUpdateUserMutation();
  const [subscribe] = useSubscribeMutation();

  const [user] = useUser();
  const { translations, language } = usei18n();

  useEffect(() => {
    if (user == null) return;

    onRedirect();
  }, [user]);

  const AgreeText = (
    <Text color='secondary' fontSize={10}>
      <Text>By continuing you agree to the</Text>{' '}
      <Text color='primary' fontSize={10}>
        Terms of Service
      </Text>{' '}
      <Text>and</Text>{' '}
      <Text color='primary' fontSize={10}>
        Privacy Policy
      </Text>{' '}
      <Text>of</Text> Laila Chat
    </Text>
  );

  const renderContent = () => {
    switch (type) {
      case 'register':
        return (
          <>
            <Text
              textAlign='center'
              fontWeight={550}
              fontSize={26}
              lineHeight={30}
            >
              Register for free to start chatting
            </Text>

            <VStack space={3} px={6}>
              <Button
                py={1}
                rounded='md'
                bg='bg'
                borderWidth={1}
                borderColor='primary'
                w={'100%'}
                onPress={linkWithGoogle}
              >
                <HStack alignItems='center' px={4} space={2}>
                  <GoogleIcon />
                  <Text fontSize={12}>Continue with Google</Text>
                </HStack>
              </Button>

              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View
                  style={{
                    flex: 1,
                    height: 1,
                    backgroundColor: theme.colors.gray,
                  }}
                />
                <View>
                  <Text
                    style={{ width: 30, textAlign: 'center' }}
                    color={theme.colors.gray}
                  >
                    or
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    height: 1,
                    backgroundColor: theme.colors.gray,
                  }}
                />
              </View>
              <Text color='secondary'>Enter your email address</Text>
              <Input
                bg='bg'
                placeholder='somebody@example.com'
                value={email}
                onChangeText={setEmail}
              />
              <Text color='secondary' fontSize={10}>
                We will send you an email with a link to register without a
                password
              </Text>
              {/* <Input
        bg='bg'
        placeholder='Password'
        value={password}
        onChangeText={setPassword}
        type='password'
      /> */}
              <Button
                rounded='md'
                py={1}
                onPress={async () => {
                  await sendPasswordlessEmail({
                    email,
                    language,
                  });

                  setHasSentLink(true);

                  // console.log({result})

                  // if (result == null) return;

                  // if (!result.user.isAnonymous) {
                  //   onClose();
                  // }
                }}
              >
                <Text color='bgSecondary' fontWeight={500}>
                  Sign Up
                </Text>
              </Button>
              {AgreeText}
            </VStack>
          </>
        );
      case 'limit-reached':
        return (
          <VStack space={2}>
            <Text
              color='secondary'
              fontSize={22}
              fontWeight={500}
              textAlign='center'
              mx={4}
            >
              You've reached the limit of free messages
            </Text>
            <Bot size={34} />
            <Text color='secondary' fontSize={16} fontWeight={500}>
              Update your plan to unlock all of the benefits.
            </Text>
            <Button
              rounded='md'
              py={1}
              onPress={() => {
                onClose();
                navigate('/plan');
              }}
            >
              <Text color='bgSecondary' fontWeight={500}>
                Change to Plus
              </Text>
            </Button>
            {AgreeText}
          </VStack>
        );
      case 'cancel-subscription':
        return (
          <VStack space={2}>
            <Text
              color='secondary'
              fontSize={22}
              fontWeight={500}
              textAlign='center'
              mx={4}
            >
              {translations['cancel-subscription']}
            </Text>

            <HStack space={2}>
              <Button
                rounded='md'
                bg='red.500'
                py={1}
                onPress={() => {
                  onClose();
                  setLoading(true);
                  subscribe({
                    cancel: true,
                  }).then(() => {
                    setLoading(false);
                    navigate('/');
                    reload('home');
                  });
                }}
              >
                <Text color='bgSecondary' fontWeight={500}>
                  Yes
                </Text>
              </Button>
              <Button
                rounded='md'
                py={1}
                onPress={() => {
                  onClose();
                  navigate('/plan');
                }}
              >
                <Text color='bgSecondary' fontWeight={500}>
                  No
                </Text>
              </Button>
            </HStack>
          </VStack>
        );
      case 'insufficient-tokens-anon':
        return (
          <VStack space={2} alignItems='center'>
            <Token size={6} />
            <Text
              color='primary'
              fontSize={18}
              fontWeight={600}
              textAlign='center'
              mx={4}
            >
              You have run out of tokens
            </Text>
            <Text
              color='primary'
              fontSize={14}
              fontWeight={500}
              textAlign='center'
              mx={12}
            >
              Receive FREE tokens by creating an account
            </Text>
            <Button
              w='50%'
              rounded='md'
              onPress={() => {
                openModal({
                  type: 'register',
                });
              }}
            >
              <Text color='bg'>Sign Up</Text>
            </Button>
          </VStack>
        );

      case 'insufficient-tokens-user':
        return (
          <VStack space={2} alignItems='center'>
            <Token size={6} />
            <Text
              color='primary'
              fontSize={18}
              fontWeight={600}
              textAlign='center'
              mx={4}
            >
              You have run out of tokens
            </Text>

            <Button
              w='50%'
              rounded='md'
              onPress={() => {
                onClose();
                navigate('/plan');
              }}
            >
              <Text color='bg'>Upgrade</Text>
            </Button>
          </VStack>
        );

      case 'new-user-tokens':
        return (
          <VStack space={2} alignItems='center'>
            <Token size={6} />
            <Text
              color='primary'
              fontSize={18}
              fontWeight={600}
              textAlign='center'
              mx={4}
            >
              Hola, tienes {userData?.tokens ?? 0} tokens
            </Text>

            <Button
              w='50%'
              rounded='md'
              onPress={() => {
                onClose();
              }}
            >
              <Text color='bg'>Accept</Text>
            </Button>
          </VStack>
        );

      default:
        assertNever(type);
    }
  };

  /*
      return (
        <>
          <VStack>
            <Text alignSelf='center' fontSize={30}>
              🤗
            </Text>
            {/*
  }


    <>
      <VStack>
        <Text alignSelf='center' fontSize={30}>
          🤗
        </Text>
        {/* 
  "Register for free to continue chatting": {"es": "Regístrate gratis para seguir conversando"},
  "or":{"es":"o"},
  "Sign Up": {"es": "Regístrate"},
  "Email": {"es": "Correo"},
  "Password": {"es": "Contraseña"},
  "Check your email to complete signup": {"es": "Ver su correo para completar el registro"}
  */

  return (
    <RNModal
      // animationType='slide'
      statusBarTranslucent
      transparent
      visible={isVisible}
      onRequestClose={onClose}
      onDismiss={onClose}
    >
      <VStack
        justifyContent='center'
        alignSelf='center'
        h='100%'
        w={isDesktop() ? '35%' : '100%'}
      >
        <VStack m={6} bg='white' p={6} mx={6} rounded='lg'>
          <Pressable onPress={onClose}>
            <CloseIcon />
          </Pressable>
          <VStack p={4} alignContent='center' space={6}>
            {hasSentLink && type === 'register' ? (
              <Text
                textAlign='center'
                fontWeight={550}
                fontSize={26}
                lineHeight={30}
              >
                Check your email to complete signup
              </Text>
            ) : (
              renderContent()
            )}
          </VStack>
        </VStack>
      </VStack>
    </RNModal>
  );
};

export function ModalProvider({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>('limit-reached');

  return (
    <ModalContext.Provider
      value={{
        isOpen: isModalOpen,
        openModal: ({ type }) => {
          setIsModalOpen(true);
          setModalType(type);
        },
        closeModal: () => setIsModalOpen(false),
      }}
    >
      <View>
        <View
          style={
            isModalOpen
              ? {
                  zIndex: 99999,
                  position: 'absolute',
                  opacity: 0.5,
                  backgroundColor: 'black',
                  width: '100%',
                  height: '100%',
                }
              : {}
          }
        />
        {children}
        <Modal
          isVisible={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          type={modalType}
        />
      </View>
    </ModalContext.Provider>
  );
}

export default Modal;
