import { HStack, Text, VStack } from '../ui';
import { useNavigate } from 'react-router-native';
import { useThreadsQuery } from '../api';
import { Ionicons } from '@expo/vector-icons';
import Search from './SearchOld';
import ThreadOptions from './Options';
import Loading from './Loading';
import { useEffect, useState } from 'react';
import ThreadTitleEditor from './ThreadTitleEditor';
import { Pressable } from 'react-native';
import { CloseIcon, FlatList, Icon } from 'native-base';
import { api } from '../apiTypes';

const ThreadHistory = ({
  activeThreadId,
  pageId,
  showAll = false,
  onClose,
}: {
  activeThreadId: string | null;
  onClose: () => void;
  pageId?: string;
  showAll?: boolean;
}) => {
  const [threads, setThreads] = useState<api['threads']['response']['threads']>(
    []
  );

  const [offset, setOffset] = useState(0);
  const limit = 20;

  const { data: threadsData, refetch: refetchThreads } = useThreadsQuery(
    {
      offset: offset === 0 ? undefined : offset,
      limit: limit === 20 ? undefined : limit,
      pageId,
    },
    { cache: false }
  );

  useEffect(() => {
    if (threads.length === (offset + 1) * limit) {
      return;
    }
    setThreads((t) => [...(t ?? []), ...(threadsData?.threads ?? [])]);
  }, [threadsData]);

  const [renamingThreadId, setRenamingThreadId] = useState<string>();

  const navigate = useNavigate();

  if (threadsData == null) {
    return <Loading />;
  }

  return (
    <VStack bg='bg' space={2} h='full' w='full' p={4}>
      <HStack justifyContent='space-between' alignItems='center'>
        <Text fontSize={'xl'} color='secondary'>
          Chat History
        </Text>
        <Pressable onPress={onClose}>
          <CloseIcon />
        </Pressable>
      </HStack>

      {/* <Search center={false} /> */}

      <FlatList
        initialNumToRender={20}
        onEndReached={() => {
          setOffset((o) => o + limit);
        }}
        data={threads
          .filter((t) =>
            showAll
              ? true
              : pageId == null
              ? t.pageId == null
              : t.pageId === pageId
          )
          .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))}
        renderItem={({ item: thread }) => (
          <Pressable
            style={{
              marginVertical: 1,
            }}
            key={thread.id}
            onPress={() => {
              navigate('/thread/' + thread.id);
              onClose();
            }}
          >
            <HStack alignItems='center' justifyContent='space-between'>
              <Icon
                color={activeThreadId === thread.id ? 'primary' : 'text'}
                size='lg'
                as={<Ionicons name='chatbox-ellipses-outline' />}
              />

              {renamingThreadId === thread.id ? (
                <ThreadTitleEditor
                  thread={thread}
                  onSave={() => {
                    if (pageId) {
                      refetchThreads({ newParams: { pageId } }).then(() =>
                        setRenamingThreadId(undefined)
                      );
                      return;
                    }
                    refetchThreads().then(() => setRenamingThreadId(undefined));
                  }}
                />
              ) : (
                <Text
                  numberOfLines={1}
                  fontSize={'lg'}
                  w='70%'
                  color='secondary'
                >
                  {thread.title ?? 'Unknown'}
                </Text>
              )}

              <ThreadOptions
                thread={thread}
                onChange={refetchThreads}
                onRename={() => setRenamingThreadId(thread.id)}
              />
            </HStack>
          </Pressable>
        )}
      />
    </VStack>
  );
};

export default ThreadHistory;
