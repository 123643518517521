import { auth, signInAnon, signInWithToken } from './firebase';
import { User } from 'firebase/auth';
import { useEffect, useRef } from 'react';
import { Dimensions } from 'react-native';
import { useUpdateUserMutation, useUserQuery } from './api';
import { isWeb } from './utils';
import { usei18n } from './utils/i18n';
import useAuthState from './hooks/useAuthState';
import { useReload } from './reloader';

export const useUser = (): [User | null, boolean, Error] => {
  // if (isWeb) return [null, false, null];
  return useAuthState(auth);
};

export default function useTimeout(callback: () => void, delay: number) {
  const timeoutRef = useRef(null);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      timeoutRef.current = setInterval(tick, delay);
      return () => clearInterval(timeoutRef.current);
    }
  }, [delay]);
  return timeoutRef;
}

export const useAnonUser = () => {
  const [user, userIsLoading] = useUser();
  const {
    data: userData,
    loading: userDataIsLoading,
    refetch: refetchUser,
  } = useUserQuery(
    {},
    isWeb ? { cacheStrategy: 'network-only', retryMs: 300, maxRetries: 90 } : {}
  );

  const { reload } = useReload();

  const [updateUser] = useUpdateUserMutation();

  // const { setLanguage } = usei18n();

  useEffect(() => {
    if (userIsLoading || !isWeb) return;

    // whatsapp login
    const searchParams = new URLSearchParams(window.location.search);

    if (user == null) {
      if (searchParams.has('wa')) {
        const waToken = searchParams.get('wa');

        if (waToken != null) {
          signInWithToken(waToken);
          return;
        }

      }
      signInAnon();
      return;
    }

    if (userData == null) {
      if (userDataIsLoading) {
        return;
      }

      // refetchUser().then(() => {
      //   const locales = l10n.getLocales();
      //   const newLanguage = locales[0].languageCode;
      //   setLanguage('es');
      //   updateUser({ language: 'es' }).then(() => {
      //     setLanguage('es');
      //   });
      // });
      return;
    }

    if (userDataIsLoading) return;

    if (user.uid !== userData.id) {
      refetchUser({ background: false });

      return;
    }

    if (
      user?.emailVerified &&
      user.email !== userData.email &&
      user.email != null
    ) {
      updateUser({
        email: user.email,
        name: user.displayName || userData.name || undefined,
      }).then(() => {
        reload('home');
      });
    } else {
      reload('home');
    }

    // cacheImage(`gs://chat-api-metazooie/promptImages/page_${pageId}_0.png`);
  }, [user?.email, user, userIsLoading, userData]);
};

// export const useOrientation = () => {
//   const [orientation, setOrientation] = useState(null);

//   useEffect(() => {
//     checkOrientation();
//     const subscription = ScreenOrientation.addOrientationChangeListener(
//       handleOrientationChange
//     );
//     return () => {
//       ScreenOrientation.removeOrientationChangeListener(subscription);
//     };
//   }, []);

//   const checkOrientation = async () => {
//     const orientation = await ScreenOrientation.getOrientationAsync();
//     setOrientation(orientation);
//   };

//   const handleOrientationChange = (o) => {
//     console.log(o.orientationInfo.orientation);
//     console.log(Dimensions.get('screen').width, Dimensions.get('window').width);
//     setOrientation(o.orientationInfo.orientation);
//   };

//   return orientation;
// };
