import { useMemo } from 'react';
import {
  useCategoriesQuery,
  useConfigQuery,
  useSearchQuery,
  useThreadsQuery,
} from '../api';
import { usei18n } from '../utils/i18n';
import { useLocation } from '../components/Router';

const _s = (text?: string | null) => {
  return (text || '').replace(/[^ a-zA-Z0-9]/g, '').toLowerCase();
};

const useSearch = ({ searchTerm }: { searchTerm: string }) => {
  // User

  const skip = !searchTerm || searchTerm.length < 3;
  const location = useLocation();

  const pageId = location.pathname.includes('/page/')
    ? location.pathname.split('/page/')[1]
    : undefined;

  // TODO improve search function to include messages
  const { data: searchData } = useSearchQuery({ term: searchTerm }, { skip });

  const { data: threadsData } = useThreadsQuery({}, { skip });
  const { data: configData } = useConfigQuery({}, { skip });
  const { data: categoriesData } = useCategoriesQuery(
    {
      pageId,
    },
    { skip }
  );

  const { i18n } = usei18n();

  const categories = useMemo(
    () =>
      (categoriesData || [])
        .map((c) => ({
          ...c,
          prompts: c.prompts.filter(
            (p) =>
              _s(i18n(p).description).includes(_s(searchTerm)) ||
              _s(i18n(p).label).includes(_s(searchTerm))
          ),
        }))
        .filter((c) => c.prompts.length > 0),
    [categoriesData, searchTerm]
  );

  const publicThreads = useMemo(
    () =>
      (searchData?.threads || []).filter(
        (t) => t.isPublic && (t.pageId === pageId || pageId == null)
      ),
    [searchData, pageId]
  );

  const threads = useMemo(
    () =>
      (threadsData?.threads || []).filter(
        (t) =>
          _s(t.title).includes(_s(searchTerm)) &&
          (t.pageId === pageId || pageId == null)
      ),
    [threadsData, searchTerm, pageId]
  );

  const messages = useMemo(
    () =>
      threads
        .filter((t) => t.pageId === pageId || pageId == null)
        .flatMap((t) => t.messages)
        .filter((m) => _s(m.data).includes(_s(searchTerm))),
    [threads, searchTerm, pageId]
  );

  const pages = useMemo(
    () =>
      pageId != null
        ? []
        : (configData?.pages || []).filter((p) =>
            // _s(i18n(p).description).includes(_s(searchTerm)) ||
            _s(i18n(p).name).includes(_s(searchTerm))
          ),
    [configData, searchTerm, pageId]
  );

  // const pageGroupCategories = (configData?.pageGroupCategories || []).flatMap(
  //   (pgc) =>
  //     pgc.pageGroups.filter((pg) => _s(i18n(pg).label).includes(_s(searchTerm)))
  // );

  if (skip) {
    return {
      publicThreads: [],
      threads: [],
      pages: [],
      messages: [],
      categories: [],
    };
  }

  return {
    publicThreads,
    threads,
    pages,
    messages,
    categories,
  };
};

export default useSearch;
