import { AlertDialog, Button } from 'native-base';
import { useRef } from 'react';
import { useLoading } from './Loading';

const Modal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  body,
}: {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: string;
  onSubmit: () => void;
}) => {
  const cancelRef = useRef(null);
  //   const [predict, { loading: mutationIsLoading }] = usePredictMutation();

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{title}</AlertDialog.Header>
        <AlertDialog.Body>{body}</AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button
              variant='unstyled'
              colorScheme='coolGray'
              onPress={onClose}
              ref={cancelRef}
            >
              No
            </Button>
            <Button
              colorScheme='danger'
              onPress={() => {
                onClose();
                onSubmit();
              }}
            >
              Yes
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default Modal;
