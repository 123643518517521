import { Button, Text, VStack } from '../ui';
import { usei18n } from '../utils/i18n';
import Navtabs from '../components/Navtabs';
import TopNavbar from '../components/TopNavbar';
import { useEffect, useState } from 'react';
import { useUserQuery } from '../api';
import Loading from '../components/Loading';
import { useNavigate } from '../components/Router';
import { useReload } from '../reloader';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { translations } = usei18n();

  const { data: userData, refetch: refetchUser } = useUserQuery(
    {},
    { cache: false }
  );
  const { reload } = useReload();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log({ userData });
    if (userData?.planId == null) {
      setTimeout(() => {
        refetchUser();
      }, 1000);
      return;
    }

    setLoading(false);
  }, [userData]);

  if (loading) return <Loading />;

  return (
    <VStack space={4} h='100%'>
      <TopNavbar hideSearch hideAdd />
      <Navtabs tabs={['Payment']} onChange={() => {}} activeTab={'Payment'} />

      <VStack space={4} p={2} px={3} alignItems='center'>
        <Text fontSize='lg' textAlign='center'>
          {translations['payment.succeeded']}
        </Text>

        <Button
          w='50%'
          onPress={() => {
            reload('home');
            navigate('/');
          }}
        >
          <Text color='bg'>Continue</Text>
        </Button>
      </VStack>
    </VStack>
  );
};

export default PaymentSuccess;
