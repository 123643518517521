import { Linking, Platform } from 'react-native';
import { Product } from 'react-native-iap';
import { Button, Divider, HStack, Stack, Text, VStack } from '../ui';
import { usei18n } from '../utils/i18n';
import { Dimensions, Pressable, ScrollView, View } from 'react-native';
import Navtabs from '../components/Navtabs';
import { api } from '../apiTypes';
import TopNavbar from '../components/TopNavbar';
import { useEffect, useState } from 'react';
import { Feather } from '@expo/vector-icons';
import { Badge, Icon } from 'native-base';
import Bot from '../icons/Bot';
import { useConfigQuery, useSubscribeMutation, useUserQuery } from '../api';
import { useNavigate } from '../components/Router';
import { useModal } from '../components/Modal';
import Loading from '../components/Loading';
import { isDesktop } from '../utils';

type PlanCardProps = {};
const PlanCard = ({
  id,
  price,
  period,
  upgradable,
  product,
  onPress,
  tokens,
  onPayment,
  onCancel,
  selected,
  freeTrial,
  currentPlan,
  details,
  name,
}: {
  id: string;
  price: string;
  name: string;
  product: Pick<Product, 'price' | 'currency'> | null;
  period: string;
  onPress: () => void;
  onPayment: (planId: string) => void;
  onCancel: () => void;
  details: string[];
  selected: boolean;
  tokens: number;
  freeTrial: boolean;
  currentPlan?: string;
  upgradable: boolean;
}) => {
  const smallScreen = Dimensions.get('window').height < 700;

  const { language } = usei18n();

  const priceStr = product?.price;
  const currency = product?.currency;

  return (
    <Pressable onPress={onPress}>
      {id === 'professional' && (
        <Badge
          position='absolute'
          borderColor={selected ? undefined : 'text'}
          bg={selected ? 'primary' : undefined}
          borderWidth={1}
          rounded='full'
          right={12}
          top={1}
          zIndex={99}
        >
          <Text color={selected ? 'bg' : 'text'}>Most Popular</Text>
        </Badge>
      )}
      <VStack
        w='200px'
        h='300px'
        borderColor={selected ? 'rgb(98,102,244)' : 'text'}
        borderWidth={selected ? 2 : 2}
        rounded='lg'
        justifyContent='space-between'
        // my={3}
        // mx={8}
        space={1}
        mt={4}
        //   w='50%'
      >
        <VStack space={0} m={3} mt={6}>
          <Text fontSize={12} fontWeight={500} color='secondary' lineHeight={1}>
            {name}
          </Text>

          <HStack alignItems='center'>
            <Text fontSize={16} fontWeight={600} color='secondary'>
              {currency}
            </Text>
            <Text
              fontSize={16}
              fontWeight={500}
              color='secondary'
            >{`$${priceStr}`}</Text>
            <Text ml={1} fontSize={10} fontWeight={500} color='secondary'>
              each
            </Text>
            <Text ml={0.5} fontSize={10} fontWeight={500} color='secondary'>
              {period}
            </Text>
          </HStack>
          <Divider />
          <VStack mt={4}>
            {details.map((detail, idx) => (
              <HStack key={idx} space={1}>
                {/* <Icon as={<Feather name='check' />} color='secondary' /> */}
                <Text color='secondary' lineHeight={20} fontSize={26}>
                  ∙
                </Text>
                <Text color='secondary'>{detail}</Text>
              </HStack>
            ))}
          </VStack>
        </VStack>

        {currentPlan === id ? (
          <Button
            // w='50%'
            px={6}
            flexShrink={1}
            opacity={id === 'free' ? 0 : 1}
            disabled={id === 'free'}
            rounded='md'
            fontWeight={500}
            alignSelf='center'
            onPress={onCancel}
            my={3}
          >
            <Text color='bg'>Cancel</Text>
          </Button>
        ) : (
          <Button
            // w='50%'
            px={4}
            flexShrink={1}
            rounded='md'
            fontWeight={500}
            opacity={upgradable ? 1 : 0}
            alignSelf='center'
            onPress={() => {
              if (!upgradable) return;
              onPayment(id);
            }}
            my={3}
          >
            <HStack>
              <Text fontSize={12} color='bg'>
                Upgrade to
              </Text>
              <Text fontSize={12} color='bg'>
                {' '}
                {name}
              </Text>
            </HStack>
          </Button>
        )}
      </VStack>
    </Pressable>
  );
};

const Plan = () => {
  const { i18n, translations } = usei18n();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { openModal } = useModal();

  const { data: userData } = useUserQuery();
  const { data: configData } = useConfigQuery({}, { cache: false });

  const [subscribe] = useSubscribeMutation();

  const plans = configData?.plans || [];
  const [selectedPlan, setSelectedPlan] = useState<string>();

  useEffect(() => {
    if (plans.length === 0) return;
  }, [plans]);

  useEffect(() => {
    if (userData == null) return;
    if (userData?.planId != null) {
      setSelectedPlan(userData?.planId);
      return;
    }
    setSelectedPlan('professional');
  }, [userData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <VStack space={4} h='100%'>
      <TopNavbar hideSearch hideAdd />
      <Navtabs tabs={['Plan']} onChange={() => {}} activeTab={'Plan'} />

      <ScrollView horizontal>
        <Stack direction={isDesktop() ? 'row' : 'row'} space={4} m={2}>
          {plans.map((plan, idx) => (
            <PlanCard
              tokens={plan.numTokens ?? 0}
              upgradable={
                idx > plans.findIndex((p) => p.id === userData?.planId)
              }
              id={plan.id}
              key={plan.id}
              price={(i18n(plan).price / 100).toString()}
              period={translations[plan.period] || plan.period}
              name={i18n(plan).name}
              product={{
                price: (plan.price / 100).toString(),
                currency: i18n(plan).currency.toUpperCase(),
              }}
              onPress={() => {
                setSelectedPlan(plan.id);
              }}
              onCancel={() => {
                openModal({
                  type: 'cancel-subscription',
                });
                // subscribe({
                //   cancel: true,
                // });
              }}
              selected={selectedPlan === plan.id}
              details={i18n(plan).details}
              currentPlan={userData?.planId || 'free'}
              freeTrial={false}
              onPayment={async () => {
                setLoading(true);
                const { url } = await subscribe({
                  planId: plan.id,
                });

                if (Platform.OS === 'web') {
                  window.location.href = url;
                }
              }}
            />
          ))}
        </Stack>
      </ScrollView>
      <Text color='secondary' fontSize='xs' mx={4}>
        Al hacer clic, aceptas la{' '}
        <Pressable
          onPress={() => {
            Linking.openURL('https://lailachat.com/utilities/privacy-policy.html');
          }}
        >
          <Text color='blue.500'>Política de Privacidad</Text>
        </Pressable>{' '}
        y{' '}
        <Pressable
          onPress={() => {
            Linking.openURL('https://lailachat.com/utilities/privacy-policy.html');
          }}
        >
          <Text color='blue.500'>Términos de Servicio</Text>
        </Pressable>{' '}
        de Laila Chat, que ofrece acceso inmediato al contenido tras comprar. No
        hay reembolsos por costos de GPU. Cancela cuando quieras.
      </Text>
    </VStack>
  );
};

export default Plan;
