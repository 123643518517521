import { HStack, Icon, Input, Popover, Switch, TextArea } from 'native-base';
import { Text, Select, VStack, Button, Slider, Box } from '../ui';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usei18n } from '../utils/i18n';
import {
  ActivityIndicator,
  Dimensions,
  Pressable,
  ScrollView,
} from 'react-native';
import Dropzone from '../components/Dropzone';
import CachedImage from '../components/CachedImage';
import {
  useCreateThreadMutation,
  useSendMessageMutation,
  useThreadQuery,
  useThreadsQuery,
  useUserQuery,
} from '../api';
import { api } from '../apiTypes';
import { colors } from '../utils/theme';
import {
  AntDesign,
  FontAwesome5,
  Ionicons,
  MaterialIcons,
} from '@expo/vector-icons';
import useTimeout, { useUser } from '../hooks';
import { useLoading } from '../components/Loading';
import { Img2ImgMessage } from '../components/StructuredMessage';
import { useLocation, useNavigate } from '../components/Router';
import { isDesktop } from '../utils';
import Logo from '../components/Logo';
import { useModal } from '../components/Modal';
import { useReload } from '../reloader';
import Tokens from '../components/Tokens';
import Navtabs from '../components/Navtabs';
type ImageInput = {
  type: 'image';
};

type NumberInput = {
  type: 'number';
  minimum: number;
  maximum: number;
  step: number;
  default: number;
};

type BooleanInput = {
  type: 'boolean';
  default: boolean;
};

type DropdownInput = {
  type: 'dropdown';
  options: {
    value: number | string;
    label: string;
    i18n?: { [lang: string]: { label: string } };
  }[];
  default: number | string;
};

type TextInput = {
  type: 'text';
  default: string;
};

type InputBase = {
  id: string;
  label: string;
  i18n?: { [lang: string]: { label: string; description?: string } };
  description?: string;
  default?: any;
};

type InternalInput = {
  id: string;
  internal: true;
  default: any;
  type?: string;
};

type Input =
  | InputBase &
      (NumberInput | DropdownInput | TextInput | ImageInput | BooleanInput);

// const imageSize = isDesktop() ? 512 : 300;
const imageWidth = isDesktop() ? 620 : Dimensions.get('screen').width - 20;
// const imageHeight = isDesktop() ? 496 : 300;

// const inputs: Input[] = [
//   { id: 'image', label: 'Image', type: 'image' },
//   {
//     id: 'scale-factor',
//     label: 'Scale factor',
//     i18n: {
//       es: { label: 'Factor de escala' },
//     },
//     type: 'dropdown',
//     default: '2x',
//     options: [
//       {
//         id: '2x',
//         label: '2 times',
//         i18n: {
//           es: { label: '2 veces' },
//         },
//       },
//     ],
//   },
//   {
//     id: 'prompt',
//     label: 'Prompt',
//     type: 'text',
//     default: '',
//   },
//   {
//     id: 'creativity',
//     label: 'Creativity',
//     type: 'number',
//     min: 0,
//     max: 10,
//     step: 1,
//     default: 5,
//   },
// ];

type BaseType = string | number | boolean;
type BaseInputProps = InputBase & {
  onChange: (value: BaseType) => void;
  value: BaseType;
};

const InputComponents: {
  [key in Input['type']]: React.FunctionComponent;
} = {
  number: ({
    minimum,
    maximum,
    step,
    value,
    onChange,
  }: BaseInputProps & NumberInput) => (
    <Slider
      value={value as number}
      onChange={onChange}
      minimumValue={minimum}
      maximumValue={maximum}
      step={step}
    ></Slider>
  ),
  dropdown: ({ options, value, onChange }: BaseInputProps & DropdownInput) => (
    <Select
      selectedValue={value.toString()}
      onValueChange={(newValue) => {
        onChange(typeof value === 'number' ? parseInt(newValue) : newValue);
      }}
    >
      {options.map((option, idx) => (
        <Select.Item
          key={idx}
          value={option.value.toString()}
          label={option.label}
        />
      ))}
    </Select>
  ),
  text: ({ value, onChange }: BaseInputProps & TextInput) => (
    <TextArea type='text' value={value as string} onChangeText={onChange} />
  ),
  image: ({ value, onChange }: BaseInputProps & ImageInput) => {
    const [loading, setLoading] = useState(false);
    return (
      <VStack space={2}>
        <VStack borderColor='primary' borderStyle='dashed' borderWidth={1}>
          <Dropzone onUpload={onChange} onLoading={setLoading}>
            <VStack m={6}>
              <Text>Click or drop an image ⬆️</Text>
            </VStack>
          </Dropzone>
        </VStack>

        {loading ? (
          <Box
            w={300}
            h={300}
            bg='gray'
            justifyContent='center'
            alignItems='center'
            rounded='lg'
          >
            <ActivityIndicator size='large' />
          </Box>
        ) : value == null ? (
          <></>
        ) : (
          <CachedImage
            rounded='lg'
            h={300}
            w={300}
            resizeMethod='resize'
            //   resizeMode='contain'
            source={{
              uri: value as string,
            }}
          />
        )}
      </VStack>
    );
  },
  boolean: ({ value, onChange }: BaseInputProps & BooleanInput) => (
    <Switch
      height={7}
      onToggle={() => onChange(!value)}
      isChecked={!!value}
      size='sm'
      trackColor={{
        false: isDesktop() ? colors.bg : colors.bgSecondary,
        true: colors.primary,
      }}
    />
  ),
};

const ImageToImage = ({
  page,
}: {
  page?: api['config']['response']['pages'][0];
}) => {
  const { i18n, language } = usei18n();
  const navigate = useNavigate();
  const location = useLocation();

  type Tab = 'create' | 'images';
  const [currentTab, setCurrentTab] = useState<Tab>('create');
  const { search } = location;

  const inputs = page?.model?.inputs || [];
  const defaultOutput = page?.model?.defaultOutput;

  const [threadId, setThreadId] = useState<string>();
  const [hasScrolled, setHasScrolled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [retryingId, setRetryingId] = useState<string>();

  const { reload } = useReload();

  useLoading(loading);

  useEffect(() => {
    if ((search?.length || 0) == 0) return;
    const params = new URLSearchParams(search);

    const threadId = params.get('threadId');
    if (threadId) {
      setThreadId(threadId);
      navigate('.', { replace: true });
    }
  }, [search]);

  useEffect(() => {
    if (threadId == null) return;
    setCurrentTab('images');
  }, [threadId]);

  const scrollRef = useRef<ScrollView>(null);

  const { data: threadData, refetch: refetchThread } = useThreadQuery(
    { id: threadId },
    { skip: threadId == null }
  );

  const { refetch: refetchThreads } = useThreadsQuery({}, { skip: true });
  const [user] = useUser();
  const { data: userData, refetch: refetchUser } = useUserQuery();
  const { openModal } = useModal();
  const messages = threadData?.messages ?? [];
  // const awaitingBotResponse = messages.find((m) => m.isBot && m.data == null);

  const [sendMessage] = useSendMessageMutation({});
  const [createThread] = useCreateThreadMutation({});

  const [inputValues, setInputValues] = useState<Record<string, any>>({});

  useTimeout(() => {
    if (threadId == null) return;

    const messagesLength = messages.length;

    const hasNoMessages = messagesLength === 0;

    const lastMessage =
      messages.length > 0
        ? messages.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          )[messages.length - 1]
        : null;

    const awaitingBotResponse = lastMessage?.isBot && lastMessage?.data == null;

    if (!hasScrolled && awaitingBotResponse) {
      setTimeout(() => {
        scrollRef?.current?.scrollTo({
          y: 0,
          animated: false,
        });

        setHasScrolled(true);
      }, 300);
    }

    const shouldRefetch = hasNoMessages || awaitingBotResponse;

    if (shouldRefetch) {
      refetchThread();
    }
  }, 4000);

  const defaults = useMemo(
    () =>
      inputs.reduce((acc, input) => {
        return {
          ...acc,
          [input.id]: input.default,
        };
      }, {}),
    inputs
  );

  useEffect(() => {
    setInputValues({
      ...defaults,
      //   image:
      //     'gs://chat-api-metazooie/images/ae7c1f8c-93ec-402b-9646-77b42ff325a9.png',
    });
  }, [inputs]);

  const renderedInputs = useMemo(
    () => (
      <>
        {inputs.map((input) => {
          const Input = InputComponents[input.type];
          return (
            <VStack key={input.id} space={2} w='100%' px={4}>
              <HStack mt={1} justifyContent='space-between' alignItems='center'>
                <HStack space={2} alignItems='center'>
                  <Text fontWeight={500} fontSize='lg' color='secondary'>
                    {i18n(input).label}
                  </Text>

                  {input.description && (
                    <Popover
                      trigger={(triggerProps) => {
                        return (
                          <Pressable {...triggerProps}>
                            <Icon
                              size='xs'
                              color='secondary'
                              as={<FontAwesome5 name='question-circle' />}
                            />
                          </Pressable>
                        );
                      }}
                    >
                      <Popover.Content
                        mx={2}
                        // opacity={.9}
                        //   accessibilityLabel='Delete Customerd'
                        //   w='56'
                      >
                        <Popover.Arrow />
                        {/* <Popover.CloseButton mr={-6} /> */}
                        {/* <Popover.Header maxH={0} /> */}

                        <Popover.Body>
                          <Text>{i18n(input).description}</Text>
                        </Popover.Body>
                      </Popover.Content>
                    </Popover>
                  )}
                </HStack>
                {input.type === 'number' && (
                  <Text color='secondary'>{inputValues[input.id]}</Text>
                )}
              </HStack>

              <Input
                {...{
                  key: input.id,
                  value: inputValues[input.id],
                  onChange: (newValue: any) => {
                    setInputValues((inputValues) => ({
                      ...inputValues,
                      [input.id]: newValue,
                    }));
                  },
                  ...input,
                }}
              />
            </VStack>
          );
        })}

        <Button
          mt={2}
          rounded='md'
          // disabled={awaitingBotResponse}
          // opacity={awaitingBotResponse ? 0.5 : 1}
          maxW={300}
          w='80%'
          onPress={async () => {
            setLoading(true);
            let activeThreadId = threadId;
            if (threadId == null) {
              const response = await createThread({
                // title
                message: new Date().toISOString().substring(0, 10),
                files: [],
                pageId: page.id,
                prompts: [],
                language,
              });
              activeThreadId = response?.threadId;

              refetchThreads();
              refetchThreads({ newParams: { pageId: page.id } });

              setThreadId(activeThreadId);
            }

            const newUserData = await refetchUser();

            const tokens = newUserData?.tokens ?? 0;

            const tokensToUse = page?.tokens ?? 0;

            if (tokens < tokensToUse) {
              setLoading(false);
              openModal({
                type: user?.isAnonymous
                  ? 'insufficient-tokens-anon'
                  : 'insufficient-tokens-user',
              });
              return;
            }

            sendMessage({
              threadId: activeThreadId,
              files: [],
              data: JSON.stringify({ inputs: inputValues }),
            })
              .then(() => {
                reload('tokens');
                setCurrentTab('images');
                refetchUser();
                setLoading(false);
                setHasScrolled(false);
                setTimeout(refetchThread, 300);
              })
              .catch(() => {
                setLoading(false);
              });
          }}
        >
          <HStack space={3} alignItems='center'>
            <Text fontWeight={600} fontSize={20} color='bgSecondary'>
              Send
            </Text>
            <Icon color='bgSecondary' size={6} as={<Ionicons name='send' />} />
          </HStack>
        </Button>
        <HStack>
          <Text>Cost</Text>
          <Text>:</Text>
          <Tokens tokens={page?.tokens ?? 0} />
        </HStack>
      </>
    ),
    [inputs, threadId, inputValues]
  );

  const renderedOutput = useMemo(() => {
    const imageInput = inputs.find((i) => i.type === 'image');

    // const imageUri: string =
    //   imageInput == null ? undefined : inputValues[imageInput.id];

    if (messages.length === 0 && defaultOutput != null) {
      return (
        <VStack>
          <Img2ImgMessage
            // size={imageSize}
            width={imageWidth}
            // height={imageHeight}
            data={{
              type: 'image',
              inputs: defaults,
              output: defaultOutput,
            }}
            inputs={inputs}
          />
        </VStack>
      );
    }

    return (
      <VStack space={2}>
        {messages
          .filter((m) => m.isBot)
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
          .map((message) => {
            const _5minutesAgo = +new Date() - 1000 * 60 * 5;

            if (
              new Date(message.createdAt).getTime() < _5minutesAgo &&
              message.data == null &&
              retryingId !== message.id
            ) {
              message.isError = true;
            }

            const botIndex = messages.findIndex((m) => m.id === message.id);

            const previousUserMessage = messages.sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )[botIndex - 1];

            return message.data == null ? (
              <CachedImage
                extend
                isBackground
                rounded='lg'
                // h={imageHeight}
                w={imageWidth}
                // @ts-ignore
                imageStyle={{ borderRadius: 8 }}
                style={{
                  flexGrow: 1,
                  width: imageWidth,
                  // height: imageHeight,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                //   resizeMode='contain'
                source={{
                  uri: JSON.parse(previousUserMessage.data || '{}').inputs[
                    imageInput.id
                  ],
                }}
                blurRadius={3}
              >
                <VStack bg='bgSecondary' p={1} rounded='sm'>
                  {message.isError ? (
                    <HStack space={1} alignItems='center'>
                      <Text color='red.500' fontWeight={500}>
                        Error
                        {message.errorMessage != null
                          ? ` (${message.errorMessage})`
                          : ''}
                      </Text>
                      <MaterialIcons
                        name='refresh'
                        size={24}
                        color='text'
                        onPress={async () => {
                          if (botIndex <= 0) return;

                          setLoading(true);
                          setRetryingId(message.id);
                          setHasScrolled(true);

                          sendMessage({
                            threadId: threadId!,
                            files: [],
                            data: previousUserMessage.data!,
                            retryMessageId: message.id,
                            retry: true,
                          })
                            .then(() => {
                              reload('tokens');
                              refetchUser();
                              setLoading(false);

                              setTimeout(refetchThread, 300);
                            })
                            .catch(() => {
                              setLoading(false);
                            });
                        }}
                      />
                    </HStack>
                  ) : (
                    <ActivityIndicator size={20} />
                  )}
                </VStack>
              </CachedImage>
            ) : (
              <Img2ImgMessage
                // size={imageSize}
                width={imageWidth}
                // height={imageHeight}
                key={message.id}
                data={JSON.parse(message.data || '{}')}
                inputs={inputs}
                onAction={(action) => {
                  const data = JSON.parse(message.data || '{}');
                  switch (action) {
                    case 'reuse-params': {
                      setInputValues({
                        ...inputValues,
                        ...data.inputs,
                      });
                      break;
                    }
                    case 'reuse-original': {
                      if (imageInput == null) return;
                      setInputValues({
                        ...inputValues,
                        [imageInput.id]: data.inputs[imageInput.id],
                      });
                      break;
                    }
                    case 'reuse-upscaled': {
                      if (imageInput == null) return;
                      setInputValues({
                        ...inputValues,
                        [imageInput.id]: data.output,
                      });
                    }
                  }
                }}
              />
            );
          })}
      </VStack>
    );
  }, [messages, inputValues, retryingId]);

  if (Object.keys(inputValues).length === 0) return null;

  if (page == null) {
    return null;
  }

  if (isDesktop()) {
    return (
      <VStack h='full'>
        <HStack h='100%'>
          <VStack
            style={{ position: 'fixed', left: 0, height: '100%', top: 0 }}
            py={4}
            bg='bgSecondary'
          >
            <VStack m={3}>
              <Pressable
                onPress={() => {
                  navigate(-1);
                }}
              >
                <HStack pt={3} space={1} alignItems='center'>
                  <Icon
                    as={<AntDesign name='arrowleft' />}
                    color='primary'
                    size={5}
                  />
                  <Text color='secondary' fontWeight={500} fontSize={14}>
                    Return
                  </Text>
                </HStack>
              </Pressable>

              <VStack alignSelf='center' mt={3}>
                <Logo />
              </VStack>
            </VStack>
            <ScrollView>
              {isDesktop() && (
                <Box ml={2}>
                  <Tokens tokens={userData?.tokens ?? 0} />
                </Box>
              )}
              <VStack
                h='100%'
                //   maxW='250px'
                alignItems='center'
                justifyItems='center'
                space={1}
              >
                {renderedInputs}
              </VStack>
            </ScrollView>
          </VStack>
          <ScrollView ref={scrollRef}>
            <VStack p={3} alignItems='center'>
              {renderedOutput}
            </VStack>
          </ScrollView>
        </HStack>
      </VStack>
    );
  }

  return (
    <VStack h={Dimensions.get('screen').height - 113 - 53}>
      <ScrollView ref={scrollRef}>
        <VStack
          bg='bg'
          w='100%'
          alignItems='center'
          justifyItems='center'
          space={1}
        >
          {(threadData?.messages || []).length > 0 && (
            <Navtabs<Tab>
              tabs={['create', 'images'] as Tab[]}
              activeTab={currentTab}
              onChange={setCurrentTab}
            />
          )}
          {currentTab === 'create' && <>{renderedInputs}</>}
          {currentTab === 'images' && (
            <>
              <VStack mt={4}>{renderedOutput}</VStack>
            </>
          )}
        </VStack>
      </ScrollView>
    </VStack>
  );
};

export default ImageToImage;
