import { Button, VStack, Image, Pressable, HStack } from 'native-base';
import { useAssets } from 'expo-asset';
import { Navigate, useNavigate } from '../components/Router';
import {
  signIn,
  signInAnon,
  signInWithApple,
  signOut,
  useGoogleSignIn,
} from '../firebase';
import { useUser } from '../hooks';
import * as AppleAuthentication from 'expo-apple-authentication';

import { isWeb, randomId } from '../utils';
// @ts-ignore
import logo from '../assets/logo.png';
import { FlatList, Linking, Platform } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useUpdateUserMutation, useUserQuery } from '../api';
import Loading from '../components/Loading';
import { useEffect, useRef, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { usei18n } from '../utils/i18n';
import * as l10n from 'expo-localization';
import { Text } from '../ui';

const Landing = () => {
  const [assets] = useAssets([logo]);
  const [user, loadingUser] = useUser();
  const userLoaded = !loadingUser && user != null;

  const {
    refetch,
    data: userData,
    loading: loadingUserData,
  } = useUserQuery({}, { cache: false, skip: !userLoaded });

  const timeoutRef = useRef<NodeJS.Timeout>();
  const timeoutRef2 = useRef<NodeJS.Timeout>();

  const [counter, setCounter] = useState(0);
  const [hasCompletedTutorial, setHasCompletedTutorial] = useState(true);

  const { request, promptAsync } = useGoogleSignIn();
  const navigate = useNavigate();

  const [updateUser] = useUpdateUserMutation({});

  const {
    translations: i18n,
    setLanguage,
    language,
    loading: i18nLoading,
  } = usei18n();

  useEffect(() => {
    const locales = l10n.getLocales();

    // Only for first login
    AsyncStorage.getItem('tutorial').then((value) => {
      setHasCompletedTutorial(value != null);
      if (value == null) {
        setLanguage(locales[0].languageCode);
      }
    });
  }, []);

  const hasUserData = userData != null;

  useEffect(() => {
    if ((!userLoaded || !hasUserData) && !timeoutRef2.current) {
      timeoutRef2.current = setInterval(() => setCounter((c) => c + 1), 1000);
    }

    if (counter > 30 && user != null) {
      signOut().then(() => {
        setCounter(0);
      });
    }

    if (!hasUserData) {
      if (timeoutRef2.current) {
        clearTimeout(timeoutRef2.current);
      }
      timeoutRef.current = setTimeout(() => {
        refetch();
        setCounter((c) => c + 1);
      }, 2000);
    }

    () => {
      if (!timeoutRef.current) return;
      clearTimeout(timeoutRef.current);
    };
  }, [counter]);

  if (userLoaded && !hasCompletedTutorial && !isWeb) {
    return <Navigate to='/tutorial' />;
  }

  if ((userLoaded && !hasUserData) || i18nLoading) {
    return <Loading />;
  }

  if (userLoaded && hasUserData && !isWeb) {
    if (userData.subscriptionReceipt == null && !hasCompletedTutorial) {
      // return <Navigate to='/pricing?onClose=/' />;
      return <Navigate to='/tutorial' />;
    }
    return <Navigate to='/' />;
  }

  if (isWeb) {
    return (
      <VStack
        maxH='80%'
        w='100%'
        flex={1}
        bg='bg'
        flexDirection='column'
        alignItems='center'
        justifyContent='space-around'
      >
        <VStack alignItems='center'>
          <Image
            alt='Logo image'
            src={assets?.[0]?.uri}
            resizeMode='contain'
            height={'50px'}
            width={`${
              (assets?.[0].width || 0 / assets?.[0].height || 1) * 80
            }px`}
          />
        </VStack>
      </VStack>
    );
  }

  return (
    <VStack
      maxH='80%'
      w='100%'
      flex={1}
      bg='bg'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-around'
    >
      <VStack alignItems='center'>
        <Image
          alt='Logo image'
          src={assets?.[0]?.uri}
          resizeMode='contain'
          height={'50px'}
          width={`${(assets?.[0].width || 0 / assets?.[0].height || 1) * 80}px`}
        />

        <Text textAlign='center' fontWeight={600} mt={4} fontSize='xl'>
          {i18n.landing_header}
        </Text>
        {/* <Text color='white' fontSize='xs'>
            Turn your photos into incredible portraits
          </Text> */}
        <VStack mt={2} h={12} w={40} rounded='full'>
          {Platform.OS === 'ios' && (
            <AppleAuthentication.AppleAuthenticationButton
              buttonType={
                AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN
              }
              buttonStyle={
                AppleAuthentication.AppleAuthenticationButtonStyle.BLACK
              }
              cornerRadius={500}
              style={{ height: '100%', width: '100%' }}
              onPress={async () => {
                const nonce = randomId(30);
                try {
                  const credential = await AppleAuthentication.signInAsync({
                    requestedScopes: [
                      AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                      AppleAuthentication.AppleAuthenticationScope.EMAIL,
                    ],
                  });

                  signInWithApple({
                    idToken: credential.identityToken,
                  });

                  // signed in
                } catch (e) {
                  if (e.code === 'ERR_REQUEST_CANCELED') {
                    // handle that the user canceled the sign-in flow
                  } else {
                    // handle other errors
                  }
                }
              }}
            />
          )}
        </VStack>
        <Button
          mt={4}
          disabled={!request}
          onPress={() => {
            // if (isWeb) {
            //   console.log('sign in3')
            //   signIn();
            // } else {
            // signIn();
            promptAsync();
            // }
          }}
        >
          <Text color='bg'>Sign In with Google</Text>
        </Button>
        <Pressable onPress={signInAnon}>
          <Text mt={4} disabled={!request} fontSize='xs' color='secondary'>
            Continue without account
          </Text>
        </Pressable>
        <HStack space='sm' mt={30}>
          <Pressable
            onPress={() => {
              Linking.openURL(
                'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'
              );
            }}
          >
            <Text mt={4} disabled={!request} fontSize='xs' color='secondary'>
              Terms of Use
            </Text>
          </Pressable>
          <Pressable
            onPress={() => {
              Linking.openURL('https://laila-privacy-policy.web.app');
            }}
          >
            <Text mt={4} disabled={!request} fontSize='xs' color='secondary'>
              Privacy Policy
            </Text>
          </Pressable>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Landing;
