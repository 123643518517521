import {
  Box,
  Divider,
  Heading,
  Icon,
  Input,
  Popover,
  VStack,
  View,
  Button,
  useDisclose,
  ScrollView,
  Pressable,
  CloseIcon,
  HStack,
} from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash/debounce';

import { useCategoriesQuery, usePromptsQuery, useSearchQuery } from '../api';
import Loading, { useLoading } from './Loading';
import ThreadCard from './ThreadCard';
import {
  Animated,
  Dimensions,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import { useNavigate } from './Router';
import { api } from '../apiTypes';
import { usei18n } from '../utils/i18n';
import ChatPromptCard from './ChatPromptCard';

type SearchType = 'thread' | 'prompt';

const SearchResults = ({
  results,
  loading = false,
  center = true,
  type,
  onSelect,
  onEnd,
}: {
  loading?: boolean;
  center?: boolean;
  onSelect?: (item: Record<string, any>) => void;
  onEnd: () => void;
} & (
  | {
      type: 'thread';
      results: api['db']['Thread'][];
    }
  | {
      type: 'prompt';
      results: api['categories']['response'][number]['prompts'];
    }
)) => {
  const { isOpen, onClose, onOpen } = useDisclose(true);

  const [contentWidth, setContentwWidth] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOpen) onOpen();
  }, [results]);

  return (
    <>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        trigger={(triggerProps) => (
          <Button h={0} w={0} opacity={0} {...triggerProps}></Button>
        )}
      >
        <Popover.Content
          // borderStyle="none"
          borderWidth={0}
          //  top={6}
          left={
            center ? Dimensions.get('window').width / 2 - contentWidth / 2 : '4'
          }
          onLayout={(event) => {
            var { x, y, width, height } = event.nativeEvent.layout;
            setContentwWidth(width);
          }}
        >
          <Popover.CloseButton onPress={onClose} top={0} right={1} />
          {/* <Popover.Header>Delete Customer</Popover.Header> */}
          <Popover.Body bg='bgSecondary'>
            <ScrollView
              mt={4}
              minH={Dimensions.get('window').height * 0.2}
              maxH={Dimensions.get('window').height * 0.6}
              width={Dimensions.get('window').width * 0.7}
            >
              <VStack space='md'>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    {(results || []).map((t, idx) => (
                      <Pressable
                        key={idx}
                        onPress={
                          onSelect != null
                            ? () => {
                                onClose();
                                onEnd();
                                setTimeout(() => onSelect(t), 0);
                              }
                            : () => navigate('/thread/' + t.id)
                        }
                      >
                        <View
                          key={t.id}
                          position={'relative'}
                          zIndex={999}
                          bg='bg'
                          borderColor='text'
                          borderWidth={type === 'prompt' ? 0 : 0.5}
                          shadow={type === 'prompt' ? 'none' : 2}
                          rounded='xl'
                          pointerEvents='none'
                        >
                          {type === 'prompt' ? (
                            <ChatPromptCard prompt={t} selected={false} />
                          ) : (
                            <ThreadCard
                              thread={t}
                              canInteract={false}
                              prompt={null}
                            />
                          )}
                        </View>
                      </Pressable>
                    ))}
                  </>
                )}
              </VStack>
            </ScrollView>
          </Popover.Body>
          {/* <Popover.Footer justifyContent='flex-end'></Popover.Footer> */}
        </Popover.Content>
      </Popover>
      {/* <Popover /> */}
    </>
  );
};

type Props = {
  placeholder?: string;
  center?: boolean;
  onSelect?: (item: any) => void;
  type?: SearchType;
  onSearch?: (term: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onEndSearch?: () => void;
  expandable?: boolean;
  withResults?: boolean;
};

export type SearchRef = {
  focusInput: () => void;
  clearInput: () => void;
};

const Search = forwardRef<SearchRef, React.PropsWithChildren<Props>>(
  (
    {
      placeholder = 'Search conversations',
      center = true,
      type = 'thread',
      onSelect,
      onSearch,
      onFocus,
      onBlur,
      onEndSearch,
      expandable = false,
      withResults = true,
    }: Props,
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      focusInput() {
        inputRef?.current?.focus();
      },
      clearInput() {
        setInputText('');
        setSearchTerm('');
      },
    }));

    const [inputText, setInputText] = useState('');

    const [searchTerm, setSearchTerm] = useState('');

    const [shouldShowInput, setShouldShowInput] = useState(!expandable);

    const { translations, i18n } = usei18n();
    const inputRef = useRef<TextInput>(null);

    const { data: threadSearchData, loading: searchResultLoading } =
      useSearchQuery(
        { term: searchTerm },
        { skip: searchTerm.length < 3 || type !== 'thread' || !withResults }
      );

    const { data: categoriesData } = useCategoriesQuery(
      {},
      { skip: searchTerm.length < 3 || type !== 'prompt' || !withResults }
    );

    const prompts = (categoriesData ?? [])
      .flatMap((c) => c.prompts)
      .filter((p) => {
        const label = i18n(p).label;

        return label.toLowerCase().includes(searchTerm.toLowerCase());
      });

    const searchData = type === 'thread' ? threadSearchData?.threads : prompts;

    //   useLoading(searchResultLoading);

    const debouncedSearch = useMemo(
      () =>
        debounce((term) => {
          setSearchTerm(term);
          onSearch?.(term);
        }, 300),
      []
    );

    useEffect(() => debouncedSearch.cancel, []);

    const { width } = Dimensions.get('window');
    const PADDING = 32;
    const SEARCH_FULL_WIDTH = width - PADDING * 2;
    const SEARCH_SHRINK_WIDTH = 33;

    const [animationState, setAnimationState] = useState({
      inputLength: new Animated.Value(SEARCH_SHRINK_WIDTH),
      cancelPosition: new Animated.Value(0),
      opacity: new Animated.Value(0),
      searchBarFocused: false,
    });

    const onFocusAnimate = () => {
      setShouldShowInput(true);
      Animated.parallel([
        Animated.timing(animationState.inputLength, {
          toValue: SEARCH_FULL_WIDTH,
          duration: 200,
          useNativeDriver: false,
        }),
        // Animated.timing(animationState.cancelPosition, {
        //   toValue: 16,
        //   duration: 400,
        //   useNativeDriver: false,
        // }),
        // Animated.timing(animationState.opacity, {
        //   toValue: 1,
        //   duration: 250,
        //   useNativeDriver: false,
        // }),
      ]).start();
    };

    const onBlurAnimate = () => {
      Animated.parallel([
        Animated.timing(animationState.inputLength, {
          toValue: SEARCH_SHRINK_WIDTH,
          duration: 0,
          useNativeDriver: false,
        }),
        // Animated.timing(animationState.cancelPosition, {
        //   toValue: 0,
        //   duration: 250,
        //   useNativeDriver: false,
        // }),
        // Animated.timing(animationState.opacity, {
        //   toValue: 0,
        //   duration: 250,
        //   useNativeDriver: false,
        // }),
      ]).start();
    };

    const AnimatedTouchable =
      Animated.createAnimatedComponent(TouchableOpacity);

    const styles = {
      searchContainer: {
        flexDirection: 'row',
        height: 72,
        borderBottomColor: '#00000033',
        paddingTop: 100,
      },
      search: {
        flex: 1,
        flexDirection: 'row',
        height: 40,
        borderRadius: 6,
        backgroundColor: 'red',
      },
      cancelSearch: {
        position: 'absolute',
        marginHorizontal: 16,
        textAlign: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
      },
    };

    // return (
    //   <View style={[styles.searchContainer]}>
    //     <Animated.View
    //       style={[
    //         {
    //           width: animationState.inputLength,
    //           position: 'absolute',
    //           left: 16,
    //           alignSelf: 'center',
    //         },
    //         true ? undefined : { justifyContent: 'center' },
    //       ]}
    //     >
    //       <TextInput
    //         style={styles.searchInput}
    //         onBlur={onBlurAnimate}
    //         onFocus={onFocusAnimate}
    //         placeholder='Type something'
    //       />
    //     </Animated.View>

    //     <AnimatedTouchable
    //       style={[styles.cancelSearch, { right: animationState.cancelPosition }]}
    //       onPress={() => null}
    //     >
    //       <Animated.Text
    //         style={[
    //           styles.cancelSearchText,
    //           { color: 'black', opacity: animationState.opacity },
    //         ]}
    //       >
    //         Cancel
    //       </Animated.Text>
    //     </AnimatedTouchable>
    //   </View>
    // );

    return (
      <VStack
        //   my='4'
        w='100%'
        // maxW='300px'
        //   divider={
        //     <Box px='2'>
        //       <Divider />
        //     </Box>
        //   }
      >
        <VStack
          w='100%'
          space={5}
          alignSelf='center'
          // bg='bg'
        >
          {true ? (
            <Animated.View
              style={
                expandable
                  ? [
                      {
                        width: animationState.inputLength,
                        // position: 'absolute',
                        // left: 16,
                        right: PADDING,
                        alignSelf: 'flex-end',
                      },
                    ]
                  : []
              }
            >
              <Pressable
                onPress={() => {
                  onFocusAnimate();
                  inputRef?.current?.focus();
                }}
              >
                <Input
                  ref={inputRef}
                  placeholder={translations[placeholder] || placeholder}
                  //   variant='filled'
                  variant='unstyled'
                  px={shouldShowInput ? undefined : 0}
                  width='100%'
                  fontSize={13}
                  h={8}
                  borderColor='text'
                  onFocus={() => {
                    // onFocusAnimate();
                    onFocus?.();
                  }}
                  onBlur={onBlur}
                  borderWidth={1}
                  borderRadius='10'
                  placeholderTextColor='text'
                  color='text'
                  rounded='full'
                  // py='1'
                  // px='2'
                  value={inputText}
                  onChangeText={(val) => {
                    setInputText(val);
                    debouncedSearch(val);
                  }}
                  InputRightElement={
                    inputText.length > 0 ? (
                      <Icon
                        ml='2'
                        onPress={() => {
                          setInputText('');
                          setSearchTerm('');
                          onSearch?.('');
                          inputRef.current?.blur();
                          onBlur?.();
                          onEndSearch?.();
                          if (expandable) {
                            setShouldShowInput(false);
                            onBlurAnimate();
                          }
                        }}
                        size='5'
                        mr={2}
                        color='text'
                        as={<Ionicons name='close-circle' />}
                      />
                    ) : (
                      <Icon
                        ml='2'
                        size='4'
                        mr={3}
                        color='text'
                        as={<Ionicons name='ios-search' />}
                      />
                    )
                  }
                />
              </Pressable>
            </Animated.View>
          ) : (
            <Pressable onPress={() => setShouldShowInput(true)}>
              <VStack alignItems='flex-end'>
                <Box bg='secondary' mt={1} rounded='full' w={6} p={1}>
                  <Icon
                    size='4'
                    color='text'
                    as={<Ionicons name='ios-search' />}
                  />
                </Box>
              </VStack>
            </Pressable>
          )}
        </VStack>

        {withResults && searchTerm.length >= 3 && (
          <SearchResults
            loading={searchResultLoading}
            results={searchData || []}
            center={center}
            type={type}
            onSelect={onSelect}
            onEnd={() => {
              setInputText('');
              setSearchTerm('');
            }}
          />
        )}
      </VStack>
    );

    return (
      <VStack
        //   my='4'
        w='100%'
        // maxW='300px'
        //   divider={
        //     <Box px='2'>
        //       <Divider />
        //     </Box>
        //   }
      >
        <VStack
          w='100%'
          space={5}
          alignSelf='center'
          // bg='bg'
        >
          {shouldShowInput ? (
            <Animated.View>
              <Input
                ref={inputRef}
                placeholder={translations[placeholder] || placeholder}
                //   variant='filled'
                variant='unstyled'
                width='100%'
                fontSize={13}
                h={8}
                borderColor='text'
                onFocus={onFocus}
                onBlur={onBlur}
                borderWidth={1}
                borderRadius='10'
                placeholderTextColor='text'
                color='text'
                rounded='full'
                py='1'
                px='2'
                value={inputText}
                onChangeText={(val) => {
                  setInputText(val);
                  debouncedSearch(val);
                }}
                InputRightElement={
                  inputText.length > 0 ? (
                    <Icon
                      ml='2'
                      onPress={() => {
                        setInputText('');
                        setSearchTerm('');
                        onSearch?.('');
                        inputRef.current?.blur();
                        onBlur?.();
                        if (expandable) {
                          setShouldShowInput(false);
                        }
                      }}
                      size='5'
                      mr={2}
                      color='text'
                      as={<Ionicons name='close-circle' />}
                    />
                  ) : (
                    <Icon
                      ml='2'
                      size='4'
                      mr={2}
                      color='text'
                      as={<Ionicons name='ios-search' />}
                    />
                  )
                }
              />
            </Animated.View>
          ) : (
            <Pressable onPress={() => setShouldShowInput(true)}>
              <VStack alignItems='flex-end'>
                <Box bg='secondary' mt={1} rounded='full' w={6} p={1}>
                  <Icon
                    size='4'
                    color='text'
                    as={<Ionicons name='ios-search' />}
                  />
                </Box>
              </VStack>
            </Pressable>
          )}
        </VStack>

        {withResults && searchTerm.length >= 3 && (
          <SearchResults
            loading={searchResultLoading}
            results={searchData || []}
            center={center}
            type={type}
            onSelect={onSelect}
            onEnd={() => {
              setInputText('');
              setSearchTerm('');
            }}
          />
        )}
      </VStack>
    );
  }
);

export default Search;
