import { Pressable } from 'react-native';
import { HStack, Text, VStack } from '../ui';
import { api } from '../apiTypes';
import { usei18n } from '../utils/i18n';
import CachedImage from './CachedImage';
import { useNavigate } from './Router';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { nanoid } from 'nanoid';

const ChatPromptCard = ({
  prompt,
  onPress,
  onLongPress,
  selected,
  fontSize = 14,
  imageSize = 100,
  removable = false,
  showImage = true,
}: {
  prompt: api['categories']['response'][number]['prompts'][number];
  selected: boolean;
  onPress?: () => void;
  onLongPress?: () => void;
  removable?: boolean;
  fontSize?: number;
  showImage?: boolean;
  imageSize?: number;
}) => {
  const { i18n } = usei18n();
  const navigate = useNavigate();

  if (prompt == null) return null;

  if (['image', 'page'].includes(prompt?.type || '') && showImage) {
    const isPage = prompt.type === 'page';
    return (
      <Pressable
        onPress={
          isPage
            ? () => {
                navigate(`/page/test_page`, {
                  state: nanoid(),
                });
                // navigate(`/page/${prompt.id.split('page_')[1]}`, {
                //   state: nanoid(),
                // });
                AsyncStorage.setItem(
                  'activePageId',
                  prompt.id.split('page_')[1]
                );
              }
            : onPress
        }
        onLongPress={onLongPress}
      >
        <VStack alignItems='center' maxWidth={'100px'}>
          <CachedImage
            size={imageSize}
            // size={size}
            rounded='lg'
            source={{
              uri: `gs://chat-api-metazooie/promptImages/${prompt.id}_0.png`,
            }}
            // rounded='full'
            // size={6}
          />
          <Text
            numberOfLines={1}
            translate={false}
            fontWeight={selected ? 700 : undefined}
            fontSize={fontSize}
            color={selected ? 'primary' : 'text'}
          >
            {i18n(prompt).label}
          </Text>
        </VStack>
      </Pressable>
    );
  }

  return (
    <Pressable onPress={onPress} onLongPress={onLongPress}>
      <HStack
        borderRadius='full'
        // rounded='full'
        bg={selected ? 'primary' : 'secondary'}
        justifyContent={'space-between'}
        // borderColor={'rgb(50,49,103)'}
        // borderWidth={1}
        // px={}
        px={2}
        py={2}
        space={0}
      >
        <Text
          fontSize={fontSize}
          color='bg'
          mx={3}
          numberOfLines={1}
          translate={false}
        >
          {i18n(prompt).label}
        </Text>
        {onPress && (
          <Text
            color='bg'
            // mx={1}
            mr={2}
            fontSize={fontSize}
            // fontSize='lg'
          >
            {selected ? (removable ? 'x' : '✓') : '+'}
          </Text>
        )}
      </HStack>
    </Pressable>
  );
};
export default ChatPromptCard;
