import React from 'react';
import PropTypes from 'prop-types';
import { Image, Linking, View } from 'react-native';
import { Link, Pressable } from 'native-base';
import { useAssets } from 'expo-asset';
import { G, Path, Svg } from 'react-native-svg';

const imageLinks = {
  ios: 'https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg',
  android:
    'https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg',
};

const MobileStoreButton = () => {
  // const store = 'ios';
  const url = 'https://apps.apple.com/us/app/id1673623609';

  const [assets] = useAssets([require('../assets/app-store-badge.png')]);

  const h = assets?.[0].height ?? 0;
  const w = assets?.[0].width ?? 1;

  const width = 150;

  return (
    <Pressable onPress={() => Linking.openURL(url)}>
      <Image
        style={{ width, height: (h / w) * width }}
        source={{ uri: assets?.[0].uri }}
      />
    </Pressable>
  );
};

export default MobileStoreButton;
