import { useState } from 'react';
import { Text, HStack, VStack, Box, Button } from '../ui';
import { useConfig } from '../utils/i18n';
import { colors as themeColors } from '../utils/theme';
import ColorPicker from 'react-native-wheel-color-picker';
import { Pressable, View } from 'react-native';
import { Input } from 'native-base';

const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return `rgb(${r}, ${g}, ${b})`;
};

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

const rgbToHex = (rgb: string) => {
  const [r, g, b] = rgb
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map((c) => parseInt(c));
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

const invertColor = (rgb: string) => {
  const [r, g, b] = rgb
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map((c) => parseInt(c));
  // invert color components
  let r2 = 255 - r;
  let g2 = 255 - g;
  let b2 = 255 - b;
  // pad each with zeros and return
  const color = `rgb(${r2},${g2},${b2})`;
  return color;
};

const AdminConfig = () => {
  const { config } = useConfig();
  const [colors, setColors] = useState<typeof themeColors>(themeColors);

  const [activeColor, setActiveColor] = useState<string>();

  const colorNames = ['bg', 'bgSecondary', 'primary', 'secondary', 'text'];

  const rgbValue: [number, number, number] = activeColor
    ? colors[activeColor]
        .replace('rgb(', '')
        .replace(')', '')
        .split(',')
        .map((c) => parseInt(c))
    : [0, 0, 0];

  return (
    <VStack p={4} bg={colors.bg} h='100%' w='100%'>
      {colorNames.map((color, idx) => (
        <HStack
          key={idx}
          alignItems='center'
          space={2}
          bg={invertColor(colors['bg'])}
        >
          <Pressable
            onPress={() => {
              setActiveColor(color);
            }}
          >
            <Box bg={colors[color]} w={8} h={8} rounded='full' />
          </Pressable>
          <Text color={colors['bg']} translate={false}>
            {color}
          </Text>
        </HStack>
      ))}
      {activeColor && (
        <VStack>
          <View style={{ position: 'relative' }}>
            <ColorPicker
              color={rgbToHex(colors[activeColor])}
              onColorChange={(value) =>
                setColors({ ...colors, [activeColor]: hexToRgb(value) })
              }
            />
          </View>
          <Button w='50%' alignSelf='center' bg={colors.primary}>
            <Text color={colors.bg}>Save</Text>
          </Button>
          <Button w='50%' alignSelf='center' bg={colors.primary} onPress={() => {
            setColors(themeColors)
            setActiveColor(undefined)
          }}>
            <Text color={colors.bg}>Reset</Text>
          </Button>
          <HStack>
            {rgbValue.map((c, idx) => (
              <Input
                w='20'
                value={c.toString()}
                onChangeText={(value) => {
                  setColors({
                    ...colors,
                    [activeColor]: `rgb(${rgbValue.map((v, i) =>
                      i === idx ? value || 0 : v
                    )})`,
                  });
                }}
                bg='text'
              />
            ))}
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};

export default AdminConfig;
