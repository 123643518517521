import { useEffect, useState } from 'react';
import { linkPasswordlessLogin, storedPasswordlessEmail } from '../firebase';
import { useNavigate } from '../components/Router';
import { Button, Text, VStack } from '../ui';
import { Input } from 'native-base';
import Logo from '../components/Logo';
import { Dimensions } from 'react-native';
import { FirebaseError } from 'firebase/app';
import { usei18n } from '../utils/i18n';
import { isDesktop } from '../utils';
import { useUpdateUserMutation } from '../api';

const Auth = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [hasEmail, setHasEmail] = useState(true);

  const [errCode, setErrCode] = useState<string>();

  const { setLanguage } = usei18n();

  const [updateUser] = useUpdateUserMutation();

  const onLink = async (email: string) => {
    if (errCode != null) setErrCode(undefined);

    const cred = await linkPasswordlessLogin({ email }).catch(
      (err: FirebaseError) => {
        console.log({ err });
        if (err.code === 'auth/invalid-email') {
          setErrCode(err.code);

          // "auth/invalid-email": {
          //   "en": "The email provided does not match the sign-in email address.",
          //   "es": "El correo no coincide con el correo registrado."
          // }, "Confirm Email" : {"es":"Confirmar Correo"}
        } else {
          setErrCode('error');
        }
        return null;
      }
    );

    const search = new URLSearchParams(window.location.search);
    const continueUrl = search.get('continueUrl');

    if (cred?.user.emailVerified && continueUrl != null) {
      // if (cred.user.email) {
      //   updateUser({
      //     email: cred.user.email,
      //   });
      // }

      const url = new URL(continueUrl);
      navigate(url.pathname);
    }
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    const lang = search.get('lang');

    if (lang != null) {
      setLanguage(lang as 'es');
    }
    storedPasswordlessEmail().then((storedEmail) => {
      console.log({ storedEmail });
      if (storedEmail == null) {
        setHasEmail(false);
        return;
      }

      onLink(storedEmail);
    });
  }, []);

  if (!hasEmail)
    return (
      <VStack
        space={4}
        w={isDesktop() ? '25%' : undefined}
        h={isDesktop() ? '70%' : '100%'}
        alignSelf='center'
        justifyContent='center'
      >
        <VStack alignSelf='center' space={2} alignItems='center'>
          <Logo />
          <Text textAlign='center' fontSize={20} fontWeight={550}>
            Confirm Email
          </Text>
        </VStack>
        <Input
          bg='bg'
          placeholder='Email'
          value={email}
          onChangeText={setEmail}
        />

        <Button rounded='md' py={1} onPress={async () => onLink(email)}>
          <Text color='bgSecondary'>Continue</Text>
        </Button>
        {errCode && <Text color='red.500'>{errCode}</Text>}
      </VStack>
    );

  return (
    <VStack
      space={4}
      w={isDesktop() ? '25%' : undefined}
      h={isDesktop() ? '70%' : '100%'}
      alignSelf='center'
      justifyContent='center'
    ></VStack>
  );
};

export default Auth;
