// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { useAssets } from 'expo-asset';
// import { Image, Pressable, View } from 'native-base';
// import { useEffect, useState } from 'react';
// import { Linking } from 'react-native';
import { useNavigate } from './Router';
import { View } from 'native-base';
import { Pressable } from 'react-native';
import { Defs, G, Path, Svg } from 'react-native-svg';

type Props = {
  size?: number;
};

// const LogoOld = ({ size = 26 }: Props) => {
//   const [numClicks, setNumClicks] = useState(0);
//   const navigate = useNavigate();

//   const [assets] = useAssets([require('../assets/logo.png')]);

//   useEffect(() => {
//     if (numClicks >= 5) {
//       setNumClicks(0);
//       AsyncStorage.clear();
//     }
//   }, [numClicks]);

//   if (assets?.[0] == null) {
//     return null;
//   }

//   const logo = assets[0];

//   const widthToHeightRatio = logo?.width || 0 / (logo?.height || 1);

//   const height = size;

//   return (
//     <Pressable
//       onPress={() => {
//         setNumClicks((clicks) => clicks + 1);
//         navigate('/');
//       }}
//     >
//       <Image
//         alt='Logo'
//         data-name='Laila Logo'
//         style={{
//           width: height * widthToHeightRatio,
//           height,
//         }}
//         src={assets[0].uri}
//       />
//     </Pressable>
//   );
// };

const Logo = ({ size = 100 }: Props) => {
  const navigate = useNavigate();

  return (
    <Pressable
      onPress={() => {
        navigate('/');
      }}
    >
      <View width={size} height={size/3.97} flexShrink={1}>
        <Svg id='Layer_2' data-name='Layer 2' viewBox='0 0 1089.64 273.81'>
          <Defs></Defs>
          <G id='TEXTS'>
            <G>
              <Path
                fill='#5f6cb2'
                d='M380.63,6.73V266.36c0,1.78-1.18,3.23-2.63,3.23h-48.64c-1.46,0-2.63-1.45-2.63-3.23V6.73c0-1.79,1.18-3.23,2.63-3.23h48.64c1.45,0,2.63,1.44,2.63,3.23Z'
              />
              <Path
                fill='#5f6cb2'
                d='M736.28,81.56v186.16c0,1.7-1.18,3.07-2.63,3.07h-46.01c-1.46,0-2.63-1.37-2.63-3.07V81.56c0-1.69,1.18-3.07,2.63-3.07h46.01c1.45,0,2.63,1.37,2.63,3.07Zm2.51-52.51c0,15.54-12.6,28.14-28.14,28.14s-28.14-12.6-28.14-28.14,12.6-28.14,28.14-28.14,28.14,12.6,28.14,28.14Z'
              />
              <Path
                fill='#5f6cb2'
                d='M840.64,4.17V266.33c0,1.8-1.18,3.26-2.63,3.26h-48.64c-1.46,0-2.63-1.47-2.63-3.26V4.17c0-1.81,1.18-3.26,2.63-3.26h48.64c1.45,0,2.63,1.46,2.63,3.26Z'
              />
              <Path
                fill='#5f6cb2'
                d='M1039.26,172.76c0-29.99-21.54-54.38-52.57-54.38s-56.26,24.39-56.26,54.38,25.24,54.37,56.26,54.37,52.57-24.39,52.57-54.37Zm49.39-93.34l1,189.42c0,1.63-.98,2.71-2.61,2.71l-52.4,.14c-1.63,0-1.73-.49-1.73-2.13l.31-10.12c0-1.14-1.14-2.41-2.14-1.85-14.83,8.39-32.9,16.23-50.09,16.23-54.22,0-98.32-43.5-98.32-96.97s44.11-96.97,98.32-96.97c17.19,0,33.96,4.41,48.79,12.79,.99,.56,6.9,5.53,6.9,4.39l-.93-18.73c0-1.63,1.5-3.32,3.13-3.32h47.71c1.64,0,2.06,2.77,2.06,4.4Z'
              />
              <Path
                fill='#5f6cb2'
                d='M578.72,171.1c0-29.99-21.54-54.38-52.57-54.38s-56.26,24.39-56.26,54.38,25.24,54.37,56.26,54.37,52.57-24.39,52.57-54.37Zm49.39-93.34l1,189.42c0,1.63-.98,2.71-2.61,2.71l-52.4,.14c-1.63,0-1.73-.49-1.73-2.13l.31-10.12c0-1.14-1.14-2.41-2.14-1.85-14.83,8.39-32.9,16.23-50.09,16.23-54.22,0-98.32-43.5-98.32-96.97s44.11-96.97,98.32-96.97c17.19,0,33.96,4.41,48.79,12.79,.99,.56,6.9,5.53,6.9,4.39l-.93-18.73c0-1.63,1.5-3.32,3.13-3.32h47.71c1.64,0,2.06,2.77,2.06,4.4Z'
              />
            </G>
            <G>
              <Path
                fill='#5f6cb2'
                d='M191.71,257.39c-16.15,15.19-42.81,17.17-64.67,5.98-17.62-9.01-28.5-30.36-25.52-50.09,1.84-12.2,10.84-22.42,20.93-23.76,9.82-1.31,19.68,5.68,24.72,17.74,1.44,3.46,2.52,7.09,3.51,10.71,4.57,16.8,15.13,28.21,31.08,34.87,3,1.25,5.91,2.69,9.95,4.55Z'
              />
              <Path
                fill='#5f6cb2'
                d='M263.38,179.86c.17,1.14-1.17,5.84-1.48,7.12-5.56,22.9-20.35,37.23-42.39,44.2-20.23,6.4-37.06-.37-51.06-15.11-9.62-10.13-11.12-23.72-4.46-33.06,6.41-8.99,19.51-12.33,31.6-4.9,20.78,12.78,41.28,14.92,63.14,4.12,.47-.23,3.71-2.31,4.65-2.38Z'
              />
              <Path
                fill='#5f6cb2'
                d='M13.71,192.32c-16.42-13.36-17.88-46.49-5.48-67.47,10.37-17.53,33.88-27.18,53.79-21.64,9.93,2.76,16.56,9.25,18.44,19.27,1.83,9.73-4.09,16.33-11.42,21.62-2.41,1.73-5.17,3.42-8.01,4.02-24.12,5.12-40.51,18.78-47.32,44.2Z'
              />
              <Path
                fill='#5f6cb2'
                d='M88.94,261.76c-22.68-.78-43.12-18.82-50.46-42.65-5.75-18.67,2.25-41.38,18.62-52.88,10.33-7.26,23.49-7.54,31.47-.67,8.04,6.92,9.83,19.84,2.63,32.16-9.84,16.83-14.25,33.78-6.55,52.55,1.38,3.37,2.55,6.83,4.28,11.5Z'
              />
              <Path
                fill='#5f6cb2'
                d='M8.38,88.03c.99-21.38,18.08-40.95,41.75-48.61,18.43-5.96,40.12,.85,52.62,16.52,8.28,10.38,9.3,23.66,2.48,32.29-6.6,8.34-18.26,9.43-31.06,4.46-12.03-4.67-24.38-9.9-36.98-11.25-9.01-.97-18.67,4.05-28.81,6.6Z'
              />
              <Path
                fill='#5f6cb2'
                d='M78.7,12.24c15.82-14.42,41.74-16.18,63.89-4.85,17.24,8.82,27.76,28.97,25.53,48.89-1.48,13.2-10.15,23.31-21.08,24.59-10.56,1.23-19.58-6.25-25.11-18.81-5.2-11.81-10.24-24.24-18.19-34.11-5.69-7.06-16.07-10.33-25.04-15.71Z'
              />
              <Path
                fill='#5f6cb2'
                d='M182.5,8.92c21.38,.99,40.95,18.08,48.61,41.75,5.96,18.43-.85,40.12-16.52,52.62-10.38,8.28-23.66,9.3-32.29,2.48-8.34-6.6-9.43-18.26-4.46-31.06,4.67-12.03,9.9-24.38,11.25-36.98,.97-9.01-4.05-18.67-6.6-28.81Z'
              />
              <Path
                fill='#5f6cb2'
                d='M258.1,78.44c14.42,15.82,16.18,41.74,4.85,63.89-8.82,17.24-28.97,27.76-48.89,25.53-13.2-1.48-23.31-10.15-24.59-21.08-1.23-10.56,6.25-19.58,18.81-25.11,11.81-5.2,24.24-10.24,34.11-18.19,7.06-5.69,10.33-16.07,15.71-25.04Z'
              />
            </G>
          </G>
        </Svg>
      </View>
    </Pressable>
  );
};

export default Logo;
