import {
  Box as NBox,
  HStack as NHStack,
  VStack as NVStack,
  Text as NBText,
  Button as NButton,
  Stack as NBStack,
  Select as NBSelect,
} from 'native-base';
import { usei18n } from '../utils/i18n';
import { StyleSheet, View } from 'react-native';
import { colors } from '../utils/theme';
import { fitFontSize } from '../utils';
import { Slider as RNSlider } from '@miblanchard/react-native-slider';

export const Select = (props) => {
  const isSafari = () =>
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);

  return <NBSelect {...props} selection={isSafari() ? 1 : null} />;
};

Select.Item = NBSelect.Item;

export const Box = NBox;

export const HStack = NHStack;

export const VStack = NVStack;

export const Stack = NBStack;

export const Text = (props: any) => {
  const { language, translations: i18n } = usei18n();

  const translate = props.translate == null ? true : props.translate;

  let translatedChildren = props.children;

  if (translate && i18n[props.children] && language !== 'en') {
    translatedChildren = i18n[props.children];
  }

  let fontSize: number = props.fontSize;
  if (props.fit && props.width) {
    fontSize = fitFontSize({
      text: translatedChildren as string,
      maxFontSize: fontSize,
      maxWidth: props.width,
    });
  }

  return (
    <NBText {...props} children={translatedChildren} fontSize={fontSize} />
  );
};

export const Button = NButton;

export const Divider = () => (
  <View
    style={{
      opacity: 0.5,
      borderBottomColor: colors.secondary,
      borderBottomWidth: StyleSheet.hairlineWidth,
    }}
  />
);

const roundToStep = (num: number, step: number) => {
  let numDecimals = 0;
  if (!(Math.floor(step.valueOf()) === step.valueOf())) {
    numDecimals = step.toString().split('.')[1].length || 0;
  }

  return parseFloat(num.toFixed(numDecimals));
};

export const Slider = ({
  minimumValue,
  maximumValue,
  step,
  value,
  onChange,
}: {
  minimumValue: number;
  maximumValue: number;
  step: number;
  value: number;
  onChange: (val: number) => void;
}) => {
  return (
    <RNSlider
      value={value}
      minimumValue={minimumValue}
      maximumValue={maximumValue}
      step={step}
      // Round to nearest step
      onValueChange={(vals) => {
        onChange(roundToStep(vals[0], step));
      }}
      trackStyle={{
        height: 20,
        marginLeft: 10,
        borderRadius: 10,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,
      }}
      maximumTrackStyle={{ backgroundColor: colors.bg }}
      minimumTrackStyle={{
        backgroundColor: value === 0 ? colors.bg : colors.primary,
        shadowOpacity: 0,
      }}
      thumbTintColor={colors.bgSecondary}
      // trackRightPadding={100}
      // thumbStyle={{

      //   width: 20,
      //   // margin:4,
      // }}
      renderThumbComponent={() => (
        <View
          style={{
            backgroundColor: colors.primary,
            width: 20,
            height: 20,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 10,
          }}
        >
          <View
            style={{
              // marginRight: 10,

              width: 14,
              height: 14,
              borderRadius: 10,
              backgroundColor: colors.bgSecondary,
            }}
          />
        </View>
      )}
    />
  );
};
