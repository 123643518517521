import { FirebaseApp, FirebaseError, initializeApp } from 'firebase/app';
import {
  signOut as fbSignOut,
  GoogleAuthProvider,
  signInWithCredential,
  OAuthCredentialOptions,
  OAuthProvider,
  Auth,
  signInWithEmailAndPassword,
  signInAnonymously,
  linkWithCredential,
  linkWithRedirect,
  EmailAuthProvider,
  getRedirectResult,
  browserPopupRedirectResolver,
  sendSignInLinkToEmail,
  ActionCodeSettings,
  signInWithCustomToken,
} from 'firebase/auth';
import {
  initializeAuth,
  getReactNativePersistence,
} from 'firebase/auth/react-native';

import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

const firebaseConfig = {
  apiKey: 'AIzaSyDxdTV_M-Xr_UCoGwlJbJewUSTGXLwQ2js',
  authDomain: 'app.lailachat.com',
  projectId: 'dreambooth-31489',
  storageBucket: 'dreambooth-31489.appspot.com',
};

let app: FirebaseApp;
let authTemp: Auth;

// if (!isWeb) {
app = initializeApp(firebaseConfig);

authTemp = initializeAuth(app, {
  persistence: getReactNativePersistence(AsyncStorage),
  popupRedirectResolver: browserPopupRedirectResolver,
});
// }

export const auth = authTemp;

const googleAuthProvider = new GoogleAuthProvider();

export const signIn = () =>
  signInWithEmailAndPassword(
    auth,
    'demo@digitalyou.web.app',
    'demo@digitalyou.web.app'
  );

export const signOut = () => fbSignOut(auth);

WebBrowser.maybeCompleteAuthSession();

export const useGoogleSignIn = () => {
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    clientId:
      '710925289981-vd2vp8me0e38jl22bi6evih61fo95bmu.apps.googleusercontent.com',
    iosClientId:
      '710925289981-adsgs5h7hkk453vfkraoam19g1tgdlsk.apps.googleusercontent.com',
  });

  useEffect(() => {
    if (response?.type === 'success') {
      const { id_token } = response.params;
      const credential = GoogleAuthProvider.credential(id_token);
      signInWithCredential(auth, credential);
    }
  }, [response]);

  return { request, response, promptAsync };
};

export const signInWithApple = (credential: OAuthCredentialOptions) => {
  const provider = new OAuthProvider('apple.com');
  const appleCredential = provider.credential(credential);

  return signInWithCredential(auth, appleCredential);
};

export const onRedirect = () => {
  if (Platform.OS !== 'web') return null;
  return getRedirectResult(auth).catch((err: FirebaseError) => {
    if (err.code !== 'auth/credential-already-in-use') {
      console.log({ err: err.code });
    }
    const credential = GoogleAuthProvider.credentialFromError(err);

    return signInWithCredential(auth, credential);
  });
}

export const linkWithGoogle = async () => {
  const provider = new GoogleAuthProvider();

  return linkWithRedirect(auth.currentUser, provider);
};

export const linkUsernamePassword = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const credential = EmailAuthProvider.credential(email, password);

  return linkWithCredential(auth.currentUser, credential).catch(
    (err: FirebaseError) => {
      if (err.code !== 'auth/email-already-in-use') {
        console.log({ err: err });
        return;
      }

      return signInWithCredential(auth, credential);
    }
  );
};

const EMAIL_SIGNIN_STORAGE_KEY = 'emailForSignIn';

export const storedPasswordlessEmail = async () => {
  const email = AsyncStorage.getItem(EMAIL_SIGNIN_STORAGE_KEY);

  if (email == null) {
    return null;
  }

  await AsyncStorage.removeItem(EMAIL_SIGNIN_STORAGE_KEY);
  return email;
};

export const sendPasswordlessEmail = async ({
  email,
  language,
}: {
  email: string;
  language: string;
}) => {
  auth.languageCode = language;

  const actionCodeSettings: ActionCodeSettings = {
    url: window.location.href,
    handleCodeInApp: true,
  };

  await sendSignInLinkToEmail(auth, email, actionCodeSettings);

  return AsyncStorage.setItem(EMAIL_SIGNIN_STORAGE_KEY, email);
};

export const linkPasswordlessLogin = async ({ email }: { email: string }) => {
  const credential = EmailAuthProvider.credentialWithLink(
    email,
    window.location.href
  );

  if (auth.currentUser == null) {
    return signInWithCredential(auth, credential);
  }

  return linkWithCredential(auth.currentUser, credential).catch(
    (err: FirebaseError) => {
      if (err.code !== 'auth/email-already-in-use') {
        throw err;
      }

      return signInWithCredential(auth, credential);
    }
  );
};

export const signInAnon = () => signInAnonymously(auth);

export const signInWithToken = (token: string) => signInWithCustomToken(auth, token)
