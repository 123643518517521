import { Pressable, useWindowDimensions } from 'react-native';
import { useNavigate } from '../components/Router';
import { Box, HStack, Text, VStack } from '../ui';
import { useConfigQuery, useThreadsQuery } from '../api';
import PageCard from '../components/PageCard';
import TopNavbar from '../components/TopNavbar';
import { ScrollView } from 'native-base';
import { isDesktop } from '../utils';
import CachedImage from '../components/CachedImage';
import { usei18n } from '../utils/i18n';
import Loading from '../components/Loading';
import { Fragment } from 'react';

const Home = () => {
  const navigate = useNavigate();

  const { i18n } = usei18n();

  // Used to rerender the component when the window dimensions change
  useWindowDimensions();

  const { data: threadsData } = useThreadsQuery({});

  const { data: configData } = useConfigQuery({}, { cache: false });

  const pageGroupCategories = configData?.pageGroupCategories || [];
  const pageGroups = pageGroupCategories.flatMap((c) => c?.pageGroups || []);

  const pages = configData?.pages || [];
  const laila = { id: 'laila', name: 'Laila' };
  pages.push(laila as any);

  const recentPages = (threadsData?.recentPages || []).slice(0, 10);

  if (threadsData == null) return <Loading />;

  const renderPages = (pages: typeof recentPages, label: string) => (
    <VStack space={1}>
      <Text fontWeight={550} fontSize={isDesktop() ? 28 : 16} color='secondary'>
        {label}
      </Text>
      <ScrollView
        horizontal={!isDesktop()}
        showsHorizontalScrollIndicator={false}
      >
        <HStack space={2} flexWrap={isDesktop() ? 'wrap' : undefined}>
          {pages.map((page, idx) => (
            <Box key={idx} mt={isDesktop() ? 2 : 0}>
              <PageCard
                page={page}
                pageGroup={pageGroups.find((pg) => pg.id === page?.groupId)}
              />
            </Box>
          ))}
        </HStack>
      </ScrollView>
    </VStack>
  );

  const renderPageGroupCategory = (
    category: (typeof pageGroupCategories)[number],
    key: number
  ) =>
    (category?.pageIds || [])?.length > 0 ? (
      <Fragment key={key}>
        {renderPages(
          category
            .pageIds!.map((id) => pages.find((p) => p.id === id)!)
            .filter((p) => p != null),
          i18n(category).label
        )}
      </Fragment>
    ) : (
      <VStack key={key} mt={2} space={1}>
        <Text
          fontWeight={550}
          fontSize={isDesktop() ? 28 : 16}
          color='secondary'
        >
          {i18n(category).label}
        </Text>

        <Box justifyContent='center'>
          <HStack space={4} flexWrap='wrap'>
            {category.pageGroups.map((pageGroup, idx) => (
              <Pressable
                key={idx}
                onPress={() => navigate(`/pageGroup/${pageGroup.id}`)}
              >
                <Box
                  mb={4}
                  bg='gray'
                  rounded='lg'
                  w={isDesktop() ? '250px' : '150px'}
                  h={isDesktop() ? '250px' : '150px'}
                  justifyContent='center'
                  alignItems='center'
                >
                  <CachedImage
                    isBackground
                    // @ts-expect-error
                    imageStyle={{ borderRadius: 8 }}
                    style={{
                      flexGrow: 1,
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    source={{
                      uri: `/static/images/pageGroups/${pageGroup.id}.png`,
                    }}
                  >
                    <Text
                      width={isDesktop() ? 250 : 150}
                      textAlign='center'
                      fontSize={isDesktop() ? 42 : 28}
                      color='bg'
                      fontWeight={600}
                      fit
                    >
                      {i18n(pageGroup).label}
                    </Text>
                  </CachedImage>
                </Box>
              </Pressable>
            ))}
          </HStack>
        </Box>
      </VStack>
    );
  return (
    <VStack
      pl={isDesktop() ? 262 : undefined}
      h='100%'
      w={isDesktop() ? '100%' : undefined}
    >
      <TopNavbar />
      <ScrollView
        style={{
          marginLeft: 10,
          padding: 10,
        }}
      >
        {recentPages.length > 0 && renderPages(recentPages, 'Recents')}
        {pageGroupCategories.map(renderPageGroupCategory)}
      </ScrollView>
    </VStack>
  );
};

export default Home;
