import { useMemo, useState } from 'react';
import Navtabs from '../components/Navtabs';
import { VStack, Divider, Text, HStack, Box } from '../ui';
import { assertNever } from '../utils';
import { useThreadsQuery, useUserQuery } from '../api';
import ThreadCard from '../components/ThreadCard';
import { Pressable, ScrollView, View } from 'react-native';
import PageCard from '../components/PageCard';
import ThreadFavoriteCard from '../components/ThreadFavoriteCard';
import TopNavbar from '../components/TopNavbar';
import { api } from '../apiTypes';
import ThreadHistory from '../components/ThreadHistory';

const tabs = ['Recents', 'Following', 'Saved', 'Published'] as const;
type Tab = (typeof tabs)[number];

const NothingHereYet = () => (
  <VStack w='100%' alignItems='center' m={4}>
    <Text fontSize={18} color='secondary'>
      There's nothing here yet 👻
    </Text>
  </VStack>
);

const Chats = () => {
  const [activeTab, setActiveTab] = useState<Tab>('Recents');

  const [historyIsOpen, setHistoryIsOpen] = useState(false);

  const {
    data: threadsData,
    optimisticUpdate: optimisticThreads,
    refetch: refetchThreads,
  } = useThreadsQuery({}, { cache: false });

  const { data: userData } = useUserQuery();

  const renderTab = useMemo(
    () => () => {
      switch (activeTab) {
        case 'Recents': {
          const recentPages = threadsData?.recentPages || [];

          if (recentPages.length === 0) {
            return <NothingHereYet />;
          }

          return (
            <VStack space={2} m={2}>
              {recentPages.map((page, idx) => (
                <PageCard key={idx} page={page} horizontal />
              ))}
            </VStack>
          );
        }
        // return (
        //   <VStack space={2} divider={<Divider />}>
        //     {(threadsData?.threads || []).slice(0, 20).map((thread) => (
        //       <ThreadCard
        //         thread={thread}
        //         prompt={null}
        //         onOptimisticUpdate={(thread) =>
        //           optimisticThreads({
        //             ...threadsData,
        //             threads: (threadsData?.threads || []).map((t) =>
        //               t.id === thread.id ? thread : t
        //             ),
        //           })
        //         }
        //       />
        //     ))}
        //   </VStack>
        // );
        case 'Following': {
          const pageFavorites = threadsData?.pageFavorites || [];

          if (pageFavorites.length === 0) {
            return <NothingHereYet />;
          }

          return (
            <VStack space={2} m={2}>
              {pageFavorites.map((page, idx) => (
                <PageCard key={idx} page={page} horizontal />
              ))}
            </VStack>
          );
        }
        case 'Published': {
          if ((threadsData?.threads || []).length === 0) {
            return <NothingHereYet />;
          }

          return (
            <VStack space={2} divider={<Divider />}>
              {(threadsData?.threads || [])
                .filter((t) => t.isPublic)
                .slice(0, 20)
                .map((thread, idx) => (
                  <ThreadCard
                    key={idx}
                    thread={thread}
                    prompt={null}
                    onOptimisticUpdate={(thread) =>
                      optimisticThreads({
                        ...threadsData,
                        threads: (threadsData?.threads || []).map((t) =>
                          t.id === thread.id ? thread : t
                        ),
                      })
                    }
                  />
                ))}
            </VStack>
          );
        }
        case 'Saved': {
          if ((threadsData?.favorites || []).length === 0) {
            return <NothingHereYet />;
          }

          return (
            <VStack space={2} divider={<Divider />}>
              {(threadsData?.favorites || []).map((thread, idx) => (
                <ThreadFavoriteCard
                  key={idx}
                  thread={thread}
                  user={userData}
                  onRefetch={refetchThreads}
                />
              ))}
            </VStack>
          );
        }
        default:
          assertNever(activeTab);
      }
    },
    [threadsData, activeTab]
  );

  if (historyIsOpen) {
    return (
      <HStack h='full'>
        <Box w='85%'>
          <ThreadHistory
            activeThreadId={null}
            showAll
            onClose={() => setHistoryIsOpen(false)}
          />
        </Box>

        <Pressable onPress={() => setHistoryIsOpen(false)}>
          <Box h='full' opacity={0.3} pointerEvents='none'>
            <VStack h='100%'>
              <TopNavbar />
              <Navtabs<Tab>
                tabs={tabs}
                onChange={setActiveTab}
                activeTab={activeTab}
              />
              <ScrollView>{renderTab()}</ScrollView>
            </VStack>
          </Box>
        </Pressable>
      </HStack>
    );
  }

  return (
    <VStack h='100%'>
      <TopNavbar onHistory={() => setHistoryIsOpen(true)} />
      <Navtabs<Tab> tabs={tabs} onChange={setActiveTab} activeTab={activeTab} />
      <ScrollView>{renderTab()}</ScrollView>
    </VStack>
  );
};

export default Chats;
