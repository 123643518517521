import { useState } from 'react';
import CachedImage from '../components/CachedImage';
import Navtabs from '../components/Navtabs';
import { useNavigate, useParams } from '../components/Router';
import { HStack, VStack, Text } from '../ui';
import { usei18n } from '../utils/i18n';
import { assertNever } from '../utils';
import { usePageGroupQuery } from '../api';
import PageCard from '../components/PageCard';
import { Pressable, ScrollView } from 'react-native';
import Explore from './Explore';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import { Icon } from 'native-base';

const PageGroup = () => {
  const { id: pageGroupId } = useParams();
  const navigate = useNavigate();

  const { i18n } = usei18n();

  const { data: pageGroupData } = usePageGroupQuery(
    { id: pageGroupId },
    { cache: false }
  );

  const tabs = ['Pages', 'Public Chats'] as const;
  type Tab = (typeof tabs)[number];
  const [activeTab, setActiveTab] = useState<Tab>('Pages');

  if (pageGroupData == null) return null;

  const label = i18n(pageGroupData).label;
  const description = i18n(pageGroupData).description;

  const renderTab = () => {
    switch (activeTab) {
      case 'Pages':
        return (
          <VStack space={2}>
            {(pageGroupData?.pages || []).map((p) => (
              <PageCard key={p.id} page={p} groupLabel={label} horizontal />
            ))}
          </VStack>
        );

      case 'Public Chats':
        return <Explore hideSortOptions pageGroupId={pageGroupId} />;

      default:
        assertNever(activeTab);
    }
  };

  return (
    <VStack w='100%' space={2} h='100%' p={2}>
      <HStack alignItems='center' py={3}>
        <Pressable onPress={() => navigate(-1)}>
          <Icon
            as={<Ionicons name='chevron-back-outline' />}
            color='primary'
            size={7}
          />
        </Pressable>
      </HStack>
      <HStack space={4}>
        <CachedImage
          source={{
            uri: `/static/images/pageGroups/${pageGroupId}.png`,
          }}
          rounded='lg'
          w='100px'
          h='100px'
        />
        <VStack style={{ flexShrink: 1 }}>
          <Text fontSize='2xl' fontWeight={700} color='secondary'>
            {label}
          </Text>
          <Text fontSize='xs' fontWeight={550} color='secondary'>
            {description}
          </Text>
        </VStack>
      </HStack>

      <Navtabs<Tab> tabs={tabs} onChange={setActiveTab} activeTab={activeTab} />

      <ScrollView>{renderTab()}</ScrollView>
    </VStack>
  );
};

export default PageGroup;
