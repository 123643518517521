import {
  Box,
  HStack,
  Icon,
  Input,
  KeyboardAvoidingView,
  Menu,
  Pressable,
  ScrollView,
  VStack,
} from 'native-base';
import { Ionicons, FontAwesome5 } from '@expo/vector-icons';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  API_BASE_URL,
  useConfigQuery,
  useThreadsQuery,
  useUserQuery,
} from '../api';
import { useUser } from '../hooks';
import Loading from './Loading';
import { urlsFromText, uploadFile, isWeb, alert, isDesktop } from '../utils';
import FileCard from './FileCard';
import { File } from '../../api/functions/src/db/types';
import { getLinkPreview } from '../utils/linkPreview';
import { Dimensions, View } from 'react-native';
import { api } from '../apiTypes';
import { usei18n } from '../utils/i18n';
import { Text } from '../ui';
import RegisterModal, { useModal } from './Modal';
import { useNavigate } from './Router';

type Props = {
  onSendMessage: ({
    message,
    files,
  }: {
    message: string;
    files: Pick<File, 'type' | 'value' | 'title' | 'description'>[];
  }) => void;
  onAddFile?: () => void;
  onRemoveFiles?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  numTokens: number;
  placeholderText?: string;
  prompt?: api['categories']['response'][number]['prompts'][number];
  disabled?: boolean;
};

// type FileType = 'url' | 'pdf' | 'image';
type FileType = File['type'];

export type SendMessageRef = {
  focusInput: () => void;
  clearInput: () => void;
};

const SendMessage = forwardRef<SendMessageRef, React.PropsWithChildren<Props>>(
  (
    {
      prompt,
      onSendMessage,
      numTokens,
      onAddFile = () => {},
      onRemoveFiles = () => {},
      onFocus = () => {},
      onBlur = () => {},
      placeholderText,
      disabled = false,
    },
    ref
  ) => {
    const inputRef = useRef(null);
    useImperativeHandle(ref, () => ({
      focusInput() {
        inputRef?.current?.focus();
      },
      clearInput() {
        setMessageText('');
      },
    }));

    const { i18n, translations } = usei18n();
    const navigate = useNavigate();

    const { openModal } = useModal();

    const [messageText, setMessageText] = useState<string>('');
    const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);

    const [user] = useUser();
    const { data: userData, refetch: refetchUser } = useUserQuery();

    const [fileType, setFileType] = useState<FileType>();

    const [isLoading, setIsLoading] = useState(false);

    const { data: threadsData, refetch: refetchThreads } = useThreadsQuery();

    const { data: configData } = useConfigQuery();

    const [files, setFiles] = useState<
      Pick<File, 'type' | 'value' | 'title' | 'description'>[]
    >([]);

    useEffect(() => {
      const urls = urlsFromText(messageText).filter(
        (url) => !files.some((f) => f.value === url)
      );

      urls.map(async (url) => {
        saveUrl(url);

        setMessageText(messageText.toLowerCase().replace(url, ''));
      });
    }, [messageText]);

    const menuOptions: {
      [k in Exclude<FileType, 'image' | 'url'>]: {
        label: string;
      };
    } = {
      // image: {
      //   label: 'Image',
      // },
      // url: {
      //   label: 'URL',
      // },
      pdf: {
        label: 'PDF',
      },
    };

    const typeQuestions =
      translations['Type questions...'] || 'Type questions...';

    const placeholder = () => {
      const defaultMessage = i18n(prompt).default;

      const typeQuestions =
        translations['Type questions...'] || 'Type questions...';
      const enterUrl = translations['Enter URL...'] || 'Enter URL...';
      if (fileType == null)
        return defaultMessage || placeholderText || typeQuestions;
      switch (fileType) {
        case 'pdf':
          return typeQuestions;
        case 'url':
          return enterUrl;
        // case 'image':
        //   return 'Image Not Implemented Yet';
      }
    };

    const onSelectFileType = async (type: FileType) => {
      if (type === 'pdf') {
        setIsLoading(true);

        uploadFile({
          idToken: await user?.getIdToken(),
          fileType: type,
          onCancel: () => setIsLoading(false),
          onFinish: ({ filePath, title }) => {
            setFiles((f) => [
              ...f,
              {
                type: 'pdf',
                value: filePath,
                title,
                description: '',
              },
            ]);
            onAddFile();

            setFileType(undefined);

            setIsLoading(false);
          },
        });

        return;
      }

      setFileType(type);
    };

    const saveUrl = async (url: string) => {
      const linkPreview = await getLinkPreview(url).catch(() => null);

      let title = url;
      let description = '';

      if ('title' in (linkPreview || {})) {
        title = linkPreview?.title ?? url;
        description = linkPreview?.description ?? '';
      }

      setFiles((f) => [
        ...f,
        {
          type: 'url',
          value: url,
          title,
          description,
        },
      ]);
      onAddFile();
      setFileType(undefined);
    };

    const onSend = async () => {
      refetchThreads({ shouldUseCache: true });

      const updatedUserData = await refetchUser();

      // const numMessagesInLastDay = (threadsData?.threads || [])
      //   .flatMap((t) => t.messages)
      //   .filter(
      //     (m) =>
      //       !m.isBot &&
      //       new Date(m.createdAt) > new Date(Date.now() - 24 * 60 * 60 * 1000)
      //   ).length;

      // if (
      //   user?.isAnonymous &&
      //   numMessagesInLastDay >= (configData?.numAnonMessages ?? 9999)
      // ) {
      //   openModal({
      //     type: 'register',
      //   });
      //   return;
      // }

      const tokens = updatedUserData?.tokens ?? 0;

      const tokensToUse = numTokens;

      if (tokens < tokensToUse) {
        openModal({
          type: user?.isAnonymous
            ? 'insufficient-tokens-anon'
            : 'insufficient-tokens-user',
        });
        return;
      }

      // if (
      //   isWeb &&
      //   user?.isAnonymous &&
      //   numMessagesInLastDay >= (configData?.numAnonMessages ?? 9999)
      // ) {
      //   setRegisterModalIsOpen(true);
      //   return;
      // }

      // if (userData?.subscriptionReceipt == null && userData?.stripeId == null) {
      //   const hasExceededFreeLimit =
      //     numMessagesInLastDay >= (configData?.numDailyFreeMessages ?? 9999);

      //   if (hasExceededFreeLimit) {
      //     setRegisterModalIsOpen(true);
      //     // alert(
      //     //   i18n['Free Trial Expired'] || 'Free Trial Expired',
      //     //   i18n.dailyLimitReached || 'Daily Limit Reached',
      //     //   [
      //     //     {
      //     //       text: i18n.Continue || 'Continue',
      //     //       onPress: () => {
      //     //         if (isWeb) return;
      //     //         navigate('/pricing?onClose=/chat');
      //     //       },
      //     //     },
      //     //   ]
      //     // );
      //     return;
      //   }
      // }

      if (fileType == null) {
        const message = messageText;
        setMessageText('');

        onSendMessage({ message, files });

        inputRef?.current?.blur();
        setFiles([]);
        return;
      }

      switch (fileType) {
        case 'pdf': {
          return;
        }
        case 'url': {
          saveUrl(messageText);

          return;
        }
        // case 'image': {
        //   return;
        // }
      }
    };

    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        <RegisterModal
          isVisible={registerModalIsOpen}
          type={user?.isAnonymous ? 'register' : 'limit-reached'}
          onClose={() => {
            setRegisterModalIsOpen(false);
          }}
        />
        <VStack
          bottom={isWeb ? undefined : 3}
          // alignItems='center'
          position={isWeb ? undefined : 'absolute'}
          bg={isDesktop() ? 'bg' : 'bgSecondary'}
          // bg="blue.500"
          // px={2}
          w={isWeb ? '100%' : undefined}
          py={3}
          // py={3}
          // space={2}
          // bg="red.200"
          // justifyItems="flex-end"

          // mx={4}
          // my={3}
          // flexGrow={1}
          // h='100%'
          // justifyContent="flex-end"
          // justifyContent='space-between'

          // alignContent="start"
        >
          {files.length > 0 && (
            <VStack mx={2} my={1}>
              <ScrollView>
                <VStack space={1}>
                  {[].concat(files).map((file, i) => (
                    <FileCard
                      key={i}
                      file={file}
                      onRemove={() => {
                        setFiles((f) => {
                          const res = f.filter((_f, idx) => idx !== i);

                          if (res.length === 0) {
                            onRemoveFiles();
                          }
                          return res;
                        });
                      }}
                    />
                  ))}
                </VStack>
              </ScrollView>
            </VStack>
          )}

          <VStack minW='100%' my={1}>
            {prompt?.description && (
              <Text mx={2} fontWeight={600} fontSize={16} translate={false}>
                {i18n(prompt).description}
              </Text>
            )}
            {prompt?.examples ? (
              <>
                <HStack mx={2} alignItems='center' space={1}>
                  <Icon color='text' as={<Ionicons name='list-sharp' />} />
                  {/* <Text color='text'>Examples</Text> */}
                </HStack>
                <ScrollView horizontal mx={2}>
                  {(i18n(prompt).examples || []).map((example, idx) => (
                    <Pressable
                      key={idx}
                      onPress={() => {
                        if (messageText === example) {
                          setMessageText('');
                          return;
                        }
                        setMessageText(example);
                      }}
                    >
                      <Box
                        rounded='sm'
                        p={1}
                        mr={2}
                        borderWidth={1}
                        borderColor='text'
                        bg={messageText === example ? 'gray.300' : undefined}
                      >
                        <Text translate={false}>{example}</Text>
                      </Box>
                    </Pressable>
                  ))}
                </ScrollView>
              </>
            ) : prompt?.description == null ? (
              <></>
            ) : (
              <></>
              // <Text fontSize='2xs' color='gray.300'>
              //   Press Send to start chat
              // </Text>
            )}
          </VStack>

          <VStack
            // minW='100%'
            bg='bg'
            borderColor='gray'
            borderWidth={1}
            rounded='lg'
            px={2}
            mx={2}
          >
            <HStack
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                alignSelf: 'center',
              }}

              // rounded="full"
              // px={2}
            >
              <Menu
                // w='190'
                placement='top'
                offset={10}
                crossOffset={20}
                _backdrop={{}}
                _presenceTransition={{}}
                _overlay={{}}
                trigger={(triggerProps) => (
                  <Pressable {...triggerProps}>
                    <FontAwesome5
                      {...triggerProps}
                      name='plus'
                      color='text'
                      size={24}
                    />
                  </Pressable>
                )}
              >
                {Object.entries(menuOptions).map(([type, { label }]) => (
                  <Menu.Item
                    key={label}
                    onPress={() => onSelectFileType(type as FileType)}
                  >
                    {label}
                  </Menu.Item>
                ))}
              </Menu>

              <Input
                isDisabled={disabled}
                ref={inputRef}
                variant='unstyled'
                w={'85%'}
                color='text'
                _focus={{
                  bg: 'bg',
                }}
                fontSize={messageText || !placeholderText ? 'sm' : 'sm'}
                placeholderTextColor='text'
                placeholder={placeholder()}
                onKeyPress={(e) => {
                  if (
                    e.nativeEvent.key == 'Enter' &&
                    // @ts-ignore
                    !e.nativeEvent?.shiftKey
                  ) {
                    onSend();
                  }

                  // if (
                  //   e.nativeEvent.key == 'Enter' &&
                  //   // @ts-ignore
                  //   e.nativeEvent?.shiftKey
                  // ) {
                  //   console.log('change text');
                  //   e.preventDefault();
                  //   e.stopPropagation();
                  //   setMessageText((t) => t + '\n');
                  // }
                }}
                // w={64}
                onFocus={onFocus}
                onBlur={onBlur}
                value={messageText}
                onChangeText={setMessageText}
              />
              <Icon
                color='primary'
                size={6}
                disabled={disabled}
                onPress={() => (disabled ? {} : onSend())}
                as={<Ionicons name='send' />}
              />
            </HStack>
            {/* <Text color='gray.500'>Type questions...</Text> */}
          </VStack>

          {/* <Box
        bg='white'
        h='8'
        w='8'
        mt={-6}
        style={{
          transform: [{ rotateZ: '-45deg' }],
        }}
        zIndex={-1}
      /> */}
        </VStack>
      </>
    );
  }
);

export default SendMessage;
