import { Dimensions, Linking, Pressable, View } from 'react-native';
import { Button, HStack, Text, VStack } from '../ui';
import { useEffect, useState } from 'react';
import { Badge, CloseIcon, Divider, Icon, Switch } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { useLocation, useNavigate, useParams } from '../components/Router';

import { isDevice } from 'expo-device';
import { useIAP, withIAPContext } from '../utils/iap';
import useAppState from 'react-native-appstate-hook';
import {
  useConfigQuery,
  useRequestOfferSignatureMutation,
  useThreadsQuery,
  useUpdateUserMutation,
  useUserQuery,
} from '../api';
import { PaymentDiscount } from 'react-native-iap/lib/typescript/types/apple';
import { Product, ProductPurchase } from 'react-native-iap';
import { useLoading } from '../components/Loading';
import { colors } from '../utils/theme';

const selectedColor = 'rgb(98,102,244)';

const RadioButton = ({ selected }: { selected: boolean }) => {
  return (
    <View
      style={[
        {
          height: 24,
          width: 24,
          borderRadius: 12,
          borderWidth: 2,
          borderColor: selected ? selectedColor : colors.text,
          alignItems: 'center',
          justifyContent: 'center',
        },
      ]}
    >
      {selected ? (
        <View
          style={{
            height: 12,
            width: 12,
            borderRadius: 6,
            backgroundColor: selectedColor,
          }}
        />
      ) : null}
    </View>
  );
};

const PurchaseCard = ({
  price,
  period,
  product,
  onPress,
  selected,
  freeTrial,
}: {
  price: string;
  product: Product | null;
  period: string;
  onPress: () => void;
  selected: boolean;
  freeTrial: boolean;
}) => {
  const smallScreen = Dimensions.get('window').height < 700;

  const priceStr = product?.price;
  const currency = product?.currency;

  let normalizedPrice = price;

  if (priceStr != null && currency != null) {
    normalizedPrice = `${currency} ${priceStr}`;
  }

  return (
    <Pressable onPress={onPress}>
      <VStack
        borderColor={selected ? 'rgb(98,102,244)' : 'text'}
        borderWidth={selected ? 2 : 2}
        rounded='lg'
        p={2}
        //   w='50%'
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <VStack w={smallScreen ? 72 : 80}>
            <Text>{period.toLocaleUpperCase()}</Text>
            <Text fontWeight={600}>{`${
              freeTrial ? '3 day free trial then' : ''
            } ${normalizedPrice} /${period}`}</Text>
          </VStack>
          <RadioButton selected={selected} />
        </View>
      </VStack>
    </Pressable>
  );
};

const Pricing = () => {
  const [selectedProductId, setSelectedProductId] = useState('sub_monthly');
  const [freeTrialEnabled, setFreeTrialEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { data: threadsData, refetch: refetchThreads } = useThreadsQuery({});
  const { data: configData } = useConfigQuery({});

  useLoading(isLoading);

  const { data: userData, refetch: refetchUser } = useUserQuery();

  const [requestOfferSignature] = useRequestOfferSignatureMutation();
  const [updateUser] = useUpdateUserMutation();

  const location = useLocation();
  const navigate = useNavigate();

  const {
    connected,
    products,
    promotedProductsIOS,
    requestPurchase,
    subscriptions,
    availablePurchases,
    currentPurchase,
    currentPurchaseError,
    initConnectionError,
    finishTransaction,
    getProducts,
    getSubscriptions,
    getAvailablePurchases,
  } = useIAP();

  useEffect(() => {
    if (!isDevice) return;
    getSubscriptions({
      skus: ['sub_weekly', 'sub_monthly', 'sub_annual'],
    });
  }, []);

  const onClose = () => {
    const onClosePath = location.search?.split('=')?.[1] || '/';
    navigate(onClosePath);
  };

  useAppState({
    onForeground: () => {
      refetchThreads();
    },
  });

  const makePurchase = async () => {
    // let withOffer: PaymentDiscount;

    // if (freeTrialEnabled) {
    //   const offer = subscriptions.find(
    //     (sub) => sub.productId === selectedProductId
    //   )?.discounts?.[0];

    //   // const signature = await requestOfferSignature({
    //   //   appBundleId: 'com.digitalyouai.digitalyouai',
    //   //   productIdentifier: selectedProductId,
    //   //   offerId: offer.identifier,
    //   //   applicationUsername: userData.id,
    //   // });

    //   // if (signature == null) {
    //   //   setIsLoading(false);
    //   //   return;
    //   // }

    //   // withOffer = {
    //   //   identifier: offer.identifier,
    //   //   keyIdentifier: signature.keyId,
    //   //   timestamp: signature.timestamp,
    //   //   nonce: signature.nonce,
    //   //   signature: signature.signature,
    //   // };
    // }

    // console.log({ withOffer });
    if (!isDevice) return;

    // setIsLoading(true);

    const purchase = await requestPurchase({
      andDangerouslyFinishTransactionAutomaticallyIOS: true,
      sku: selectedProductId,
    }).catch(() => null);

    if (purchase == null) {
      setIsLoading(false);
      return;
    }

    const verifiedPurchased: ProductPurchase = purchase as any;

    await updateUser({
      subscriptionReceipt: verifiedPurchased.transactionReceipt,
    }).then(() => refetchUser());

    setIsLoading(false);

    onClose();
  };

  const numThreadsInLastDay = (threadsData?.threads || []).filter(
    (t) => new Date(t.createdAt) > new Date(Date.now() - 24 * 60 * 60 * 1000)
  ).length;

  const reachedDailyLimit =
    numThreadsInLastDay >= (configData?.numDailyFreeThreads || 9999);

  const smallScreen = Dimensions.get('window').height < 700;

  return (
    <View style={{ marginTop: smallScreen ? 30 : 80 }}>
      <Pressable onPress={onClose}>
        <CloseIcon color='text' ml={2} mb={-1} />
      </Pressable>

      <VStack m={3}>
        <Text alignSelf='center' fontSize={36}>
          Simple AI Chats
        </Text>
        <HStack alignSelf='center'>
          <Text alignSelf='center' fontSize={28} mt={-3} mr={2}>
            with
          </Text>
          <Text alignSelf='center' fontSize={36} fontWeight={600} mt={-3}>
            Laila
          </Text>
        </HStack>
        <VStack space={2}>
          <Text fontSize='lg' bold ml={2} color='rgb(98,102,244)'>
            With Premium, you'll get:
          </Text>
          <VStack ml={4}>
            {['Unlimited AI Chats (Q&A)', 'No Ads', 'Access Premium Chats'].map(
              (txt, idx) => (
                <HStack space={2} key={idx}>
                  <Text color='green.500'>✓</Text>
                  <Text>{txt}</Text>
                </HStack>
              )
            )}
            <HStack space={2} opacity={freeTrialEnabled ? undefined : 0}>
              <Text color='green.500'>✓</Text>
              <Text fontWeight={600} color='green.500'>
                {'No Payment Now'}
              </Text>
            </HStack>
          </VStack>
          <Divider />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text fontSize='lg' fontWeight={600}>
              FREE TRIAL ENABLED
            </Text>
            <Switch
              margin={0}
              padding={0}
              onChange={() => {
                setFreeTrialEnabled(!freeTrialEnabled);
              }}
              isChecked={freeTrialEnabled}
              size='md'
              trackColor={{ false: 'rgb(103,205,10)', true: 'rgb(103,205,10)' }}
            />
          </View>
          <VStack space={4}>
            <PurchaseCard
              period='weekly'
              product={products.find((p) => p.productId === 'sub_weekly')}
              price={'USD 4.99'}
              //   onPress={() => makePurchase('sub_weekly')}
              onPress={() => setSelectedProductId('sub_weekly')}
              selected={selectedProductId === 'sub_weekly'}
              freeTrial={freeTrialEnabled}
            />
            <View>
              <Badge
                position='absolute'
                borderColor='text'
                borderWidth={1}
                rounded='full'
                right={16}
                top={-10}
                zIndex={99}
              >
                <Text>Most Popular</Text>
              </Badge>
              <PurchaseCard
                period='monthly'
                product={products.find((p) => p.productId === 'sub_monthly')}
                price={'USD 9.99'}
                onPress={() => setSelectedProductId('sub_monthly')}
                selected={selectedProductId === 'sub_monthly'}
                freeTrial={freeTrialEnabled}
              />
            </View>
            <PurchaseCard
              period='annual'
              product={products.find((p) => p.productId === 'sub_annual')}
              price={'USD 99.99'}
              onPress={() => setSelectedProductId('sub_annual')}
              selected={selectedProductId === 'sub_annual'}
              freeTrial={freeTrialEnabled}
            />
          </VStack>
          {/* <Button
          w='40'
          onPress={() => {
            restorePurchases();
          }}
        >
          Restore Purchases
        </Button> */}
        </VStack>
        <Button
          rounded='lg'
          alignSelf='center'
          h={8}
          bg='primary'
          w='100%'
          onPress={makePurchase}
        >
          <Text fontWeight={600} fontSize='xl' color='bg'>
            {freeTrialEnabled ? 'TRY FOR FREE' : 'SUBSCRIBE'}
          </Text>
        </Button>
        <HStack alignSelf='center' alignItems='center' space={1} mt={1}>
          <Icon
            color={'green.500'}
            mt={0.5}
            size='xs'
            as={<Ionicons name='shield-checkmark-sharp' />}
          />

          <Text fontWeight={500} fontSize='2xs' mt={1} textAlign='center'>
            NO PAYMENT NOW
          </Text>
        </HStack>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 16,
          }}
        >
          <Pressable
            onPress={() => {
              Linking.openURL(
                'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'
              );
            }}
          >
            <Text fontSize='2xs'>Terms of Use</Text>
          </Pressable>

          <Pressable onPress={onClose}>
            <Text fontSize='xs' style={{ textDecorationLine: 'underline' }}>
              Skip for now
            </Text>
          </Pressable>

          <Pressable
            onPress={() => {
              Linking.openURL('https://laila-privacy-policy.web.app');
            }}
          >
            <Text fontSize='2xs'>Privacy Policy</Text>
          </Pressable>
        </View>
      </VStack>
    </View>
  );
};

export default withIAPContext(Pricing);
